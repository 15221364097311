import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import VideoModal from "./VideoModal";

interface VideoCarouselProps {
  songsData: { youtubeLink: string; title: string; artist: string }[];
}

const VideoCarousel: React.FC<VideoCarouselProps> = ({ songsData }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const previousVideo = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? songsData.length - 1 : prevIndex - 1
    );
  };

  const nextVideo = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === songsData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getAutoplayLink = (link: string) => {
    const url = new URL(link);
    url.searchParams.set("autoplay", "1");
    return url.toString();
  };

  return (
    <div className="relative w-full max-w-2xl  ">
      {songsData.length > 0 && (
        <>
          <div
            className="aspect-w-16 aspect-h-9 cursor-pointer h-[200px]"
            onClick={openModal}
          >
            <div className="mt-4 flex  items-center">
              <h3 className="text-lg font-semibold text-gray-800 mr-2">
                {songsData[currentIndex].title}
              </h3>
              <p className="text-sm text-gray-600">
                - {songsData[currentIndex].artist}
              </p>
            </div>
            <iframe
              src={songsData[currentIndex].youtubeLink}
              title={`YouTube video ${currentIndex + 1}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full h-full pointer-events-none"
            ></iframe>
          </div>

          {/* Navigation buttons */}
          <button
            onClick={previousVideo}
            className="absolute left-[1rem] top-[60%] transform -translate-y-1/2 bg-black bg-opacity-50 p-2 rounded-full text-white"
          >
            <FaChevronLeft size={24} />
          </button>
          <button
            onClick={nextVideo}
            className="absolute right-[1rem] top-[60%] transform -translate-y-1/2 bg-black bg-opacity-50 p-2 rounded-full text-white"
          >
            <FaChevronRight size={24} />
          </button>

          {/* Modal */}
          {isModalOpen && (
            <VideoModal onClose={closeModal}>
              <div className="h-[300px]">
                <iframe
                  src={getAutoplayLink(songsData[currentIndex].youtubeLink)} // Autoplay video in modal
                  title={`YouTube video ${currentIndex + 1}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="w-full h-full"
                ></iframe>
              </div>
            </VideoModal>
          )}
        </>
      )}
    </div>
  );
};

export default VideoCarousel;
