import React, { useState, useEffect } from "react";
import { db, auth } from "../../../db/firebase";
import {
  collection,
  getDocs,
  addDoc,
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { FaPlus, FaEdit, FaTrash, FaSort } from "react-icons/fa";
import EventModal from "./EventModal";
import { useNavigate } from "react-router-dom";
import Card from "../Components/Card";
import AddButtonWithIcon from "../Components/AddButton";
import SuccessButton from "../Components/SuccessButton";
import DangerButton from "../Components/DangerButton";
import ConfirmDeleteEvent from "../../../components/ConfirmationDeleteEvent";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

const Events: React.FC = () => {
  const { t } = useTranslation(); // Initialize translation
  const [events, setEvents] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<any | null>(null);
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState("desc");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [eventToDelete, setEventToDelete] = useState<any | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserOrganization = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const orgId = userDoc.data().organizationId;
          const orgDoc = await getDoc(doc(db, "organizations", orgId));
          if (orgDoc.exists()) {
            setOrganizationId(orgDoc.id);
          }
        } else {
          const orgDoc = await getDoc(doc(db, "organizations", user.uid));
          if (orgDoc.exists()) {
            setOrganizationId(orgDoc.id);
          }
        }
      }
    };

    fetchUserOrganization();
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      if (organizationId) {
        const eventsSnapshot = await getDocs(collection(db, "events"));
        const eventsData = eventsSnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter((event) => event.organizationId === organizationId);
        setEvents(eventsData);
        setLoading(false);
      }
    };

    if (organizationId) {
      fetchEvents();
    }
  }, [organizationId]);

  const handleCreate = () => {
    setCurrentEvent(null);
    setShowModal(true);
  };

  const handleEdit = (event: any) => {
    setCurrentEvent(event);
    setShowModal(true);
  };

  const confirmDeleteEvent = (event: any) => {
    setEventToDelete(event);
    setIsDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    if (!eventToDelete) return;

    await deleteDoc(doc(db, "events", eventToDelete.id));
    setEvents(events.filter((event) => event.id !== eventToDelete.id));
    setIsDeleteModalOpen(false);
    setEventToDelete(null);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setCurrentEvent(null);
  };

  const handleSave = async (event: any) => {
    if (currentEvent) {
      const eventDocRef = doc(db, "events", currentEvent.id);
      await updateDoc(eventDocRef, event);
      setEvents(
        events.map((e) => (e.id === currentEvent.id ? { ...e, ...event } : e))
      );
    } else {
      const newEventDocRef = await addDoc(collection(db, "events"), {
        ...event,
        organizationId,
      });
      setEvents([
        ...events,
        { id: newEventDocRef.id, ...event, organizationId },
      ]);
    }
    handleModalClose();
  };

  const handleSort = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    setEvents(
      [...events].sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return newSortOrder === "asc"
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      })
    );
  };

  const handleCardClick = (id: string) => {
    navigate(`/organization/events/${id}`);
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4 sm:p-6 lg:p-8">
        <div className="bg-white p-8 rounded-2xl shadow-md w-full max-w-md text-center">
          <p className="text-gray-700">{t("loading")}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto ">
      <h1 className="text-3xl font-light mb-4 text-gray-700">{t("events")}</h1>
      <div className="flex justify-between items-center mb-4">
        <AddButtonWithIcon
          onClick={handleCreate}
          text={t("addEvent")}
          icon={<FaPlus />}
          className="custom-class"
        />
        <SuccessButton
          onClick={handleSort}
          text={t("sort")}
          icon={<FaSort />}
          className="custom-class"
        />
      </div>
      {events.length === 0 ? (
        <p className="text-gray-600">{t("noEventsAvailable")}</p>
      ) : (
        <ul className="space-y-4">
          {events.map((event) => (
            <Card
              key={event.id}
              className="bg-gray-100 p-4 rounded-2xl flex flex-col md:flex-row justify-between items-start md:items-center cursor-pointer"
              onClick={() => handleCardClick(event.id)}
            >
              <div className="flex-1">
                <h2 className="text-xl font-bold text-gray-700">
                  {event.title}
                </h2>
                <p className="text-gray-600">{event.description}</p>
                <p className="text-gray-600">
                  {t("date")}:{" "}
                  {new Date(`${event.date}T00:00:00`).toLocaleDateString()}
                </p>
              </div>
              <div className="flex mt-4 md:mt-0 md:ml-4 space-x-2">
                <SuccessButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEdit(event);
                  }}
                  text={t("edit")}
                  icon={<FaEdit />}
                  className="custom-class"
                />
                <DangerButton
                  onClick={(e) => {
                    e.stopPropagation();
                    confirmDeleteEvent(event);
                  }}
                  text={t("delete")}
                  icon={<FaTrash />}
                  className="custom-class"
                />
              </div>
            </Card>
          ))}
        </ul>
      )}
      {showModal && (
        <EventModal
          event={currentEvent}
          onSave={handleSave}
          onClose={handleModalClose}
        />
      )}
      {isDeleteModalOpen && (
        <ConfirmDeleteEvent
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={handleDelete}
          itemName={eventToDelete ? eventToDelete.title : ""}
        />
      )}
    </div>
  );
};

export default Events;
