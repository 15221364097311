import React from "react";
import { db } from "../../src/db/firebase";
import { doc, deleteDoc } from "firebase/firestore";

interface User {
  id: string;
  firstName: string;
  lastName: string;
}

interface UserInfoModalProps {
  user: User;
  isOpen: boolean;
  onClose: () => void;
  boardId: string;
  onRemoveUser: (userId: string) => void;
}

const UserInfoModal: React.FC<UserInfoModalProps> = ({
  user,
  isOpen,
  onClose,
  boardId,
  onRemoveUser,
}) => {
  const handleRemoveUser = async () => {
    try {
      await deleteDoc(doc(db, "memberships", `${boardId}_${user.id}`));
      onRemoveUser(user.id);
      onClose();
    } catch (error) {
      console.error("Error removing user: ", error);
    }
  };

  return isOpen ? (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded-2xl w-full mx-4 max-h-[80%] overflow-y-auto">
        <h2 className="text-xl font-bold mb-4">User Info</h2>
        <div className="mb-4">
          <p>
            <strong>First Name:</strong> {user.firstName}
          </p>
          <p>
            <strong>Last Name:</strong> {user.lastName}
          </p>
        </div>
        <div className="flex justify-end">
          <button
            onClick={handleRemoveUser}
            className="bg-red-500 text-white p-2 mr-2 rounded-2xl"
          >
            Remove User
          </button>
          <button
            onClick={onClose}
            className="bg-blue-500 text-white p-2 rounded-2xl"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default UserInfoModal;
