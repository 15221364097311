import React, { useState } from "react";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import { auth, db } from "../../db/firebase";
import { doc, getDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next"; // Import useTranslation

const AdminLogin: React.FC = () => {
  const { t } = useTranslation(); // Initialize translation
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleAdminLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      const userDoc = await getDoc(doc(db, "organizations", user.uid));
      const userUserDoc = await getDoc(doc(db, "users", user.uid)); // Check the users collection as well

      if (
        (userDoc.exists() &&
          (userDoc.data().role === "admin" ||
            userDoc.data().role === "organization")) ||
        (userUserDoc.exists() && userUserDoc.data().role === "admin")
      ) {
        navigate("/organization/dashboard");
      } else {
        await signOut(auth);
        setError(t("no_permission"));
      }
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-500 to-purple-600 p-4 sm:p-6 lg:p-8">
      <div className="bg-white p-8 rounded-2xl shadow-md w-full max-w-md -mt-[100px]">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-700">
          {t("admin_login")}
        </h2>
        <form onSubmit={handleAdminLogin} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-gray-600 mb-2">
              {t("email")}
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t("email")}
              required
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-gray-600 mb-2">
              {t("password")}
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t("password")}
              required
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-[#5932EA] bg-opacity-75 text-white py-2 rounded-lg hover:bg-[#5932EA] transition-colors"
          >
            {t("login")}
          </button>
        </form>
        {error && <p className="mt-4 text-red-500 text-center">{error}</p>}
        <p className="mt-6 text-gray-600 text-center">
          <Link to="/login" className="text-[#5932EA] hover:underline">
            {t("login_as_user")}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default AdminLogin;
