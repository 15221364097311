import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { auth, db } from "../../db/firebase"; // Adjust the import path if necessary
import { createUserWithEmailAndPassword } from "firebase/auth";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";

const SignupOrganization: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [orgName, setOrgName] = useState("");
  const [address, setAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [congregationNumber, setCongregationNumber] = useState("");
  const [language, setLanguage] = useState("en"); // Default to English
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSignup = async (e: React.FormEvent) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      // Create user authentication
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Save organization information in Firestore
      const orgRef = doc(db, "organizations", user.uid);
      await setDoc(orgRef, {
        orgName,
        address,
        firstName,
        lastName,
        phoneNumber,
        congregationNumber,
        email,
        adminId: user.uid,
        role: "admin", // Assign the role as "admin"
        language, // Set the language field based on the selection
        timestamp: serverTimestamp(),
        master: true,
      });

      // Save user information in Firestore under the 'users' collection
      const userRef = doc(db, "users", user.uid);
      await setDoc(userRef, {
        firstName,
        lastName,
        phoneNumber,
        email,
        organizationId: user.uid, // Link user to the organization
        role: "admin", // Assign the role as "admin"
        approved: true, // Assuming this user is approved by default
        timestamp: serverTimestamp(),
      });

      navigate("/organization/dashboard"); // Redirect to organization dashboard after signup
    } catch (error: any) {
      console.error("Error creating organization and user: ", error);
      setError(error.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-500 to-purple-600 p-4 sm:p-6 lg:p-8">
      <div className="bg-white p-8 rounded-2xl shadow-md w-full max-w-md ]">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-700">
          Organization Sign Up
        </h2>
        <form onSubmit={handleSignup} className="space-y-4">
          <div>
            <label htmlFor="orgName" className="block text-gray-600 mb-2">
              Organization Name
            </label>
            <input
              maxLength={100}
              type="text"
              id="orgName"
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
              placeholder="Organization Name"
              required
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <div>
            <label htmlFor="firstName" className="block text-gray-600 mb-2">
              First Name
            </label>
            <input
              maxLength={100}
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First Name"
              required
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <div>
            <label htmlFor="lastName" className="block text-gray-600 mb-2">
              Last Name
            </label>
            <input
              maxLength={100}
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last Name"
              required
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <div>
            <label htmlFor="phoneNumber" className="block text-gray-600 mb-2">
              Phone Number
            </label>
            <input
              maxLength={15}
              type="text"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Phone Number"
              required
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <div>
            <label
              htmlFor="congregationNumber"
              className="block text-gray-600 mb-2"
            >
              Congregation Number
            </label>
            <input
              maxLength={15}
              type="text"
              id="congregationNumber"
              value={congregationNumber}
              onChange={(e) => setCongregationNumber(e.target.value)}
              placeholder="Congregation Number"
              required
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <div>
            <label htmlFor="address" className="block text-gray-600 mb-2">
              Address
            </label>
            <input
              maxLength={100}
              type="text"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Address"
              required
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <div>
            <label htmlFor="language" className="block text-gray-600 mb-2">
              Language
            </label>
            <select
              id="language"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            >
              <option value="en">English</option>
              <option value="es">Spanish</option>
            </select>
          </div>
          <div>
            <label htmlFor="email" className="block text-gray-600 mb-2">
              Email
            </label>
            <input
              maxLength={100}
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <div className="relative">
            <label htmlFor="password" className="block text-gray-600 mb-2">
              Password
            </label>
            <input
              maxLength={100}
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <div className="relative">
            <label
              htmlFor="confirmPassword"
              className="block text-gray-600 mb-2"
            >
              Confirm Password
            </label>
            <input
              maxLength={100}
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm Password"
              required
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-[#5932EA] bg-opacity-75 text-white py-2 rounded-lg hover:bg-[#5932EA] transition-colors"
          >
            Sign Up
          </button>
        </form>
        {error && <p className="mt-4 text-red-500 text-center">{error}</p>}
        <p className="mt-6 text-gray-600 text-center">
          Already have an account?{" "}
          <Link to="/login-admin" className="text-[#5932EA] hover:underline">
            Login
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignupOrganization;
