import React, { useState, useEffect } from "react";
import { db } from "../../src/db/firebase";
import {
  doc,
  getDocs,
  collection,
  query,
  where,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { useTranslation } from "react-i18next";

interface User {
  id: string;
  firstName: string;
  lastName: string;
}

interface AddMemberModalProps {
  isOpen: boolean;
  onClose: () => void;
  boardId: string;
  listId: string;
  cardId: string;
  onMemberChange: (assignedMembers: string[]) => void;
  assignedMembers: string[];
}

const AddMemberModal: React.FC<AddMemberModalProps> = ({
  isOpen,
  onClose,
  boardId,
  listId,
  cardId,
  onMemberChange,
  assignedMembers: initialAssignedMembers,
}) => {
  const { t } = useTranslation();
  const [allMembers, setAllMembers] = useState<User[]>([]);
  const [assignedMembers, setAssignedMembers] = useState<string[]>(
    initialAssignedMembers
  );

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const membershipsQuery = query(
          collection(db, "memberships"),
          where("boardId", "==", boardId)
        );
        const membershipsSnapshot = await getDocs(membershipsQuery);
        const memberIds = membershipsSnapshot.docs.map(
          (doc) => doc.data().userId
        );

        const usersQuery = query(
          collection(db, "users"),
          where("__name__", "in", memberIds)
        );
        const usersSnapshot = await getDocs(usersQuery);
        const usersData = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
        }));

        setAllMembers(usersData);
      } catch (error) {
        console.error(t("fetchError"), error);
      }
    };

    fetchMembers();
  }, [boardId, t]);

  useEffect(() => {
    setAssignedMembers(initialAssignedMembers);
  }, [initialAssignedMembers]);

  const handleMemberChange = async (memberId: string, isChecked: boolean) => {
    const updatedAssignedMembers = isChecked
      ? [...assignedMembers, memberId]
      : assignedMembers.filter((id) => id !== memberId);

    setAssignedMembers(updatedAssignedMembers);
    onMemberChange(updatedAssignedMembers);

    const cardRef = doc(
      db,
      "boards",
      boardId,
      "lists",
      listId,
      "cards",
      cardId
    );
    const cardDoc = await getDoc(cardRef);
    const currentComments = cardDoc.data()?.comments || [];

    await updateDoc(cardRef, {
      assignedMembers: updatedAssignedMembers,
      comments: currentComments,
    });
  };

  const handleSave = () => {
    onMemberChange(assignedMembers);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded-2xl w-[80%]">
        <h2 className="text-xl mb-4">{t("assignMembersTitle")}</h2>
        {allMembers.map((member) => (
          <div key={member.id}>
            <input
              type="checkbox"
              id={member.id}
              checked={assignedMembers.includes(member.id)}
              onChange={(e) => handleMemberChange(member.id, e.target.checked)}
            />
            <label htmlFor={member.id} className="ml-2">
              {member.firstName} {member.lastName}
            </label>
          </div>
        ))}
        <div className="flex justify-end">
          <button
            onClick={handleSave}
            className="bg-blue-500 text-white p-2 mr-2 w-[80px] rounded-md"
          >
            {t("saveButton")}
          </button>
          <button
            onClick={onClose}
            className="bg-red-500 text-white p-2 w-[80px] rounded-md"
          >
            {t("cancelButton")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddMemberModal;
