// ConfirmationModal.tsx
import React, { useEffect, useRef, useCallback } from "react";
import { ClipLoader } from "react-spinners"; // Import the spinner component

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
  loading: boolean; // Add loading prop
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  message,
  loading,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black opacity-50"></div>{" "}
      {/* Darker backdrop */}
      <div
        ref={modalRef}
        className="relative bg-white p-6 rounded-2xl shadow-lg z-10"
      >
        <p className="mb-4">{message}</p>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 mr-2 text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300"
            disabled={loading} // Disable button while loading
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700"
            disabled={loading} // Disable button while loading
          >
            {loading ? <ClipLoader size={20} color="#fff" /> : "Confirm"}{" "}
            {/* Show spinner if loading */}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
