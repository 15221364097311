import React, { useState, useEffect } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import Divider from "../../../../components/Divider";
import { db } from "../../../../db/firebase";

interface InviteToServiceModalProps {
  eventId: string;
  onClose: () => void;
}

const InviteToServiceModal: React.FC<InviteToServiceModalProps> = ({
  eventId,
  onClose,
}) => {
  const [assignedMembers, setAssignedMembers] = useState<any[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [eventDetails, setEventDetails] = useState<any | null>(null);

  useEffect(() => {
    const fetchEventAndMembers = async () => {
      try {
        // Fetch the event details
        const eventDoc = await getDoc(doc(db, "events", eventId));
        const eventData = eventDoc.data();

        if (eventData && eventData.servingMembers) {
          // Fetch the user details based on servingMembers IDs
          const memberIds = eventData.servingMembers;
          const q = query(
            collection(db, "users"),
            where("id", "in", memberIds)
          );

          const querySnapshot = await getDocs(q);
          const members = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setAssignedMembers(members);
          setEventDetails({ id: eventId, ...eventData }); // Add eventId to eventDetails
        }
      } catch (err) {
        setError("Failed to fetch event and member details.");
      }
    };

    fetchEventAndMembers();
  }, [eventId]);

  const handleInvite = async (e: React.FormEvent) => {
    e.preventDefault();

    if (isSubmitting) {
      return;
    }

    if (selectedMembers.length === 0) {
      setError("Please select at least one member.");
      return;
    }

    if (!eventDetails) {
      setError("Event details are missing.");
      return;
    }

    setIsSubmitting(true);

    console.log(eventDetails); // Event ID should now be included in the logged object

    try {
      const selectedEmails = assignedMembers
        .filter((member) => selectedMembers.includes(member.id))
        .map((member) => member.email);

      const response = await fetch(
        "/.netlify/functions/sendEventNotification",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            emails: selectedEmails,
            eventDetails,
          }),
        }
      );

      if (response.ok) {
        setSuccess("Invitations sent successfully!");
        setSelectedMembers([]);
      } else {
        const errorResponse = await response.json();
        setError(`Failed to send invitations: ${errorResponse.error}`);
      }
    } catch (error) {
      setError("Failed to send invitations. Please try again.");
      console.error("Error sending invitations:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCheckboxChange = (memberId: string) => {
    setSelectedMembers((prevSelectedMembers) =>
      prevSelectedMembers.includes(memberId)
        ? prevSelectedMembers.filter((id) => id !== memberId)
        : [...prevSelectedMembers, memberId]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedMembers([]);
    } else {
      setSelectedMembers(assignedMembers.map((member) => member.id));
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (selectedMembers.length === assignedMembers.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedMembers, assignedMembers]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
      <div className="relative mx-auto p-6 border w-full max-w-lg shadow-lg rounded-2xl bg-white">
        <div className="text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Invite to Service
          </h3>
          <div className="mt-4">
            <form onSubmit={handleInvite} className="space-y-4">
              <div>
                <div className="flex items-center mb-2 p-2">
                  <input
                    type="checkbox"
                    id="select-all"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    className="mr-2"
                  />
                  <label htmlFor="select-all" className="text-gray-700">
                    Select All
                  </label>
                </div>
                <Divider />
                <ul className="max-h-40 overflow-y-auto px-2 rounded-2xl">
                  {assignedMembers.map((member) => (
                    <li key={member.id} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        id={`member-${member.id}`}
                        checked={selectedMembers.includes(member.id)}
                        onChange={() => handleCheckboxChange(member.id)}
                        className="mr-2"
                      />
                      <label
                        htmlFor={`member-${member.id}`}
                        className="text-gray-700"
                      >
                        {member.firstName} {member.lastName}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
              <Divider />
              <button
                className="mt-6 w-full py-2 px-4 bg-[#5932EA] bg-opacity-75  text-white rounded-full hover:bg-[#5932EA] transition-colors"
                disabled={isSubmitting}
              >
                Invite
              </button>
              {error && <p className="text-red-500 mt-4">{error}</p>}
              {success && <p className="text-green-500 mt-4">{success}</p>}
            </form>
          </div>
        </div>
        <div className="mt-4">
          <button
            className="w-full px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-2xl shadow-sm hover:bg-gray-600 transition-colors"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default InviteToServiceModal;
