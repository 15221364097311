import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../db/firebase";
import { useTranslation } from "react-i18next";
import ServiceEventDetail from "../Organization/Components/ServiceEventDetail";

interface User {
  id: string;
  firstName: string;
  lastName: string;
}

interface Song {
  id: string;
  title: string;
  artist: string;
  album: string;
  artworkURL: string;
}

interface Comment {
  id: string;
  userName: string;
  comment: string;
  timestamp: string;
  attachmentUrl?: string;
  attachmentType?: string;
  attachmentName?: string;
  isAudio?: boolean;
}

const UserEventDetail: React.FC = () => {
  const { t } = useTranslation();
  const { eventId } = useParams<{ eventId: string }>();
  const [event, setEvent] = useState<any | null>(null);
  const [leaders, setLeaders] = useState<User[]>([]);
  const [members, setMembers] = useState<User[]>([]);
  const [songs, setSongs] = useState<Song[]>([]);
  const [comments, setComments] = useState<Comment[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        if (eventId) {
          const eventDocRef = doc(db, "events", eventId);
          const eventDoc = await getDoc(eventDocRef);
          if (eventDoc.exists()) {
            const eventData = eventDoc.data();
            setEvent(eventData);

            // Fetch leaders and members details
            const leaderPromises = (eventData.leaders || []).map(
              async (leaderId: string) => {
                const userDoc = await getDoc(doc(db, "users", leaderId));
                if (userDoc.exists()) {
                  const userData = userDoc.data();
                  return {
                    id: userDoc.id,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                  };
                }
                return null;
              }
            );

            const memberPromises = (eventData.members || []).map(
              async (memberId: string) => {
                const userDoc = await getDoc(doc(db, "users", memberId));
                if (userDoc.exists()) {
                  const userData = userDoc.data();
                  return {
                    id: userDoc.id,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                  };
                }
                return null;
              }
            );

            const leaderResults = await Promise.all(leaderPromises);
            const memberResults = await Promise.all(memberPromises);

            setLeaders(leaderResults.filter((user) => user !== null) as User[]);
            setMembers(memberResults.filter((user) => user !== null) as User[]);

            // Fetch songs details
            const songPromises = (eventData.songs || []).map(
              async (songId: string) => {
                const songDoc = await getDoc(doc(db, "songs", songId));
                if (songDoc.exists()) {
                  const songData = songDoc.data();
                  return {
                    id: songDoc.id,
                    title: songData.title,
                    artist: songData.artist,
                    album: songData.album,
                    artworkURL: songData.artworkURL,
                  };
                }
                return null;
              }
            );

            const songResults = await Promise.all(songPromises);
            setSongs(songResults.filter((song) => song !== null) as Song[]);

            // Fetch and set comments
            setComments(eventData.comments || []);
          } else {
            setError(t("eventDoesNotExist"));
          }
        } else {
          setError(t("invalidEventId"));
        }
      } catch (error) {
        console.error(t("errorFetchingEventDetails"), error);
        setError(t("failedToFetchEventDetails"));
      }
    };

    fetchEventDetails();
  }, [eventId, t]);

  const getInitials = (firstName: string, lastName: string) => {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  };

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4 sm:p-6 lg:p-8">
        <div className="bg-white p-8 rounded-2xl shadow-md w-full max-w-md text-center">
          <p className="text-red-500">{error}</p>
        </div>
      </div>
    );
  }

  if (!event) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4 sm:p-6 lg:p-8">
        <div className="bg-white p-8 rounded-2xl shadow-md w-full max-w-md text-center">
          <p className="text-gray-700">{t("loading")}</p>
        </div>
      </div>
    );
  }

  // Render ServiceEventDetail if the event type is "Service"
  return event.type === "Service" ? (
    <ServiceEventDetail
      event={event}
      eventId={eventId!}
      organizationId={event.organizationId!}
    />
  ) : (
    <div className="min-h-screen flex flex-col items-center bg-gray-100 p-4 sm:p-6 lg:p-8">
      <div className="bg-white p-8 rounded-2xl w-full ">
        <h2 className="text-3xl font-bold mb-6 text-gray-700 text-center">
          {event.title}
        </h2>
        <p className="text-lg mb-4 text-gray-700">{event.description}</p>
        <p className="mb-2 text-gray-600">
          {t("date")}: {t("date")}:{" "}
          {new Date(`${event.date}T00:00:00`).toLocaleDateString()}
          {t("time")}: {event.time || "N/A"}
        </p>
      </div>

      <div className="mt-4 bg-white w-full p-4 rounded-2xl">
        <h3 className="text-2xl font-semibold mb-4 text-gray-700">
          {t("details")}
        </h3>
        <p className="text-lg text-gray-700 mb-2">
          {t("type")}: {event.type}
        </p>
        {leaders.length > 0 && (
          <div className="mt-4">
            <h4 className="text-xl font-semibold mb-2 text-gray-700">
              {t("leaders")}
            </h4>
            <div className="flex space-x-2">
              {leaders.map((leader) => (
                <div
                  key={leader.id}
                  className="bg-blue-500 text-white p-2 rounded-full w-10 h-10 flex items-center justify-center"
                  title={`${leader.firstName} ${leader.lastName}`}
                >
                  {getInitials(leader.firstName, leader.lastName)}
                </div>
              ))}
            </div>
          </div>
        )}
        {members.length > 0 && (
          <div className="mt-4">
            <h4 className="text-xl font-semibold mb-2 text-gray-700">
              {t("members")}
            </h4>
            <div className="flex space-x-2">
              {members.map((member) => (
                <div
                  key={member.id}
                  className="bg-green-500 text-white p-2 rounded-full w-10 h-10 flex items-center justify-center"
                  title={`${member.firstName} ${member.lastName}`}
                >
                  {getInitials(member.firstName, member.lastName)}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {songs.length > 0 && (
        <div className="mt-4 w-full">
          <h4 className="text-xl font-semibold mb-2 text-gray-700">
            {t("songs")}
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {songs.map((song: Song) => (
              <Link
                key={song.id}
                to={`/songs/${song.id}`}
                className="bg-white rounded-2xl overflow-hidden"
              >
                <div className="flex">
                  <img
                    src={song.artworkURL}
                    alt={`${song.title} artwork`}
                    className="w-[120px] h-[120px] object-cover"
                  />
                  <div className="p-4">
                    <h5 className="text-lg font-semibold text-gray-800">
                      {song.title}
                    </h5>
                    <p className="text-gray-600">{song.artist}</p>
                    <p className="text-gray-600">{song.album}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}

      <div className="mt-8 w-full bg-white p-4 rounded-2xl">
        <h4 className="text-xl font-semibold mb-4 text-gray-700">
          {t("notes")}
        </h4>
        {comments.length > 0 ? (
          <div className="space-y-4">
            {comments.map((comment) => (
              <div
                key={comment.id}
                className="bg-gray-100 p-4 rounded-2xl shadow-md"
              >
                <div className="mb-2">
                  <strong>{comment.userName}:</strong>{" "}
                  <span className="text-gray-600">
                    {new Date(comment.timestamp).toLocaleString()}
                  </span>
                </div>
                <p className="text-gray-700">{comment.comment}</p>
                {comment.attachmentUrl && (
                  <div className="mt-2">
                    {comment.attachmentType === "image" && (
                      <img
                        src={comment.attachmentUrl}
                        alt="Attachment"
                        className="max-w-full h-auto rounded-md"
                      />
                    )}
                    {comment.attachmentType === "audio" && (
                      <audio controls className="w-full mt-2">
                        <source src={comment.attachmentUrl} type="audio/mpeg" />
                        {t("browserNotSupportAudio")}
                      </audio>
                    )}
                    {comment.attachmentType !== "image" &&
                      comment.attachmentType !== "audio" && (
                        <a
                          href={comment.attachmentUrl}
                          download={comment.attachmentName}
                          className="text-blue-500 underline"
                        >
                          {comment.attachmentName || t("downloadAttachment")}
                        </a>
                      )}
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-600">{t("noComments")}</p>
        )}
      </div>
    </div>
  );
};

export default UserEventDetail;
