import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../db/firebase";
import {
  FaHome,
  FaUsers,
  FaChurch,
  FaClipboardList,
  FaCalendarAlt,
  FaMusic,
  FaCog,
  FaSignOutAlt,
  FaEnvelope,
  FaSearch,
} from "react-icons/fa";
import { useTranslation } from "react-i18next"; // Import useTranslation

const Sidebar: React.FC<{ isOpen: boolean; toggleSidebar: () => void }> = ({
  isOpen,
  toggleSidebar,
}) => {
  const { t } = useTranslation(); // Initialize translation
  const navigate = useNavigate();

  const handleLogout = async () => {
    await signOut(auth);
    navigate("/login");
    toggleSidebar();
  };

  return (
    <div
      className={`fixed inset-y-0 left-0 transform ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } md:translate-x-0 transition-transform duration-300 ease-in-out w-64 h-full text-gray-600 bg-white z-50`}
    >
      <div className="p-6">
        <h1 className="text-3xl font-bold">{t("dashboard")}</h1>
        <ul className="mt-6">
          <Link
            to="/organization/dashboard"
            className="text-lg hover:transition-opacity hover:text-[#5932EA]"
            onClick={toggleSidebar}
          >
            <li className="my-4 flex items-center hover:text-[#5932EA] hover:bg-[#5932EA] hover:bg-opacity-10 hover:rounded-xl py-2">
              <FaHome className="mx-3 hover:text-[#5932EA]" />
              {t("home")}
            </li>
          </Link>
          <Link
            to="/organization/users"
            className="text-lg hover:transition-opacity hover:text-[#5932EA]"
            onClick={toggleSidebar}
          >
            <li className="my-4 flex items-center hover:text-[#5932EA] hover:bg-[#5932EA] hover:bg-opacity-10 hover:rounded-xl py-2">
              <FaUsers className="mx-3 hover:text-[#5932EA]" />
              {t("users")}
            </li>
          </Link>
          <Link
            to="/organization/ministries"
            className="text-lg hover:transition-opacity hover:text-[#5932EA]"
            onClick={toggleSidebar}
          >
            <li className="my-4 flex items-center hover:text-[#5932EA] hover:bg-[#5932EA] hover:bg-opacity-10 hover:rounded-xl py-2">
              <FaChurch className="mx-3 hover:text-[#5932EA]" />
              {t("ministries")}
            </li>
          </Link>
          <Link
            to="/organization/groups"
            className="text-lg hover:transition-opacity hover:text-[#5932EA]"
            onClick={toggleSidebar}
          >
            <li className="my-4 flex items-center hover:text-[#5932EA] hover:bg-[#5932EA] hover:bg-opacity-10 hover:rounded-xl py-2">
              <FaClipboardList className="mx-3 hover:text-[#5932EA]" />
              {t("groups")}
            </li>
          </Link>

          <Link
            to="/organization/events"
            className="text-lg hover:transition-opacity hover:text-[#5932EA]"
            onClick={toggleSidebar}
          >
            <li className="my-4 flex items-center hover:text-[#5932EA] hover:bg-[#5932EA] hover:bg-opacity-10 hover:rounded-xl py-2">
              <FaCalendarAlt className="mx-3 hover:text-[#5932EA]" />
              {t("events")}
            </li>
          </Link>
          <Link
            to="/organization/song-search"
            className="text-lg hover:transition-opacity hover:text-[#5932EA]"
            onClick={toggleSidebar}
          >
            <li className="my-4 flex items-center hover:text-[#5932EA] hover:bg-[#5932EA] hover:bg-opacity-10 hover:rounded-xl py-2">
              <FaSearch className="mx-3 hover:text-[#5932EA]" />
              {t("songImporter")}
            </li>
          </Link>
          <Link
            to="/organization/songs"
            className="text-lg hover:transition-opacity hover:text-[#5932EA]"
            onClick={toggleSidebar}
          >
            <li className="my-4 flex items-center hover:text-[#5932EA] hover:bg-[#5932EA] hover:bg-opacity-10 hover:rounded-xl py-2">
              <FaMusic className="mx-3 hover:text-[#5932EA]" />
              {t("songs")}
            </li>
          </Link>
          <Link
            to="/organization/communications"
            className="text-lg hover:transition-opacity hover:text-[#5932EA]"
            onClick={toggleSidebar}
          >
            <li className="my-4 flex items-center hover:text-[#5932EA] hover:bg-[#5932EA] hover:bg-opacity-10 hover:rounded-xl py-2">
              <FaEnvelope className="mx-3 hover:text-[#5932EA]" />
              {t("sms")}
            </li>
          </Link>
          <Link
            to="/organization/settings"
            className="text-lg hover:transition-opacity hover:text-[#5932EA]"
            onClick={toggleSidebar}
          >
            <li className="my-4 flex items-center hover:text-[#5932EA] hover:bg-[#5932EA] hover:bg-opacity-10 hover:rounded-xl py-2">
              <FaCog className="mx-3 hover:text-[#5932EA]" />
              {t("settings")}
            </li>
          </Link>

          <li
            className="my-4 flex items-center hover:text-[#5932EA] hover:bg-[#5932EA] hover:bg-opacity-10 hover:rounded-xl py-2"
            onClick={handleLogout}
          >
            <FaSignOutAlt className="mx-3 hover:text-[#5932EA]" />
            <button className="text-lg hover:transition-opacity hover:text-[#5932EA]">
              {t("logout")}
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
