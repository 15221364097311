import React from "react";

interface EventHeaderProps {
  title: string;
  description: string;
  date: string;
  time: string;
}

const EventHeader: React.FC<EventHeaderProps> = ({
  title,
  description,
  date,
  time,
}) => {
  return (
    <div>
      <h1 className="text-3xl font-bold text-gray-700">{title}</h1>
      <p className="text-gray-400 mb-4 mt-2">{description}</p>
      <p className="text-gray-400 mb-4">
        <strong></strong> {date} <strong> - </strong> {time}
      </p>
    </div>
  );
};

export default EventHeader;
