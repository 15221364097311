import React, { useState } from "react";
import CommentList from "./CommentList";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../db/firebase";
import { useTranslation } from "react-i18next";
import CommentInputModal from "../../Components/CommentInputModal";

interface CommentSectionProps {
  eventId: string;
  initialComments: Comment[];
}

const CommentSection: React.FC<CommentSectionProps> = ({
  eventId,
  initialComments,
}) => {
  const { t } = useTranslation();
  const [comments, setComments] = useState<Comment[]>(initialComments);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAddComment = async (newComment: any) => {
    const updatedComments = [newComment, ...comments];
    setComments(updatedComments);

    const eventDocRef = doc(db, "events", eventId);
    await updateDoc(eventDocRef, { comments: updatedComments });
    setIsModalOpen(false); // Close the modal after adding a comment
  };

  const handleDeleteComment = async (commentId: string) => {
    const updatedComments = comments.filter(
      (comment) => comment.id !== commentId
    );
    setComments(updatedComments);

    const eventDocRef = doc(db, "events", eventId);
    await updateDoc(eventDocRef, { comments: updatedComments });
  };

  return (
    <div className="bg-white rounded-2xl h-full">
      <h3 className="text-xl font-bold mb-2 px-4 pt-4">{t("notes")}</h3>

      <div className="flex flex-col justify-between w-full ">
        <CommentList
          comments={comments}
          onDeleteComment={handleDeleteComment}
        />
        <button
          onClick={() => setIsModalOpen(true)}
          className=" mx-2 mt-2 mb-4 bg-[#5932EA] bg-opacity-75 text-white hover:bg-[#5932EA] transition-colors p-2 rounded-2xl"
        >
          {t("addNotesButton")}
        </button>
      </div>

      <CommentInputModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onAddComment={handleAddComment}
      />
    </div>
  );
};

export default CommentSection;
