import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../db/firebase";
import Divider from "../../../components/Divider";

interface InviteToEventModalProps {
  eventId: string;
  organizationId: string;
  onClose: () => void;
  leaders: string[];
  members: string[];
}

const InviteToEventModal: React.FC<InviteToEventModalProps> = ({
  eventId,
  organizationId,
  onClose,
  leaders,
  members,
}) => {
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [eventDetails, setEventDetails] = useState<any | null>(null);

  useEffect(() => {
    const fetchUsersAndEvent = async () => {
      try {
        const userDocs = await Promise.all(
          [...leaders, ...members].map((userId) =>
            getDoc(doc(db, "users", userId))
          )
        );
        const userData = userDocs
          .map((doc) => (doc.exists() ? { id: doc.id, ...doc.data() } : null))
          .filter((user) => user !== null);
        setUsers(userData);

        const eventDoc = await getDoc(doc(db, "events", eventId));
        if (eventDoc.exists()) {
          setEventDetails({ id: eventId, ...eventDoc.data() });
        } else {
          setError("Event not found.");
        }
      } catch (err) {
        setError("Failed to fetch users or event details.");
      }
    };

    fetchUsersAndEvent();
  }, [leaders, members, eventId]);

  const handleInvite = async (e: React.FormEvent) => {
    e.preventDefault();

    if (isSubmitting) {
      return;
    }

    if (selectedUsers.length === 0) {
      setError("Please select at least one user.");
      return;
    }

    if (!eventDetails) {
      setError("Event details are missing.");
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch(
        "/.netlify/functions/sendEventNotification",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            emails: selectedUsers.map((userId) => {
              const user = users.find((user) => user.id === userId);
              return user ? user.email : "";
            }),
            eventDetails,
          }),
        }
      );

      if (response.ok) {
        setSuccess("Invitations sent successfully!");
        setSelectedUsers([]);
      } else {
        const errorResponse = await response.json();
        setError(`Failed to send invitations: ${errorResponse.error}`);
      }
    } catch (error) {
      setError("Failed to send invitations. Please try again.");
      console.error("Error sending invitations:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCheckboxChange = (userId: string) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.includes(userId)
        ? prevSelectedUsers.filter((id) => id !== userId)
        : [...prevSelectedUsers, userId]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(users.map((user) => user.id));
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (selectedUsers.length === users.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedUsers, users]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
      <div className="relative mx-auto p-6 border w-full max-w-lg shadow-lg rounded-2xl bg-white">
        <div className="text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Invite to Event
          </h3>
          <div className="mt-4">
            <form onSubmit={handleInvite} className="space-y-4">
              <div>
                <div className="flex items-center mb-2 p-2">
                  <input
                    type="checkbox"
                    id="select-all"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    className="mr-2"
                  />
                  <label htmlFor="select-all" className="text-gray-700">
                    Select All
                  </label>
                </div>
                <Divider />
                <ul className="max-h-40 overflow-y-auto px-2 rounded-2xl">
                  {users.map((user) => (
                    <li key={user.id} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        id={`user-${user.id}`}
                        checked={selectedUsers.includes(user.id)}
                        onChange={() => handleCheckboxChange(user.id)}
                        className="mr-2"
                      />
                      <label
                        htmlFor={`user-${user.id}`}
                        className="text-gray-700"
                      >
                        {user.firstName} {user.lastName}
                        {/* ({user.email}) */}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
              <Divider />
              <button
                className="mt-6 w-full py-2 px-4 bg-[#5932EA] bg-opacity-75  text-white rounded-full hover:bg-[#5932EA] transition-colors"
                disabled={isSubmitting}
              >
                Invite
              </button>
              {error && <p className="text-red-500 mt-4">{error}</p>}
              {success && <p className="text-green-500 mt-4">{success}</p>}
            </form>
          </div>
        </div>
        <div className="mt-4">
          <button
            className="w-full px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-2xl shadow-sm hover:bg-gray-600 transition-colors"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default InviteToEventModal;
