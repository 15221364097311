import React, { useState, useEffect } from "react";
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../db/firebase";
import Modal from "./Modal";

import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { MdDragIndicator } from "react-icons/md";
import CreatableSelect from "react-select/creatable";
import InviteToServiceModal from "../Events/Components/InviteToServiceModal";
import Divider from "../../../components/Divider";

const ItemTypes = {
  SERVICE_ITEM: "serviceItem",
};

const ServiceEventDetail: React.FC<{
  event: any;
  eventId: string;
  organizationId: string;
}> = ({ event, eventId, organizationId }) => {
  const [serviceOrder, setServiceOrder] = useState(event.serviceOrder || []);
  const [newItem, setNewItem] = useState("");
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("00:00");
  const [assignedMember, setAssignedMember] = useState<any>(null);
  const [itemType, setItemType] = useState("");
  const [selectedWorshipEvent, setSelectedWorshipEvent] = useState("");
  const [worshipEvents, setWorshipEvents] = useState<any[]>([]);
  const [songs, setSongs] = useState<any[]>([]);
  const [announcementTitle, setAnnouncementTitle] = useState("");
  const [announcementFile, setAnnouncementFile] = useState<File | null>(null);
  const [announcements, setAnnouncements] = useState<
    { title: string; file: File | null }[]
  >([]);
  const [sermonNote, setSermonNote] = useState("");
  const [sermonNotes, setSermonNotes] = useState<
    { note: string; attachments: File[] }[]
  >([]);
  const [sermonAttachments, setSermonAttachments] = useState<File[]>([]);
  const [members, setMembers] = useState<any[]>([]);
  const [temporaryMembers, setTemporaryMembers] = useState<any[]>([]);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editAnnouncementIndex, setEditAnnouncementIndex] = useState<
    number | null
  >(null);
  const [editSermonNoteIndex, setEditSermonNoteIndex] = useState<number | null>(
    null
  );
  const [comments, setComments] = useState<string[]>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false); // State for InviteToServiceModal

  useEffect(() => {
    const fetchMembers = async () => {
      const q = query(
        collection(db, "users"),
        where("organizationId", "==", organizationId)
      );
      const querySnapshot = await getDocs(q);
      const membersList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMembers(membersList);
    };

    fetchMembers();
  }, [organizationId]);

  useEffect(() => {
    const fetchWorshipEvents = async () => {
      if (itemType === "Worship") {
        const q = query(
          collection(db, "events"),
          where("organizationId", "==", organizationId),
          where("type", "==", "Worship")
        );
        const querySnapshot = await getDocs(q);
        const eventsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setWorshipEvents(eventsList);
      }
    };

    fetchWorshipEvents();
  }, [itemType, organizationId]);

  useEffect(() => {
    const fetchSongs = async () => {
      if (selectedWorshipEvent) {
        const eventDoc = await getDoc(doc(db, "events", selectedWorshipEvent));
        const eventData = eventDoc.data();
        const songIds = eventData?.songs || [];

        const fetchedSongs = await Promise.all(
          songIds.map(async (songId: string) => {
            const songDoc = await getDoc(doc(db, "songs", songId));
            return { id: songDoc.id, ...songDoc.data() };
          })
        );

        setSongs(fetchedSongs);
      }
    };

    fetchSongs();
  }, [selectedWorshipEvent]);

  const moveItem = (dragIndex: number, hoverIndex: number) => {
    const updatedOrder = [...serviceOrder];
    const [movedItem] = updatedOrder.splice(dragIndex, 1);
    updatedOrder.splice(hoverIndex, 0, movedItem);
    setServiceOrder(updatedOrder);
  };

  const handleDrop = async () => {
    try {
      const eventDocRef = doc(db, "events", eventId);
      await updateDoc(eventDocRef, {
        serviceOrder,
      });
    } catch (error) {
      console.error("Error saving service order:", error);
    }
  };

  const ServiceItem: React.FC<{
    item: any;
    index: number;
    moveItem: (dragIndex: number, hoverIndex: number) => void;
    handleDrop: () => void;
  }> = ({ item, index, moveItem, handleDrop }) => {
    const ref = React.useRef<HTMLTableRowElement>(null);
    const [, drop] = useDrop({
      accept: ItemTypes.SERVICE_ITEM,
      hover(draggedItem: { index: number }) {
        if (!ref.current) {
          return;
        }
        const dragIndex = draggedItem.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        moveItem(dragIndex, hoverIndex);
        draggedItem.index = hoverIndex;
      },
      drop: handleDrop,
    });

    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.SERVICE_ITEM,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    drag(drop(ref));

    return (
      <tr
        ref={ref}
        className={`hover:bg-gray-50 ${
          isDragging ? "opacity-50" : "opacity-100"
        }`}
        style={{ cursor: "move" }}
      >
        <td className="px-2 md:px-4 py-2 border">
          <MdDragIndicator className="text-gray-500" />
        </td>
        <td className="px-2 md:px-4 py-2 border">
          {item.startTime} - {item.endTime}
        </td>
        <td className="px-2 md:px-4 py-2 border">{item.title}</td>
        <td className="px-2 md:px-4 py-2 border">{item.type || "Worship"}</td>
        <td className="px-2 md:px-4 py-2 border">
          {item.member?.firstName} {item.member?.lastName}
        </td>
        <td className="px-2 md:px-4 py-2 border flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
          <button
            onClick={() => handleEditItem(index)}
            className={`bg-[#2FEA9B] bg-opacity-[38%] border-2 border-[#008767] text-[#008767] px-4 py-2 rounded-2xl hover:bg-[#008767] hover:text-white transition-colors flex items-center`}
          >
            Edit
          </button>
          <button
            onClick={() => handleDeleteItem(index)}
            className={`bg-[#FFC5C5] text-[#DF0404] border-2 border-[#DF0404] px-4 py-2 rounded-2xl hover:bg-[#DF0404] hover:text-white transition-colors flex items-center`}
          >
            Delete
          </button>
        </td>
      </tr>
    );
  };

  const handleAddAnnouncement = () => {
    if (announcementTitle.trim() !== "" || announcementFile) {
      const newAnnouncement = {
        title: announcementTitle,
        file: announcementFile,
      };

      if (editAnnouncementIndex !== null) {
        const updatedAnnouncements = announcements.map((announcement, index) =>
          index === editAnnouncementIndex ? newAnnouncement : announcement
        );
        setAnnouncements(updatedAnnouncements);
        setEditAnnouncementIndex(null);
      } else {
        setAnnouncements([...announcements, newAnnouncement]);
      }

      setAnnouncementTitle("");
      setAnnouncementFile(null);
    }
  };

  const handleEditAnnouncement = (index: number) => {
    const announcement = announcements[index];
    setAnnouncementTitle(announcement.title);
    setAnnouncementFile(announcement.file);
    setEditAnnouncementIndex(index);
  };

  const handleRemoveAnnouncement = (index: number) => {
    const updatedAnnouncements = announcements.filter((_, i) => i !== index);
    setAnnouncements(updatedAnnouncements);
    setEditAnnouncementIndex(null);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setAnnouncementFile(file);
    }
  };

  const handleSermonFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = Array.from(event.target.files || []);
    setSermonAttachments([...sermonAttachments, ...files]);
  };

  const handleAddSermonNote = () => {
    if (sermonNote.trim() !== "" || sermonAttachments.length > 0) {
      const newSermon = {
        note: sermonNote,
        attachments: sermonAttachments,
      };

      if (editSermonNoteIndex !== null) {
        const updatedSermonNotes = sermonNotes.map((sermon, index) =>
          index === editSermonNoteIndex ? newSermon : sermon
        );
        setSermonNotes(updatedSermonNotes);
        setEditSermonNoteIndex(null);
      } else {
        setSermonNotes([...sermonNotes, newSermon]);
      }

      setSermonNote("");
      setSermonAttachments([]);
    }
  };

  const handleEditSermonNote = (index: number) => {
    const sermon = sermonNotes[index];
    setSermonNote(sermon.note);
    setSermonAttachments(sermon.attachments);
    setEditSermonNoteIndex(index);
  };

  const handleRemoveSermonNote = (index: number) => {
    const updatedSermonNotes = sermonNotes.filter((_, i) => i !== index);
    setSermonNotes(updatedSermonNotes);
    setEditSermonNoteIndex(null);
  };

  const handleAddItem = async () => {
    if (
      newItem?.trim() !== "" &&
      startTime?.trim() !== "" &&
      endTime?.trim() !== ""
    ) {
      let updatedServiceOrder;
      if (editIndex !== null) {
        updatedServiceOrder = serviceOrder.map((item, index) =>
          index === editIndex
            ? {
                startTime,
                endTime,
                title: newItem,
                member: assignedMember, // This can be null
                type: itemType,
                announcements:
                  itemType === "Announcements" ? announcements : [],
                sermonNotes: itemType === "Sermon" ? sermonNotes : [],
                comments: itemType === "Sermon" ? comments : [],
                worshipEventId:
                  itemType === "Worship" ? selectedWorshipEvent : null,
                songs: itemType === "Worship" ? songs : [],
              }
            : item
        );
        setEditIndex(null);
      } else {
        updatedServiceOrder = [
          ...serviceOrder,
          {
            startTime,
            endTime,
            title: newItem,
            member: assignedMember, // This can be null
            type: itemType,
            announcements: itemType === "Announcements" ? announcements : [],
            sermonNotes: itemType === "Sermon" ? sermonNotes : [],
            comments: itemType === "Sermon" ? comments : [],
            worshipEventId:
              itemType === "Worship" ? selectedWorshipEvent : null,
            songs: itemType === "Worship" ? songs : [],
          },
        ];
      }

      setServiceOrder(updatedServiceOrder);
      setNewItem("");
      setStartTime("00:00");
      setEndTime("00:00");
      setAssignedMember(null);
      setItemType("");
      setAnnouncements([]);
      setSermonNotes([]);
      setComments([]);
      setSelectedWorshipEvent("");
      setSongs([]);
      setIsModalOpen(false);

      try {
        const eventDocRef = doc(db, "events", eventId);

        // Get the existing servingMembers array or initialize it as empty
        const eventDoc = await getDoc(eventDocRef);
        const eventData = eventDoc.data();
        const existingServingMembers = eventData?.servingMembers || [];

        // Add the assigned member to servingMembers if not already included
        const updatedServingMembers = assignedMember
          ? [...new Set([...existingServingMembers, assignedMember.id])]
          : existingServingMembers;

        // Update the service order and serving members
        await updateDoc(eventDocRef, {
          serviceOrder: updatedServiceOrder,
          servingMembers: updatedServingMembers,
        });
      } catch (error) {
        console.error("Error updating service order:", error);
      }
    }
  };

  const handleDeleteItem = async (index: number) => {
    const updatedServiceOrder = serviceOrder.filter((_, i) => i !== index);
    setServiceOrder(updatedServiceOrder);

    try {
      const eventDocRef = doc(db, "events", eventId);
      await updateDoc(eventDocRef, {
        serviceOrder: updatedServiceOrder,
      });
    } catch (error) {
      console.error("Error updating service order:", error);
    }
  };

  const handleEditItem = (index: number) => {
    const item = serviceOrder[index];
    setStartTime(item.startTime);
    setEndTime(item.endTime);
    setNewItem(item.title);
    setAssignedMember(item.member);
    setItemType(item.type || "");
    setAnnouncements(item.announcements || []);
    setSermonNotes(item.sermonNotes || []);
    setComments(item.comments || []);
    setSelectedWorshipEvent(item.worshipEventId || "");
    setSongs(item.songs || []);
    setEditIndex(index);
    setIsModalOpen(true);
  };

  const openModal = () => {
    setStartTime("00:00");
    setEndTime("00:00");
    setNewItem("");
    setAssignedMember(null);
    setItemType("");
    setAnnouncements([]);
    setSermonNotes([]);
    setComments([]);
    setSelectedWorshipEvent("");
    setSongs([]);
    setEditIndex(null);
    setIsModalOpen(true);
  };

  const openInviteModal = () => {
    setIsInviteModalOpen(true);
  };

  const closeInviteModal = () => {
    setIsInviteModalOpen(false);
  };

  const handleAddTemporaryMember = (inputValue: string) => {
    const newMember = {
      id: inputValue,
      firstName: inputValue,
      lastName: "(Temporary)",
    };
    setTemporaryMembers([...temporaryMembers, newMember]);
    setAssignedMember(newMember);
  };

  const memberOptions = [...members, ...temporaryMembers].map((member) => ({
    value: member,
    label: `${member.firstName} ${member.lastName}`,
  }));

  const renderFile = (file: File) => {
    if (file.type.startsWith("image/")) {
      return (
        <img
          src={URL.createObjectURL(file)}
          alt="Attachment"
          className="mt-2"
          style={{ maxWidth: "200px" }}
        />
      );
    }
    if (file.type === "application/pdf") {
      return (
        <embed
          src={URL.createObjectURL(file)}
          type="application/pdf"
          width="100%"
          height="400px"
          className="mt-2"
        />
      );
    }
    return (
      <a
        href={URL.createObjectURL(file)}
        download={file.name}
        className="text-blue-500 underline mt-2 block"
      >
        Download {file.name}
      </a>
    );
  };

  const renderAnnouncement = (announcementTitle: string) => {
    const youtubeRegex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Check if the announcement contains a YouTube link
    const youtubeMatch = announcementTitle.match(youtubeRegex);
    if (youtubeMatch) {
      const videoId = youtubeMatch[1];
      return (
        <div className="mb-4">
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      );
    }

    // Convert any other URLs in the announcement into clickable links
    return announcementTitle.split(" ").map((word, index) => {
      if (urlRegex.test(word)) {
        return (
          <a
            key={index}
            href={word}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            {word}
          </a>
        );
      }
      return <span key={index}>{word} </span>;
    });
  };

  const renderSermonNote = (sermon: { note: string; attachments: File[] }) => {
    const { note, attachments } = sermon;
    const youtubeRegex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Check if the note contains a YouTube link
    const youtubeMatch = note.match(youtubeRegex);
    if (youtubeMatch) {
      const videoId = youtubeMatch[1];
      return (
        <div className="mb-4">
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      );
    }

    // Convert any other URLs in the note into clickable links
    const renderedNote = note.split(" ").map((word, index) => {
      if (urlRegex.test(word)) {
        return (
          <a
            key={index}
            href={word}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            {word}
          </a>
        );
      }
      return <span key={index}>{word} </span>;
    });

    return (
      <div>
        <div>{renderedNote}</div>
        {attachments.length > 0 && (
          <div className="mt-2">
            {attachments.map((file, index) => (
              <div key={index} className="mb-2">
                {renderFile(file)}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4">
          {event.title} - Service Details
        </h2>
        <div className="mb-4">
          <p className="text-gray-700">
            <strong>Description:</strong> {event.description}
          </p>
          <p className="text-gray-700">
            <strong>Date:</strong> {event.date}
          </p>
          <p className="text-gray-700">
            <strong>Time:</strong> {event.time}
          </p>
        </div>

        <h3 className="text-xl font-semibold mb-2">Service Order</h3>
        <table className="table-auto w-full mb-4">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-2 md:px-4 py-2 border"></th>{" "}
              {/* Drag handle column */}
              <th className="px-2 md:px-4 py-2 border">Time Range</th>{" "}
              {/* Time range column */}
              <th className="px-2 md:px-4 py-2 border">Title</th>
              <th className="px-2 md:px-4 py-2 border">Type</th>
              <th className="px-2 md:px-4 py-2 border">Assigned Member</th>
              <th className="px-2 md:px-4 py-2 border">Actions</th>
            </tr>
          </thead>
          <tbody>
            {serviceOrder.map((item, index) => (
              <ServiceItem
                key={index}
                index={index}
                item={item}
                moveItem={moveItem}
                handleDrop={handleDrop}
              />
            ))}
          </tbody>
        </table>

        <div className="flex flex-col md:flex-row md:space-x-4">
          <button
            onClick={openModal}
            className="bg-[#5932EA] bg-opacity-75 text-white px-4 py-2 rounded-2xl hover:bg-[#5932EA] transition-colors"
          >
            Add New Item
          </button>

          <button
            onClick={openInviteModal}
            className="bg-[#5932EA] bg-opacity-75 text-white px-4 py-2 rounded-2xl hover:bg-[#5932EA] transition-colors"
          >
            Send Invite
          </button>
        </div>

        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className="flex flex-col items-start">
            <h3 className="text-lg font-semibold mb-4">
              Add New Item to Event
            </h3>
            <div className="flex flex-col md:flex-row mb-4 w-full">
              <input
                type="time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                className="border rounded px-4 py-2 w-full md:w-1/2 md:mr-2 mb-2 md:mb-0"
                placeholder="Start time"
              />
              <input
                type="time"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                className="border rounded px-4 py-2 w-full md:w-1/2"
                placeholder="End time"
              />
            </div>
            <input
              type="text"
              value={newItem}
              onChange={(e) => setNewItem(e.target.value)}
              className="border rounded px-4 py-2 mb-4 w-full"
              placeholder="New item title"
            />
            <select
              value={itemType}
              onChange={(e) => setItemType(e.target.value)}
              className="border rounded px-4 py-2 mb-4 w-full"
            >
              <option value="">Select Type</option> {/* Placeholder option */}
              <option value="Worship">Worship</option>
              <option value="Announcements">Announcements</option>
              <option value="Sermon">Sermon</option>
              <option value="Other">Other</option>
            </select>

            {itemType === "Worship" && (
              <>
                <select
                  value={selectedWorshipEvent}
                  onChange={(e) => setSelectedWorshipEvent(e.target.value)}
                  className="border rounded px-4 py-2 mb-4 w-full"
                >
                  <option value="">Select a worship event</option>
                  {worshipEvents.map((event) => (
                    <option key={event.id} value={event.id}>
                      {event.title} - {event.date}
                    </option>
                  ))}
                </select>

                <div className="mb-4 w-full">
                  <h4 className="text-lg font-semibold">Event Songs</h4>
                  <ul className="list-disc ml-5">
                    {songs.map((song) => (
                      <li key={song.id} className="mb-2">
                        <a
                          href={`/organization/songs/${song.id}/view`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 underline"
                        >
                          {song.title} by {song.artist}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}

            {itemType === "Sermon" && (
              <>
                <div className="flex flex-col items-start mb-4 w-full">
                  <input
                    type="text"
                    value={sermonNote}
                    onChange={(e) => setSermonNote(e.target.value)}
                    className="border rounded px-4 py-2 mb-2 w-full"
                    placeholder="Add a sermon note"
                  />
                  <input
                    type="file"
                    onChange={handleSermonFileChange}
                    className="border rounded px-4 py-2 mb-2 w-full"
                    multiple
                  />
                  <button
                    onClick={handleAddSermonNote}
                    className="bg-[#5932EA] bg-opacity-75 text-white px-4 py-2 rounded-2xl hover:bg-[#5932EA] transition-colors w-full"
                  >
                    Add Sermon Note
                  </button>
                </div>

                <ul
                  className="list-disc px-2 mt-2 w-full overflow-y-auto my-6 flex flex-col h-[500px]"
                  style={{ maxHeight: "300px" }} // Set the maximum height as per your requirement
                >
                  {sermonNotes.map((sermon, index) => (
                    <div className="flex flex-col">
                      <Divider />
                      <li
                        key={index}
                        className="mb-2 flex flex-col md:flex-row justify-between items-start md:items-center"
                      >
                        <div>
                          <div className="px-3">{renderSermonNote(sermon)}</div>
                          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mt-2 md:mt-0 ">
                            <button
                              onClick={() => handleEditSermonNote(index)}
                              className={` bg-opacity-[38%] text-[#008767] px-4 rounded-2xl  hover:text-[#008767]transition-colors flex items-center`}
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => handleRemoveSermonNote(index)}
                              className={` text-[#DF0404] px-4 py-1 rounded-2xl  hover:text-[#bf3d3d] transition-colors flex items-center`}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </li>
                    </div>
                  ))}
                </ul>
              </>
            )}

            {itemType === "Announcements" && (
              <>
                <div className="flex flex-col items-start mb-4 w-full">
                  <input
                    type="text"
                    value={announcementTitle}
                    onChange={(e) => setAnnouncementTitle(e.target.value)}
                    className="border rounded px-4 py-2 mb-2 w-full"
                    placeholder="Add an announcement"
                  />
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="border rounded px-4 py-2 mb-2 w-full"
                  />
                  <button
                    onClick={handleAddAnnouncement}
                    className="bg-[#5932EA] bg-opacity-75 text-white px-4 py-2 rounded-2xl hover:bg-[#5932EA] transition-colors w-full"
                  >
                    Add Announcement
                  </button>
                </div>
                <ul
                  className="list-disc px-2 mt-2 w-full overflow-y-auto my-6 flex flex-col h-[500px]"
                  style={{ maxHeight: "300px" }} // Set the maximum height as per your requirement
                >
                  {announcements.map((announcement, index) => (
                    <div className="flex flex-col">
                      <Divider />
                      <li
                        key={index}
                        className="mb-2 flex flex-col md:flex-row justify-between items-start md:items-center"
                      >
                        <div>
                          <div className="px-3">
                            {renderAnnouncement(announcement.title)}
                            {announcement.file && renderFile(announcement.file)}
                          </div>
                          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mt-2 md:mt-0 ">
                            <button
                              onClick={() => handleEditAnnouncement(index)}
                              className={` bg-opacity-[38%] text-[#008767] px-4 rounded-2xl  hover:text-[#008767]transition-colors flex items-center`}
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => handleRemoveAnnouncement(index)}
                              className={` text-[#DF0404] px-4 py-1 rounded-2xl  hover:text-[#bf3d3d] transition-colors flex items-center`}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </li>
                    </div>
                  ))}
                </ul>
              </>
            )}

            <CreatableSelect
              value={
                assignedMember
                  ? {
                      value: assignedMember,
                      label: `${assignedMember.firstName} ${assignedMember.lastName}`,
                    }
                  : null
              }
              onChange={(selectedOption) =>
                setAssignedMember(selectedOption ? selectedOption.value : null)
              }
              options={memberOptions}
              onCreateOption={handleAddTemporaryMember}
              placeholder="Search or select a member"
              isClearable
              className="w-full mb-4"
            />

            <button
              onClick={handleAddItem}
              className="bg-[#5932EA] bg-opacity-75 text-white px-4 py-2 rounded-2xl hover:bg-[#5932EA] transition-colors w-full"
            >
              {editIndex !== null ? "Save Changes" : "Add Item"}
            </button>
          </div>
        </Modal>

        {isInviteModalOpen && (
          <InviteToServiceModal eventId={eventId} onClose={closeInviteModal} />
        )}
      </div>
    </DndProvider>
  );
};

export default ServiceEventDetail;
