import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import { db, auth } from "../../db/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { FaUsers, FaCalendarAlt, FaChurch, FaMusic } from "react-icons/fa"; // Import necessary icons
import Graphs from "./Components/Graphs";
import StatCard from "./Components/StatCard";
import { useTranslation } from "react-i18next"; // Import useTranslation

const AdminDashboard: React.FC = () => {
  const [user] = useAuthState(auth);
  const [approvedUsers, setApprovedUsers] = useState([]);
  const [ministriesCount, setMinistriesCount] = useState(0);
  const [eventsCount, setEventsCount] = useState(0);
  const [songsCount, setSongsCount] = useState(0);
  const [error, setError] = useState("");
  const [userName, setUserName] = useState("");
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation(); // Initialize translation

  useEffect(() => {
    const fetchUserRoleAndOrganization = async () => {
      if (user) {
        try {
          // Fetch user document
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            if (userData.role === "admin") {
              setIsAdmin(true);
              setOrganizationId(userData.organizationId);
            }
          }

          // Check if the user is an organization admin
          const orgDoc = await getDoc(doc(db, "organizations", user.uid));
          if (orgDoc.exists()) {
            setOrganizationId(orgDoc.id);
          }
        } catch (err) {
          setError(t("errorFetchingUsers"));
        }
      }
    };

    fetchUserRoleAndOrganization();
  }, [user, t]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (user && (organizationId || isAdmin)) {
        try {
          const organizationAdminId = organizationId || user.uid;

          const usersCollection = collection(db, "users");
          const usersQuery = query(
            usersCollection,
            where("organizationId", "==", organizationAdminId)
          );
          const usersSnapshot = await getDocs(usersQuery);

          const usersList = usersSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setApprovedUsers(usersList.filter((user) => user.approved === true));

          if (isAdmin) {
            const userDoc = await getDoc(doc(db, "users", user.uid));
            if (userDoc.exists()) {
              const firstName = userDoc.data().firstName;
              const lastName = userDoc.data().lastName;
              setUserName(`${firstName} ${lastName}`);
            }
          } else if (organizationId) {
            const orgDoc = await getDoc(
              doc(db, "organizations", organizationId)
            );
            if (orgDoc.exists()) {
              const orgName = orgDoc.data().orgName;
              setUserName(orgName);
            }
          }
        } catch (err) {
          setError(t("errorFetchingUsers"));
        }
      }
    };

    const fetchCounts = async () => {
      if (user && (organizationId || isAdmin)) {
        try {
          const organizationAdminId = organizationId || user.uid;

          const ministriesSnapshot = await getDocs(
            query(
              collection(db, "ministries"),
              where("organizationId", "==", organizationAdminId)
            )
          );
          setMinistriesCount(ministriesSnapshot.size);

          const eventsSnapshot = await getDocs(
            query(
              collection(db, "events"),
              where("organizationId", "==", organizationAdminId)
            )
          );
          setEventsCount(eventsSnapshot.size);

          // Fetching the total number of songs in the "songs" collection
          const songsSnapshot = await getDocs(collection(db, "songs"));
          setSongsCount(songsSnapshot.size);
        } catch (err) {
          setError(t("errorFetchingCounts"));
        }
      }
    };

    const fetchData = async () => {
      setLoading(true);
      await fetchUsers();
      await fetchCounts();
      setLoading(false);
    };

    fetchData();
  }, [user, organizationId, isAdmin, t]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-center">
          <p className="text-gray-700">{t("loading")}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen md:flex-row">
      <div className="flex-1 ">
        <h1 className="ml-4 text-2xl font-bold text-gray-700 sm:text-3xl md:ml-12 md:text-3xl">
          {t("welcome")} {userName} 👋
        </h1>
        <div className="p-4 mx-auto mt-4 bg-white rounded-2xl shadow-md sm:max-w-7xl sm:p-6">
          <div className="grid grid-cols-1 gap-6 mb-6 sm:grid-cols-2 lg:grid-cols-4">
            <StatCard
              icon={<FaUsers className="text-red-500" />}
              color="#ffe5e5"
              value={approvedUsers.length}
              title={t("totalMembers")}
              description={t("descriptionMembers")}
            />
            <StatCard
              icon={<FaChurch className="text-orange-500" />}
              color="#fff5e5"
              value={ministriesCount}
              title={t("totalMinistries")}
              description={t("descriptionMinistries")}
            />
            <StatCard
              icon={<FaCalendarAlt className="text-green-500" />}
              color="#e5ffe5"
              value={eventsCount}
              title={t("totalEvents")}
              description={t("descriptionEvents")}
            />
            <StatCard
              icon={<FaMusic className="text-purple-500" />}
              color="#f5e5ff"
              value={songsCount}
              title={t("totalSongs")}
              description={t("descriptionSongs")}
            />
          </div>
          <Graphs />
          {error && <p className="text-red-500">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
