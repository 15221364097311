import React from "react";
import { FaTimes } from "react-icons/fa";

interface VideoModalProps {
  onClose: () => void;
  children: React.ReactNode;
}

const VideoModal: React.FC<VideoModalProps> = ({ onClose, children }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative bg-white rounded-2xl p-4 w-full mx-4">
        <button
          onClick={onClose}
          className="absolute -top-5 -right-4 bg-gray-100  rounded-full p-2 text-gray-700 hover:text-gray-900"
        >
          <FaTimes size={24} />
        </button>
        {children}
      </div>
    </div>
  );
};

export default VideoModal;
