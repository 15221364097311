import React, { useState } from "react";
import Modal from "react-modal";

const LandingPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [orgName, setOrgName] = useState("");
  const [address, setAddress] = useState("");
  const [contactInfo, setContactInfo] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [membersCount, setMembersCount] = useState(""); // New state for Members Count
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEmail("");
    setOrgName("");
    setAddress("");
    setContactInfo("");
    setPhoneNumber("");
    setMembersCount(""); // Reset Members Count
    setError("");
    setSuccessMessage("");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (
      !email ||
      !orgName ||
      !address ||
      !contactInfo ||
      !phoneNumber ||
      !membersCount
    ) {
      setError("All fields are required");
      return;
    }

    try {
      const response = await fetch("/.netlify/functions/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          orgName,
          address,
          contactInfo,
          phoneNumber,
          membersCount, // Include Members Count in the request body
        }),
      });

      if (response.ok) {
        setSuccessMessage(
          "Thank you for your interest! We have received your information."
        );
        setTimeout(closeModal, 3000); // Optionally close the modal after 3 seconds
      } else {
        setError("Failed to send your information. Please try again.");
      }
    } catch (error) {
      console.error("Failed to send data", error);
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Hero Section */}
      <section className="flex-grow bg-gradient-to-r from-blue-500 to-purple-600 text-white py-12">
        <div className="container mx-auto text-center px-4">
          <h2 className="text-4xl font-bold mb-4">Welcome to Praisify</h2>
          <p className="text-lg mb-8">
            Manage your church groups and ministries with ease.
          </p>
          <button
            onClick={openModal}
            className="bg-[#5932EA] bg-opacity-75 text-white px-6 py-3 rounded-full text-lg hover:bg-[#5932EA] transition-colors"
          >
            Join our waiting list
          </button>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-12 bg-gray-100">
        <div className="container mx-auto text-center px-4">
          <h2 className="text-3xl font-bold mb-6">Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="p-6 bg-white shadow-md rounded-2xl">
              <h3 className="text-2xl font-bold mb-4">Group Management</h3>
              <p>
                Easily manage your church groups and ministries. Add, edit, and
                remove groups as needed.
              </p>
            </div>
            <div className="p-6 bg-white shadow-md rounded-2xl">
              <h3 className="text-2xl font-bold mb-4">Leader Assignment</h3>
              <p>
                Assign leaders to your groups and ministries to ensure proper
                management and oversight.
              </p>
            </div>
            <div className="p-6 bg-white shadow-md rounded-2xl">
              <h3 className="text-2xl font-bold mb-4">Event Coordination</h3>
              <p>
                Coordinate events and activities for your groups and ministries
                seamlessly.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-6">
        <div className="container mx-auto text-center px-4">
          <p>&copy; 2024 Praisify. All rights reserved.</p>
        </div>
      </footer>

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="bg-white p-8 rounded-2xl shadow-md w-full max-w-md mx-auto mt-24"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        {!successMessage ? (
          <form onSubmit={handleSubmit} className="space-y-4">
            <h2 className="text-3xl font-bold mb-6 text-center text-gray-700">
              Join Our Waiting List
            </h2>
            <div>
              <label htmlFor="orgName" className="block text-gray-600 mb-2">
                Organization Name
              </label>
              <input
                type="text"
                id="orgName"
                value={orgName}
                onChange={(e) => setOrgName(e.target.value)}
                placeholder="Organization Name"
                required
                className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
              />
            </div>
            <div>
              <label htmlFor="address" className="block text-gray-600 mb-2">
                Address
              </label>
              <input
                type="text"
                id="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Address"
                required
                className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
              />
            </div>
            <div>
              <label htmlFor="contactInfo" className="block text-gray-600 mb-2">
                Contact Name
              </label>
              <input
                type="text"
                id="contactInfo"
                value={contactInfo}
                onChange={(e) => setContactInfo(e.target.value)}
                placeholder="Contact Info"
                required
                className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
              />
            </div>
            <div>
              <label htmlFor="phoneNumber" className="block text-gray-600 mb-2">
                Phone Number
              </label>
              <input
                type="tel"
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Phone Number"
                required
                className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
              />
            </div>
            <div>
              <label
                htmlFor="membersCount"
                className="block text-gray-600 mb-2"
              >
                Members Count
              </label>
              <input
                type="number"
                id="membersCount"
                value={membersCount}
                onChange={(e) => setMembersCount(e.target.value)}
                placeholder="Number of Members"
                required
                className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-gray-600 mb-2">
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
                className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-[#5932EA] bg-opacity-75 text-white py-2 rounded-lg hover:bg-[#5932EA] transition-colors"
            >
              Submit
            </button>
            {error && <p className="mt-4 text-red-500 text-center">{error}</p>}
          </form>
        ) : (
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-4 text-gray-700">
              Thank You!
            </h2>
            <p className="text-lg text-gray-600 mb-4">
              We have received your information and will get back to you soon.
            </p>
            <button
              onClick={closeModal}
              className="bg-[#5932EA] bg-opacity-75 text-white px-4 py-2 rounded-lg hover:bg-[#5932EA] transition-colors"
            >
              Close
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default LandingPage;
