import React from "react";

interface ButtonWithIconProps {
  onClick: () => void;
  text: string;
  icon: React.ReactNode;
  className?: string;
}

const SuccessButton: React.FC<ButtonWithIconProps> = ({
  onClick,
  text,
  icon,
  className = "",
}) => {
  return (
    <button
      onClick={onClick}
      className={`bg-[#2FEA9B] bg-opacity-[38%] border-2 border-[#008767] text-[#008767] px-4 py-2 rounded-2xl hover:bg-[#008767] hover:text-white transition-colors flex items-center ${className}`}
    >
      {icon}
      <span className="ml-2">{text}</span>
    </button>
  );
};

export default SuccessButton;
