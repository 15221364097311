import React, { useState, useEffect } from "react";
import { db } from "../../../db/firebase"; // Adjust the import path if necessary
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  arrayUnion,
  arrayRemove,
  getDoc,
} from "firebase/firestore";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DangerButton from "../Components/DangerButton";
import SuccessButton from "../Components/SuccessButton";
import { FaPlus, FaTrash } from "react-icons/fa";

interface AddLeaderModalProps {
  ministryId: string;
  organizationId: string;
  onClose: () => void;
}

const AddLeaderModal: React.FC<AddLeaderModalProps> = ({
  ministryId,
  organizationId,
  onClose,
}) => {
  const [users, setUsers] = useState<any[]>([]);
  const [leaders, setLeaders] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      const q = query(
        collection(db, "users"),
        where("organizationId", "==", organizationId),
        where("role", "==", "admin")
      );
      const querySnapshot = await getDocs(q);
      const usersData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersData);
      setLoading(false);
    };

    const fetchMinistryLeaders = async () => {
      const ministryDocRef = doc(db, "ministries", ministryId);
      const ministryDoc = await getDoc(ministryDocRef);
      if (ministryDoc.exists()) {
        setLeaders(ministryDoc.data().leaders || []);
      }
    };

    fetchUsers();
    fetchMinistryLeaders();
  }, [organizationId, ministryId]);

  const handleAddLeader = async (userId: string) => {
    const ministryDocRef = doc(db, "ministries", ministryId);

    // Update ministry document to add the leader
    await updateDoc(ministryDocRef, {
      leaders: arrayUnion(userId),
    });

    setLeaders((prevLeaders) => [...prevLeaders, userId]);
  };

  const handleRemoveLeader = async (userId: string) => {
    const ministryDocRef = doc(db, "ministries", ministryId);

    // Update ministry document to remove the leader
    await updateDoc(ministryDocRef, {
      leaders: arrayRemove(userId),
    });

    setLeaders((prevLeaders) =>
      prevLeaders.filter((leader) => leader !== userId)
    );
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
      <div className="bg-white p-6 rounded-2xl shadow-lg w-full max-w-md">
        <h2 className="text-xl font-bold mb-4 text-center">
          Add/Remove Leader
        </h2>
        {loading ? (
          <div className="text-center">
            <Skeleton count={5} />
          </div>
        ) : (
          <ul className="space-y-2">
            {users.map((user) => (
              <li
                key={user.id}
                className="flex justify-between items-center border-b pb-2"
              >
                <span className="text-gray-800">
                  {user.firstName} {user.lastName}
                </span>
                {leaders.includes(user.id) ? (
                  <DangerButton
                    onClick={() => handleRemoveLeader(user.id)}
                    text="Remove"
                    icon={<FaTrash />}
                    className="custom-class" // Optional: add additional custom class names if needed
                  />
                ) : (
                  <SuccessButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddLeader(user.id);
                    }}
                    text="Add"
                    icon={<FaPlus />}
                    className="custom-class" // Optional: add additional custom class names if needed
                  />
                )}
              </li>
            ))}
          </ul>
        )}
        <button
          onClick={onClose}
          className="mt-4 bg-gray-500 text-white px-4 py-2 rounded-2xl w-full hover:bg-gray-600 transition-colors"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default AddLeaderModal;
