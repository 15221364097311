import React, { useState } from "react";
import Sidebar from "./Sidebar"; // Adjust the import path if necessary

const DashboardLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex flex-col md:flex-row h-screen">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="flex-1 md:ml-64">
        <div className="md:hidden bg-white fixed w-full flex items-center justify-between p-4 shadow-md z-50">
          <h1 className="text-xl font-bold">Dashboard</h1>
          <button onClick={toggleSidebar} className="text-2xl">
            ☰
          </button>
        </div>
        <div
          className={`mt-16 md:mt-0 p-6 w-full ${
            isSidebarOpen ? "opacity-50 md:opacity-100" : ""
          }`}
          onClick={() => isSidebarOpen && toggleSidebar()}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
