import React from "react";
import { ref, deleteObject } from "firebase/storage";
import { storage } from "../../../../db/firebase";
import { useTranslation } from "react-i18next";
import Divider from "../../../../components/Divider";

interface Comment {
  id: string;
  userName: string;
  comment: string; // This will now contain HTML
  timestamp: string;
  attachmentUrl?: string;
  attachmentType?: string;
  attachmentName?: string;
  isAudio?: boolean;
}

interface CommentListProps {
  comments: Comment[];
  onDeleteComment: (commentId: string) => void;
}

const CommentList: React.FC<CommentListProps> = ({
  comments,
  onDeleteComment,
}) => {
  const { t } = useTranslation();

  const isYouTubeUrl = (url: string) => {
    const ytRegex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    return ytRegex.test(url);
  };

  const getYouTubeEmbedUrl = (url: string) => {
    const ytRegex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(ytRegex);
    if (match && match[1]) {
      return `https://www.youtube.com/embed/${match[1]}`;
    }
    return "";
  };

  const extractUrls = (html: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return html.match(urlRegex) || [];
  };

  const renderContent = (content: string) => {
    // Remove wrapping <p> tags if present
    const strippedContent = content.trim().replace(/^<p>|<\/p>$/g, "");

    const urls = extractUrls(strippedContent);

    if (urls.length > 0) {
      return urls.map((url, index) => (
        <div key={index}>
          {isYouTubeUrl(url) ? (
            <iframe
              width="100%"
              height="315"
              src={getYouTubeEmbedUrl(url)}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube video"
            ></iframe>
          ) : (
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#5932EA] underline break-all"
            >
              {url}
            </a>
          )}
        </div>
      ));
    } else {
      return (
        <div
          className="formatted-comment"
          dangerouslySetInnerHTML={{ __html: strippedContent }}
        />
      );
    }
  };

  const handleDeleteComment = async (commentId: string) => {
    const commentToDelete = comments.find(
      (comment) => comment.id === commentId
    );
    if (commentToDelete && commentToDelete.attachmentUrl) {
      const attachmentRef = ref(storage, commentToDelete.attachmentUrl);
      try {
        await deleteObject(attachmentRef);
      } catch (error) {
        console.error("Error deleting attachment: ", error);
      }
    }

    onDeleteComment(commentId);
  };

  return (
    <div className="bg-white p-4 rounded-2xl max-h-80 md:max-h-[500px] overflow-y-auto">
      <div>
        {comments.length > 0 ? (
          <ul className="space-y-2">
            {comments.map((comment) => (
              <li key={comment.id} className="p-4">
                <Divider />
                <div className="flex justify-between items-start">
                  <div className="w-full">
                    {renderContent(comment.comment)}
                    {comment.attachmentUrl && (
                      <div className="my-2">
                        {comment.attachmentType === "image" ? (
                          <div>
                            <img
                              src={comment.attachmentUrl}
                              alt="Attachment"
                              className="max-w-full h-auto"
                            />
                            <a
                              href={comment.attachmentUrl}
                              download={comment.attachmentName}
                              className="text-black text-sm font-bold underline"
                            >
                              {t("download")}
                            </a>
                          </div>
                        ) : comment.attachmentType === "audio" ? (
                          <audio controls>
                            <source
                              src={comment.attachmentUrl}
                              type="audio/mpeg"
                            />
                            {t("audioNotSupported")}
                          </audio>
                        ) : (
                          <a
                            href={comment.attachmentUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 underline"
                          >
                            {comment.attachmentName || comment.attachmentUrl}
                          </a>
                        )}
                      </div>
                    )}
                    <div className="mt-2">
                      <strong>{comment.userName}:</strong>
                      <em>{new Date(comment.timestamp).toLocaleString()}</em>
                      <button
                        onClick={() => handleDeleteComment(comment.id)}
                        className="text-red-500 text-sm ml-4 underline"
                      >
                        {t("delete")}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-600">{t("noNotesYet")}</p>
        )}
      </div>
    </div>
  );
};

export default CommentList;
