import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { db, storage, auth } from "../../../db/firebase";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import { FaSpinner } from "react-icons/fa";

const CommunicationsPage: React.FC = () => {
  const [user] = useAuthState(auth);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [message, setMessage] = useState("");
  const [mms, setMms] = useState(false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [organizationId, setOrganizationId] = useState<string | null>(null);

  useEffect(() => {
    const fetchOrganizationId = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setOrganizationId(userData.organizationId);
        }
      }
    };

    fetchOrganizationId();
  }, [user]);

  useEffect(() => {
    const fetchGroupsAndMinistries = async () => {
      if (organizationId) {
        try {
          const groupCollection = collection(db, "groups");
          const ministryCollection = collection(db, "ministries");

          const groupSnapshot = await getDocs(
            query(
              groupCollection,
              where("organizationId", "==", organizationId)
            )
          );
          const ministrySnapshot = await getDocs(
            query(
              ministryCollection,
              where("organizationId", "==", organizationId)
            )
          );

          const groupsData = groupSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          const ministriesData = ministrySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          console.log("Groups: ", groupsData);
          console.log("Ministries: ", ministriesData);

          setGroups([
            ...groupsData,
            ...ministriesData,
            { id: "all-users", name: "All Users" },
          ]);
        } catch (error) {
          console.error("Error fetching groups and ministries: ", error);
        }
      }
    };

    fetchGroupsAndMinistries();
  }, [organizationId]);

  const fetchPhoneNumbers = async (
    groupOrMinistryId: string,
    isAllUsers: boolean
  ) => {
    const phoneNumbers: string[] = [];
    const userList: any[] = [];

    try {
      if (isAllUsers) {
        const usersCollection = collection(db, "users");
        const usersSnapshot = await getDocs(
          query(usersCollection, where("organizationId", "==", organizationId))
        );

        usersSnapshot.forEach((doc) => {
          const userData = doc.data();
          if (userData.phoneNumber) {
            phoneNumbers.push(userData.phoneNumber);
            userList.push(userData);
          }
        });
      } else {
        const groupOrMinistryDoc = await getDoc(
          doc(
            db,
            groupOrMinistryId.startsWith("group") ? "groups" : "ministries",
            groupOrMinistryId
          )
        );
        if (groupOrMinistryDoc.exists()) {
          const data = groupOrMinistryDoc.data();
          const userIds = [...(data.members || []), ...(data.leaders || [])];

          for (const userId of userIds) {
            const userDoc = await getDoc(doc(db, "users", userId));
            if (userDoc.exists()) {
              const userData = userDoc.data();
              if (userData.phoneNumber) {
                phoneNumbers.push(userData.phoneNumber);
                userList.push(userData);
              }
            }
          }
        } else {
          console.log("Group or Ministry document not found");
        }
      }
    } catch (error) {
      console.error("Error fetching phone numbers: ", error);
    }

    return phoneNumbers;
  };

  const handleGroupChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedGroupId = e.target.value;
    setSelectedGroup(selectedGroupId);

    if (selectedGroupId) {
      const phoneNumbers = await fetchPhoneNumbers(
        selectedGroupId,
        selectedGroupId === "all-users"
      );
      console.log("Phone Numbers for Selected Group: ", phoneNumbers);
    }
  };

  const isUnicode = (str: string) => {
    for (let i = 0; i < str.length; i++) {
      if (str.charCodeAt(i) > 127) {
        return true;
      }
    }
    return false;
  };

  const getCharacterLimit = () => {
    return isUnicode(message) ? 70 : 160;
  };

  const uploadImage = async (): Promise<string> => {
    if (!imageFile) return "";
    const storageRef = ref(storage, `images/${Date.now()}_${imageFile.name}`);
    await uploadBytes(storageRef, imageFile);
    const imageUrl = await getDownloadURL(storageRef);
    return imageUrl;
  };

  const sendSMS = async () => {
    if (!selectedGroup) {
      setError("Please select a valid group or ministry");
      return;
    }

    if (!message.trim()) {
      setError("Message cannot be empty");
      return;
    }

    if (message.length > getCharacterLimit()) {
      setError(`Message exceeds character limit of ${getCharacterLimit()}`);
      return;
    }

    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const phoneNumbers = await fetchPhoneNumbers(
        selectedGroup,
        selectedGroup === "all-users"
      );

      if (phoneNumbers.length === 0) {
        setError("No phone numbers found for the selected group or ministry");
        setLoading(false);
        return;
      }

      let imageUrl = "";
      if (mms && imageFile) {
        imageUrl = await uploadImage();
      }

      console.log("Sending SMS to: ", phoneNumbers);

      const response = await axios.post(
        "/.netlify/functions/send-sms",
        {
          phoneNumbers,
          message,
          imageUrl: imageUrl || undefined,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setSuccess(`Message sent with SID: ${response.data.sids.join(", ")}`);
      setError("");

      // Delete image from Firebase Storage after sending the message
      if (imageUrl) {
        const imageRef = ref(
          storage,
          `images/${Date.now()}_${imageFile?.name}`
        );
        await deleteObject(imageRef);
        console.log("Image deleted from Firebase Storage");
      }

      // Clear the form
      setSelectedGroup("");
      setMessage("");
      setMms(false);
      setImageFile(null);
    } catch (error) {
      setError("Failed to send SMS");
      console.error("Error sending SMS: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-2xl font-bold mb-6 text-center">Send SMS</h1>
      <div className="mb-6">
        <label className="block text-sm font-semibold mb-2">
          Select Group or Ministry
        </label>
        <select
          value={selectedGroup}
          onChange={handleGroupChange}
          className="w-full p-2 border rounded-lg"
        >
          <option value="">Select a group or ministry</option>
          {groups.map((group) => (
            <option key={group.id} value={group.id}>
              {group.name}
            </option>
          ))}
          <option value="all-users">All Users</option>
        </select>
      </div>
      <div className="mb-6">
        <label className="block text-sm font-semibold mb-2">Message</label>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="w-full p-2 border rounded-lg"
          maxLength={getCharacterLimit()}
        ></textarea>
        <div className="text-sm text-gray-500 mt-1">
          {message.length} / {getCharacterLimit()} characters
        </div>
      </div>
      <div className="mb-6">
        <label className="block text-sm font-semibold mb-2">Send as MMS</label>
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={mms}
            onChange={(e) => setMms(e.target.checked)}
            className="mr-2"
          />
          <span className="text-sm">Check to send as MMS</span>
        </div>
        {mms && (
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImageFile(e.target.files?.[0] || null)}
            className="mt-4"
          />
        )}
      </div>
      {error && (
        <p className="text-red-500 mb-4">Unable to send. Try again later 😮</p>
      )}
      {success && <p className="text-green-500 mb-4">Message Sent 😃</p>}
      <button
        onClick={sendSMS}
        className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg flex items-center justify-center"
        disabled={loading}
      >
        {loading && <FaSpinner className="mr-2 animate-spin" />}
        Send SMS
      </button>
    </div>
  );
};

export default CommunicationsPage;
