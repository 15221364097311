import React from "react";
import Modal from "./Modal"; // Ensure the path is correct
import CommentInput from "../Events/Components/CommentInput";

interface CommentInputModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddComment: (comment: any) => void;
  maxFileSizeMB?: number;
}

const CommentInputModal: React.FC<CommentInputModalProps> = ({
  isOpen,
  onClose,
  onAddComment,
  maxFileSizeMB,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="px-4 bg-white h-[95%] rounded-2xl">
        <h3 className="text-xl font-semibold mb-4">Add a Note</h3>
        <CommentInput
          onAddComment={onAddComment}
          maxFileSizeMB={maxFileSizeMB}
        />
      </div>
    </Modal>
  );
};

export default CommentInputModal;
