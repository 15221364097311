import React, { useEffect, useState } from "react";
import { Chart, CategoryScale } from "chart.js/auto";
import { Bar, Line } from "react-chartjs-2";
import {
  collection,
  getDocs,
  query,
  where,
  getDoc,
  doc,
} from "firebase/firestore"; // Add doc import
import { db, auth } from "../../../db/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

Chart.register(CategoryScale);

const Graphs: React.FC = () => {
  const [user] = useAuthState(auth);
  const [eventsCount, setEventsCount] = useState(0);
  const [ministriesCount, setMinistriesCount] = useState(0);
  const [songsCount, setSongsCount] = useState(0);
  const [groupsCount, setGroupsCount] = useState(0);
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchUserRoleAndOrganization = async () => {
      if (user) {
        try {
          // Fetch user document
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            if (userData.role === "admin") {
              setIsAdmin(true);
              setOrganizationId(userData.organizationId);
            }
          }

          // Check if the user is an organization admin
          const orgDoc = await getDoc(doc(db, "organizations", user.uid));
          if (orgDoc.exists()) {
            setOrganizationId(orgDoc.id);
          }
        } catch (err) {
          console.error("Failed to fetch user role or organization:", err);
        }
      }
    };

    fetchUserRoleAndOrganization();
  }, [user]);

  useEffect(() => {
    const fetchCounts = async () => {
      if (user && organizationId) {
        try {
          const organizationAdminId = organizationId || user.uid;

          const ministriesSnapshot = await getDocs(
            query(
              collection(db, "ministries"),
              where("organizationId", "==", organizationAdminId)
            )
          );
          setMinistriesCount(ministriesSnapshot.size);

          const eventsSnapshot = await getDocs(
            query(
              collection(db, "events"),
              where("organizationId", "==", organizationAdminId)
            )
          );
          setEventsCount(eventsSnapshot.size);

          const songsSnapshot = await getDocs(
            query(
              collection(db, "songs"),
              where("organizationId", "==", organizationAdminId)
            )
          );
          setSongsCount(songsSnapshot.size);

          const groupsSnapshot = await getDocs(
            query(
              collection(db, "groups"),
              where("organizationId", "==", organizationAdminId)
            )
          );
          setGroupsCount(groupsSnapshot.size);
        } catch (err) {
          console.error("Failed to fetch counts:", err);
        }
      }
    };

    fetchCounts();
  }, [user, organizationId, isAdmin]);

  const barData = {
    labels: ["Events", "Ministries", "Songs", "Groups"],
    datasets: [
      {
        label: "Counts",
        data: [eventsCount, ministriesCount, songsCount, groupsCount],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
      },
    ],
  };

  const lineData = {
    labels: ["Events", "Ministries", "Songs", "Groups"],
    datasets: [
      {
        label: "Counts",
        data: [eventsCount, ministriesCount, songsCount, groupsCount],
        fill: false,
        borderColor: "#36A2EB",
      },
    ],
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div className="bg-white p-6 ">
        <h2 className="text-2xl font-bold mb-4 text-gray-700">Events Chart</h2>
        <Bar data={barData} />
      </div>
      <div className="bg-white p-6">
        <h2 className="text-2xl font-bold mb-4 text-gray-700">Line Chart</h2>
        <Line data={lineData} />
      </div>
    </div>
  );
};

export default Graphs;
