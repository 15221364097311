import React, { useState, useEffect } from "react";
import { db, auth } from "../../../db/firebase";
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { FaPlus, FaTrash } from "react-icons/fa";
import MinistryModal from "./MinistryModal";
import { Link } from "react-router-dom";
import Card from "../Components/Card";
import DangerButton from "../Components/DangerButton";
import AddButtonWithIcon from "../Components/AddButton";
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal";
import { useTranslation } from "react-i18next";

const Ministries: React.FC = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const [ministries, setMinistries] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [ministryToDelete, setMinistryToDelete] = useState<any | null>(null);

  useEffect(() => {
    const fetchUserOrganization = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const orgId = userDoc.data().organizationId;
            setOrganizationId(orgId || user.uid);
          } else {
            const orgDoc = await getDoc(doc(db, "organizations", user.uid));
            if (orgDoc.exists()) {
              setOrganizationId(orgDoc.id);
            }
          }
        } catch (error) {
          console.error(t("errorFetchingOrganizationId"), error);
        }
      }
    };

    fetchUserOrganization();
  }, [t]);

  useEffect(() => {
    const fetchMinistries = async () => {
      if (organizationId) {
        try {
          const ministriesSnapshot = await getDocs(
            collection(db, "ministries")
          );
          const ministriesData = ministriesSnapshot.docs
            .map((doc) => ({ id: doc.id, ...doc.data() }))
            .filter((ministry) => ministry.organizationId === organizationId);
          setMinistries(ministriesData);
        } catch (error) {
          console.error(t("errorFetchingMinistries"), error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchMinistries();
  }, [organizationId, t]);

  const handleCreate = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleSave = async (ministry: any) => {
    if (organizationId) {
      try {
        const newMinistryDocRef = await addDoc(collection(db, "ministries"), {
          ...ministry,
          organizationId,
        });
        setMinistries([
          ...ministries,
          { id: newMinistryDocRef.id, ...ministry, organizationId },
        ]);
      } catch (error) {
        console.error(t("errorSavingMinistry"), error);
      } finally {
        handleModalClose();
      }
    }
  };

  const confirmDeleteMinistry = (ministry: any) => {
    setMinistryToDelete(ministry);
    setIsDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    if (!ministryToDelete) return;

    try {
      await deleteDoc(doc(db, "ministries", ministryToDelete.id));
      setMinistries(
        ministries.filter((ministry) => ministry.id !== ministryToDelete.id)
      );
    } catch (error) {
      console.error(t("errorDeletingMinistry"), error);
    } finally {
      setIsDeleteModalOpen(false);
      setMinistryToDelete(null);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4 sm:p-6 lg:p-8">
        <div className="bg-white p-8 rounded-2xl shadow-md w-full max-w-md text-center">
          <p className="text-gray-700">{t("loading")}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto">
      <h1 className="text-3xl font-light mb-4 text-gray-700">
        {t("ministries")}
      </h1>
      <AddButtonWithIcon
        onClick={handleCreate}
        text={t("addMinistry")}
        icon={<FaPlus />}
        className="custom-class mb-4"
      />
      {ministries.length === 0 ? (
        <p className="text-gray-600">{t("noMinistriesAvailable")}</p>
      ) : (
        <ul className="space-y-4">
          {ministries.map((ministry) => (
            <Card
              key={ministry.id}
              className="bg-gray-100 p-4 rounded-2xl flex flex-col md:flex-row justify-between items-start md:items-center cursor-pointer"
            >
              <Link
                to={`/organization/ministries/${ministry.id}`}
                className="flex flex-col flex-grow mb-4 md:mb-0"
              >
                <h2 className="text-xl font-bold text-gray-700">
                  {ministry.name}
                </h2>
                <p className="text-gray-600 mt-2">{ministry.description}</p>
              </Link>
              <div className="flex space-x-2">
                <DangerButton
                  onClick={() => confirmDeleteMinistry(ministry)}
                  text={t("delete")}
                  icon={<FaTrash />}
                  className="custom-class"
                />
              </div>
            </Card>
          ))}
        </ul>
      )}
      {showModal && (
        <MinistryModal
          ministry={null}
          onSave={handleSave}
          onClose={handleModalClose}
        />
      )}
      {isDeleteModalOpen && (
        <ConfirmDeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={handleDelete}
          userName={ministryToDelete ? ministryToDelete.name : ""}
        />
      )}
    </div>
  );
};

export default Ministries;
