import React from "react";

interface SaveButtonProps {
  onClick?: () => void;
  isSubmitting: boolean;
  text: string;
  className?: string;
}

const SaveButton: React.FC<SaveButtonProps> = ({
  onClick,
  isSubmitting,
  text,
  className = "",
}) => {
  return (
    <button
      type="submit"
      onClick={onClick}
      className={`w-full bg-[#5932EA] bg-opacity-75 text-white py-2 rounded-2xl hover:bg-[#5932EA] transition-colors ${className}`}
      disabled={isSubmitting} // Disable the button while submitting
    >
      {text}
    </button>
  );
};

export default SaveButton;
