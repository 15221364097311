import React from "react";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook

interface SongDetailModalProps {
  song: any;
  onClose: () => void;
  placeholderImage: string;
}

const SongDetailModal: React.FC<SongDetailModalProps> = ({
  song,
  onClose,
  placeholderImage,
}) => {
  const { t } = useTranslation(); // Initialize translation

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
      <div className="bg-white p-6 rounded-2xl shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4">{song.title}</h2>
        <div className="bg-white p-6 rounded-2xl shadow-md">
          <p className="text-gray-700 mb-2">
            <strong>{t("artist")}:</strong> {song.artist}
          </p>
          <p className="text-gray-700 mb-2">
            <strong>{t("album")}:</strong> {song.album}
          </p>
          {song.artworkURL ? (
            <img
              src={song.artworkURL}
              alt={t("artwork")}
              className="w-full h-auto rounded-md mb-4"
            />
          ) : (
            <img
              src={placeholderImage}
              alt={t("placeholder")}
              className="w-full h-auto rounded-md mb-4"
            />
          )}
          <p className="text-gray-700 mb-2">
            <strong>{t("lyrics")}:</strong>
          </p>
          <pre className="text-gray-700 whitespace-pre-wrap mb-4">
            {song.lyrics}
          </pre>
          {song.youtubeLink && (
            <div className="mb-4">
              <iframe
                width="100%"
                height="315"
                src={`https://www.youtube.com/embed/${new URL(
                  song.youtubeLink
                ).searchParams.get("v")}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
        <button
          onClick={onClose}
          className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 transition-colors"
        >
          {t("close")}
        </button>
      </div>
    </div>
  );
};

export default SongDetailModal;
