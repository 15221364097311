import React, { useState, useEffect } from "react";

interface StatCardProps {
  icon: React.ReactNode;
  color: string;
  title: string;
  value: number; // Change type to number for animation purposes
  description: string;
}

const StatCard: React.FC<StatCardProps> = ({
  icon,
  color,
  title,
  value,
  description,
}) => {
  const [displayValue, setDisplayValue] = useState(0);

  //   const displayValue = 200;
  useEffect(() => {
    let start = 0;
    const end = value;
    const duration = 30; // Duration of the animation in milliseconds
    const incrementTime = 10; // Time in milliseconds between each increment
    const step = (end - start) / (duration / incrementTime);

    const timer = setInterval(() => {
      start += step;
      if (start >= end) {
        clearInterval(timer);
        start = end;
      }
      setDisplayValue(Math.floor(start));
    }, incrementTime);

    return () => clearInterval(timer);
  }, [value]);

  return (
    <div
      className={`p-4 rounded-2xl shadow-md flex flex-col items-center`}
      style={{ backgroundColor: color }}
    >
      <div className="mb-2">{icon}</div>
      <div className="text-3xl font-bold text-gray-800 mb-1">
        {displayValue}
      </div>
      <div className="text-lg text-gray-600 mb-1">{title}</div>
      <div className="text-sm text-blue-500">{description}</div>
    </div>
  );
};

export default StatCard;
