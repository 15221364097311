import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  setDoc,
  collection,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { db, storage, auth } from "../../../db/firebase";
import { v4 as uuidv4 } from "uuid";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook

interface Comment {
  id: string;
  userName: string;
  comment: string;
  timestamp: string;
  attachmentUrl?: string;
  attachmentType?: string;
  attachmentName?: string;
}

const MAX_FILE_SIZE_MB = 5;

const ViewSong: React.FC = () => {
  const { t } = useTranslation(); // Initialize translation
  const { id } = useParams<{ id: string }>();
  const [song, setSong] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const [newComment, setNewComment] = useState<string>("");
  const [comments, setComments] = useState<Comment[]>([]);
  const [attachment, setAttachment] = useState<File | null>(null);
  const [attachmentError, setAttachmentError] = useState<string>("");

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const fetchSong = async () => {
      if (id) {
        const songDoc = await getDoc(doc(db, "songs", id));
        if (songDoc.exists()) {
          const songData = songDoc.data();
          setSong(songData);

          const user = auth.currentUser;
          if (user) {
            const userDoc = await getDoc(doc(db, "users", user.uid));
            if (userDoc.exists()) {
              const orgId = userDoc.data().organizationId;
              const commentsRef = collection(
                db,
                "organizations",
                orgId,
                "songs",
                id,
                "comments"
              );
              const commentsSnapshot = await getDocs(commentsRef);
              const commentsData = commentsSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              })) as Comment[];
              setComments(commentsData);
            }
          }
        }
        setLoading(false);
      }
    };

    fetchSong();
  }, [id]);

  const handleAddComment = async () => {
    if (newComment.trim() !== "" || attachment) {
      let attachmentUrl = "";
      let attachmentType = "";
      let attachmentName = "";

      if (attachment) {
        if (attachment.size / (1024 * 1024) > MAX_FILE_SIZE_MB) {
          setAttachmentError(
            t("fileSizeError", { maxFileSize: MAX_FILE_SIZE_MB })
          );
          return;
        }

        const storageRef = ref(storage, `attachments/${uuidv4()}`);
        await uploadBytes(storageRef, attachment);
        attachmentUrl = await getDownloadURL(storageRef);
        attachmentType = attachment.type.split("/")[0];
        attachmentName = attachment.name;
        setAttachment(null);
        setAttachmentError("");
      }

      const user = auth.currentUser;
      let userName = t("anonymousUser");
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          userName = `${userDoc.data().firstName} ${userDoc.data().lastName}`;
        }
      }

      const newCommentObject: Comment = {
        id: uuidv4(),
        userName,
        comment: newComment,
        timestamp: new Date().toISOString(),
        attachmentUrl,
        attachmentType,
        attachmentName,
      };
      const updatedComments = [newCommentObject, ...comments];
      setComments(updatedComments);
      setNewComment("");

      if (id && user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const orgId = userDoc.data().organizationId;
          const commentsRef = collection(
            db,
            "organizations",
            orgId,
            "songs",
            id,
            "comments"
          );
          await setDoc(doc(commentsRef, newCommentObject.id), newCommentObject);
        }
      }

      // Reset file input
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleDeleteComment = async (commentId: string) => {
    const commentToDelete = comments.find(
      (comment) => comment.id === commentId
    );
    if (commentToDelete && commentToDelete.attachmentUrl) {
      const attachmentRef = ref(storage, commentToDelete.attachmentUrl);
      try {
        await deleteObject(attachmentRef);
      } catch (error) {
        console.error("Error deleting attachment: ", error);
      }
    }

    const updatedComments = comments.filter(
      (comment) => comment.id !== commentId
    );
    setComments(updatedComments);

    if (id) {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const orgId = userDoc.data().organizationId;
          const commentsRef = collection(
            db,
            "organizations",
            orgId,
            "songs",
            id,
            "comments"
          );
          await deleteDoc(doc(commentsRef, commentId));
        }
      }
    }
  };

  const isYouTubeUrl = (url: string) => {
    const ytRegex = /^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
    return ytRegex.test(url);
  };

  const getYouTubeEmbedUrl = (url: string) => {
    const urlObj = new URL(url);
    if (urlObj.hostname === "www.youtube.com" && urlObj.pathname === "/watch") {
      return `https://www.youtube.com/embed/${urlObj.searchParams.get("v")}`;
    } else if (urlObj.hostname === "youtu.be") {
      return `https://www.youtube.com/embed/${urlObj.pathname.slice(1)}`;
    }
    return "";
  };

  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4 sm:p-6 lg:p-8">
        <div className="bg-white p-8 rounded-2xl shadow-md w-full max-w-md text-center">
          <p className="text-gray-700">{t("loading")}...</p>
        </div>
      </div>
    );
  }

  if (!song) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4 sm:p-6 lg:p-8">
        <div className="bg-white p-8 rounded-2xl shadow-md w-full max-w-md text-center">
          <p className="text-gray-700">{t("songNotFound")}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 mb-4 md:mb-0">
          <div className="bg-white flex mb-4 rounded-2xl p-4">
            <div>
              {song.artworkURL ? (
                <img
                  src={song.artworkURL}
                  alt="Artwork"
                  className="rounded-md w-32 h-32"
                />
              ) : (
                <img
                  src={placeholderImage}
                  alt={t("placeholder")}
                  className="w-32 h-32 rounded-md"
                />
              )}
            </div>
            <div className="ml-4">
              <h1 className="text-2xl font-bold mb-2 text-gray-700">
                {song.title}
              </h1>
              <p className="text-gray-700 mb-2">
                <strong>{t("artist")}:</strong> {song.artist}
              </p>
              <p className="text-gray-700 mb-2">
                <strong>{t("album")}:</strong> {song.album}
              </p>
              {song.charts && (
                <p className="text-gray-700 mb-2">
                  <strong>{t("charts")}:</strong>{" "}
                  <a
                    href={song.charts}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#5932EA]"
                  >
                    {t("viewCharts")}
                  </a>
                </p>
              )}
            </div>
          </div>
          <div className="bg-white p-6 rounded-2xl h-80 md:h-[620px] overflow-y-auto">
            <p className="text-gray-700 mb-2">
              <strong>{t("lyrics")}:</strong>
            </p>
            <pre className="text-gray-700 whitespace-pre-wrap mb-4">
              {song.lyrics}
            </pre>
          </div>
        </div>
        <div className="w-full md:w-1/2 md:pl-4">
          <div className="bg-white p-4 rounded-2xl mb-4">
            {song.youtubeLink && (
              <div className="mb-4">
                <iframe
                  width="100%"
                  height="315"
                  src={`https://www.youtube.com/embed/${new URL(
                    song.youtubeLink
                  ).searchParams.get("v")}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            )}
          </div>
          <div className="bg-white p-4 rounded-2xl mb-4">
            <input
              type="text"
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder={t("newNotes")}
              className="w-full px-4 py-2 border rounded-2xl text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA] mb-2"
              maxLength={400}
            />
            <input
              type="file"
              ref={fileInputRef}
              accept="image/*, audio/*, application/pdf, application/msword, .docx, .ppt, .pptx"
              onChange={(e) => setAttachment(e.target.files?.[0] || null)}
              className="mt-2"
            />
            {attachmentError && (
              <p className="text-red-500 text-sm mt-2">{attachmentError}</p>
            )}
            <button
              onClick={handleAddComment}
              className="bg-[#5932EA] bg-opacity-75 text-white hover:bg-[#5932EA] transition-colors mt-2 p-2 rounded-2xl w-full"
            >
              {t("addNotes")}
            </button>
          </div>
          <div className="bg-white p-4 rounded-2xl max-h-80 md:max-h-[500px] overflow-y-auto">
            <h3 className="text-lg font-bold mb-2">{t("notes")}</h3>
            <div>
              {comments.length > 0 ? (
                <ul className="space-y-2">
                  {comments.map((comment) => (
                    <li
                      key={comment.id}
                      className="bg-gray-100 p-4 rounded-2xl shadow-md"
                    >
                      <div className="flex justify-between items-start">
                        <div className="w-full">
                          {isYouTubeUrl(comment.comment) ? (
                            <iframe
                              width="100%"
                              height="315"
                              src={getYouTubeEmbedUrl(comment.comment)}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              title="YouTube video"
                            ></iframe>
                          ) : isValidUrl(comment.comment) ? (
                            <a
                              href={comment.comment}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-[#5932EA] underline break-all"
                            >
                              {comment.comment}
                            </a>
                          ) : (
                            <p>{comment.comment}</p>
                          )}
                          {comment.attachmentUrl && (
                            <div className="my-2">
                              {comment.attachmentType === "image" ? (
                                <div>
                                  <img
                                    src={comment.attachmentUrl}
                                    alt={t("attachment")}
                                    className="max-w-full h-auto"
                                  />
                                  <a
                                    href={comment.attachmentUrl}
                                    download={comment.attachmentName}
                                    className="text-black text-sm font-bold underline"
                                  >
                                    {t("download")}
                                  </a>
                                </div>
                              ) : (
                                <a
                                  href={comment.attachmentUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-blue-500 underline"
                                >
                                  {comment.attachmentName ||
                                    comment.attachmentUrl}
                                </a>
                              )}
                            </div>
                          )}
                          <div className="mt-2">
                            <strong>{comment.userName}:</strong>
                            <em>
                              {new Date(comment.timestamp).toLocaleString()}
                            </em>
                            <button
                              onClick={() => handleDeleteComment(comment.id)}
                              className="text-red-500 text-sm ml-4 underline"
                            >
                              {t("delete")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-600">{t("noNotesYet")}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSong;
