import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  deleteDoc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { db, auth } from "../../../db/firebase";
import { useNavigate } from "react-router-dom";
import AddSongToEventModal from "./AddSongToEventModal";
import AddSongModal from "./AddSongModal";
import {
  FaPlus,
  FaEdit,
  FaEye,
  FaTrashAlt,
  FaStar,
  FaStarHalfAlt,
} from "react-icons/fa";
import Card from "../Components/Card";
import AddButtonWithIcon from "../Components/AddButton";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useTranslation } from "react-i18next";

const placeholderImage = "/imgs/placeholder-614.png";

const Songs: React.FC = () => {
  const { t } = useTranslation();
  const [songs, setSongs] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSong, setSelectedSong] = useState<any | null>(null);
  const [showAddSongModal, setShowAddSongModal] = useState(false);
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserOrganization = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const orgId = userDoc.data().organizationId;
            if (orgId) {
              setOrganizationId(orgId);
              fetchFavoriteSongs(orgId);
            } else {
              console.error("Organization ID not found in user document.");
            }
          } else {
            console.error("User document not found.");
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
        }
      } else {
        console.error("No authenticated user found.");
      }
    };

    fetchUserOrganization();
  }, []);

  useEffect(() => {
    if (organizationId && searchQuery.trim() === "") {
      fetchFavoriteSongs(organizationId);
    }
  }, [organizationId, searchQuery]);

  const fetchFavoriteSongs = async (orgId: string) => {
    setLoading(true);
    try {
      const favSongsRef = collection(
        db,
        "organizations",
        orgId,
        "favorite_songs"
      );
      const favSongsSnapshot = await getDocs(favSongsRef);
      const favSongIds = favSongsSnapshot.docs.map((doc) => doc.id);

      if (favSongIds.length === 0) {
        setSongs([]);
        return;
      }

      const favSongsQuery = query(
        collection(db, "songs"),
        where("__name__", "in", favSongIds)
      );
      const favSongsSnapshotAll = await getDocs(favSongsQuery);
      const favSongs = favSongsSnapshotAll.docs.map((doc) => ({
        id: doc.id,
        favorite: true,
        ...doc.data(),
      }));

      setSongs(favSongs);
    } catch (error) {
      console.error("Error fetching favorite songs: ", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllSongs = async () => {
    setLoading(true);
    try {
      const allSongsSnapshot = await getDocs(collection(db, "songs"));
      const allSongs = allSongsSnapshot.docs.map((doc) => ({
        id: doc.id,
        favorite: false,
        ...doc.data(),
      }));
      setSongs(allSongs);
    } catch (error) {
      console.error("Error fetching all songs: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    const queryText = searchQuery.trim().toLowerCase();

    if (queryText === "") {
      fetchAllSongs();
      return;
    }

    try {
      const allSongsSnapshot = await getDocs(collection(db, "songs"));
      const allSongs = allSongsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const filteredSongs = allSongs.filter((song) => {
        const songTitle = song.title.toLowerCase();
        const songArtist = song.artist.toLowerCase();
        return songTitle.includes(queryText) || songArtist.includes(queryText);
      });

      if (organizationId) {
        const favSongsRef = collection(
          db,
          "organizations",
          organizationId,
          "favorite_songs"
        );
        const favSongsSnapshot = await getDocs(favSongsRef);
        const favSongIds = favSongsSnapshot.docs.map((doc) => doc.id);

        const songsWithFavorites = filteredSongs.map((song) => ({
          ...song,
          favorite: favSongIds.includes(song.id),
        }));

        setSongs(songsWithFavorites);
      } else {
        setSongs(filteredSongs);
      }
    } catch (error) {
      console.error("Error searching songs: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    const confirmed = window.confirm(t("confirmDeleteSong"));
    if (confirmed) {
      try {
        await deleteDoc(doc(db, "songs", id));
        setSongs(songs.filter((song) => song.id !== id));
      } catch (error) {
        console.error("Error deleting song: ", error);
      }
    }
  };

  const handleToggleFavorite = async (id: string, currentFavorite: boolean) => {
    if (!organizationId) {
      console.error("Organization ID is not set.");
      alert(t("organizationNotFound"));
      return;
    }

    console.log(
      "Toggling favorite status for organization ID:",
      organizationId
    ); // Debugging log

    try {
      const favoriteSongDocRef = doc(
        db,
        "organizations",
        organizationId,
        "favorite_songs",
        id
      );

      if (currentFavorite) {
        // Remove the song from the favorite_songs subcollection
        await deleteDoc(favoriteSongDocRef);
        alert(t("removedFromFavorites"));
      } else {
        // Add the song ID to the favorite_songs subcollection
        await setDoc(favoriteSongDocRef, { songId: id });
        alert(t("addedToFavorites"));
      }

      // Update the local state to reflect the change
      setSongs(
        songs.map((song) =>
          song.id === id ? { ...song, favorite: !currentFavorite } : song
        )
      );
    } catch (error) {
      console.error("Error toggling favorite status:", error);
      alert(t("errorTogglingFavorite"));
    }
  };

  return (
    <div className="container mx-auto">
      <h1 className="text-2xl font-light mb-4 text-gray-700">{t("songs")}</h1>
      <AddButtonWithIcon
        onClick={() => setShowAddSongModal(true)}
        text={t("addSong")}
        icon={<FaPlus />}
        className="custom-class"
      />

      <div className="mb-6 flex  md:flex-row justify-between items-center">
        <input
          type="text"
          placeholder={t("searchByNameOrArtist")}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-[78%] md:w-full md:w-auto px-4 py-2 border rounded-2xl text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA] "
          maxLength={150}
        />
        <button
          onClick={handleSearch}
          className=" w-[20%] md:mt-0 md:ml-2 px-4 py-2 bg-[#5932EA] bg-opacity-75 text-white rounded-2xl hover:bg-[#5932EA] transition-colors"
        >
          {loading ? (
            <svg
              className="animate-spin h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
              ></path>
            </svg>
          ) : (
            t("search")
          )}
        </button>
      </div>
      {loading ? (
        <ul className="space-y-4">
          {Array.from({ length: 5 }).map((_, index) => (
            <li key={index}>
              <Card>
                <Skeleton height={80} />
              </Card>
            </li>
          ))}
        </ul>
      ) : songs.length > 0 ? (
        <ul className="space-y-4">
          {songs.map((song) => (
            <Card key={song.id} className="mb-4">
              <div className="bg-white rounded-2xl flex flex-col md:flex-row justify-between md:items-start">
                <div className="flex items-center">
                  {song.artworkURL ? (
                    <img
                      src={song.artworkURL}
                      alt={song.title}
                      className="w-20 h-20 rounded-md mr-4"
                    />
                  ) : (
                    <img
                      src={placeholderImage}
                      alt="Placeholder"
                      className="w-20 h-20 rounded-md mr-4"
                    />
                  )}
                  <div>
                    <h3 className="text-lg font-bold text-gray-700">
                      {song.title}
                    </h3>
                    <p className="text-gray-500">
                      <strong>{t("artist")}:</strong> {song.artist}
                    </p>
                    <p className="text-gray-500">
                      <strong>{t("album")}:</strong> {song.album}
                    </p>
                  </div>
                </div>
                <div className="flex space-x-2 mt-4 md:mt-0">
                  <button
                    onClick={() => setSelectedSong(song)}
                    className="px-4 py-2 text-2xl text-[#00B087] hover:text-green-600 rounded-md flex items-center"
                  >
                    <FaPlus className="mr-2" />
                  </button>
                  <button
                    onClick={() =>
                      navigate(`/organization/songs/${song.id}/edit`)
                    }
                    className="px-4 py-2 text-gray-400 hover:text-[#5932EA] text-2xl rounded-md transition-colors flex items-center"
                  >
                    <FaEdit className="" />
                  </button>
                  <button
                    onClick={() =>
                      navigate(`/organization/songs/${song.id}/view`)
                    }
                    className="px-4 py-2 text-gray-400 hover:text-[#5932EA] text-2xl rounded-md transition-colors flex items-center"
                  >
                    <FaEye className="" />
                  </button>
                  <button
                    onClick={() => handleDelete(song.id)}
                    className="px-4 py-2 text-gray-400 hover:text-[#5932EA] text-2xl rounded-md transition-colors flex items-center"
                  >
                    <FaTrashAlt className="" />
                  </button>
                  <button
                    onClick={() => handleToggleFavorite(song.id, song.favorite)}
                    className="px-4 py-2 text-2xl text-gray-400 hover:text-[#5932EA] rounded-md transition-colors flex items-center"
                  >
                    {song.favorite ? (
                      <>
                        <FaStar className="" />
                      </>
                    ) : (
                      <>
                        <FaStarHalfAlt className="" />
                      </>
                    )}
                  </button>
                </div>
              </div>
            </Card>
          ))}
        </ul>
      ) : (
        <p>{t("noSongsFound")}</p>
      )}
      {selectedSong && (
        <AddSongToEventModal
          song={selectedSong}
          onClose={() => setSelectedSong(null)}
        />
      )}
      {showAddSongModal && (
        <AddSongModal onClose={() => setShowAddSongModal(false)} />
      )}
    </div>
  );
};

export default Songs;
