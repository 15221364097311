import React from "react";
import ReactDOM from "react-dom/client"; // Use ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import App from "./App";
import Nav from "./components/Nav";
import "../src/index.css";
import "./i18n";

const Root = () => {
  const location = useLocation();
  const showNav = !location.pathname.startsWith("/organization");

  return (
    <>
      {showNav && <Nav />}
      <App />
    </>
  );
};

const container = document.getElementById("root");

if (container) {
  const root = ReactDOM.createRoot(container); // Create the root using createRoot
  root.render(
    <React.StrictMode>
      <Router>
        <Root />
      </Router>
    </React.StrictMode>
  );
}
