import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
// import { BsThreeDotsVertical } from "react-icons/bs";

interface DropdownMenuProps {
  onSetCurrentPlaylist: () => void;
  onInviteToEvent: () => void;
  onSearchAddSongs: () => void;
  isCurrentPlaylist: boolean;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  onSetCurrentPlaylist,
  onInviteToEvent,
  onSearchAddSongs,
  isCurrentPlaylist,
}) => {
  const { t } = useTranslation(); // Initialize translation
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div>
        {/* <BsThreeDotsVertical
          onClick={toggleDropdown}
          className="text-gray-400"
          size={20}
        /> */}

        <button
          onClick={toggleDropdown}
          className="inline-flex justify-center w-full rounded-2xl border bg-[#5932EA] bg-opacity-75 text-white shadow-sm px-4 py-2 text-sm font-medium hover:bg-[#5932EA] transition-colors focus:outline-none"
        >
          {t("actions")}
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
          <div className="py-1">
            <button
              onClick={onSetCurrentPlaylist}
              className="block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-[#5932EA] hover:bg-opacity-10"
            >
              {isCurrentPlaylist
                ? t("currentPlaylist")
                : t("setCurrentPlaylist")}
            </button>
            <button
              onClick={onInviteToEvent}
              className="block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-[#5932EA] hover:bg-opacity-10"
            >
              {t("inviteToEvent")}
            </button>
            <button
              onClick={onSearchAddSongs}
              className="block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-[#5932EA] hover:bg-opacity-10"
            >
              {t("searchAddSongs")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
