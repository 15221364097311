import React, { useState, useEffect } from "react";
import { db, auth } from "../../../db/firebase";
import {
  collection,
  getDocs,
  query,
  where,
  serverTimestamp,
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

interface EventModalProps {
  event: any | null;
  onSave: (event: any) => void;
  onClose: () => void;
}

const EventModal: React.FC<EventModalProps> = ({ event, onSave, onClose }) => {
  const { t } = useTranslation(); // Initialize translation
  const [title, setTitle] = useState(event?.title || "");
  const [description, setDescription] = useState(event?.description || "");
  const [date, setDate] = useState(event?.date || "");
  const [time, setTime] = useState(event?.time || "");
  const [type, setType] = useState(event?.type || "");
  const [groupOrMinistry, setGroupOrMinistry] = useState(
    event?.groupOrMinistry || ""
  );
  const [ministriesAndGroups, setMinistriesAndGroups] = useState<any[]>([]);
  const [isRecurring, setIsRecurring] = useState(event?.isRecurring || false);
  const [recurrencePattern, setRecurrencePattern] = useState(
    event?.recurrencePattern || ""
  );
  const [user] = useAuthState(auth);

  useEffect(() => {
    const fetchMinistriesAndGroups = async () => {
      if (!user) return;

      try {
        const userDoc = await getDocs(collection(db, "users"));
        const userOrganizationId = userDoc.docs
          .find((doc) => doc.id === user.uid)
          ?.data().organizationId;

        if (!userOrganizationId) {
          console.error("Organization ID not found for the user.");
          return;
        }

        const ministriesQuery = query(
          collection(db, "ministries"),
          where("organizationId", "==", userOrganizationId)
        );
        const groupsQuery = query(
          collection(db, "groups"),
          where("organizationId", "==", userOrganizationId)
        );

        const [ministriesSnapshot, groupsSnapshot] = await Promise.all([
          getDocs(ministriesQuery),
          getDocs(groupsQuery),
        ]);

        const ministries = ministriesSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
        }));
        const groups = groupsSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
        }));

        setMinistriesAndGroups([...ministries, ...groups]);
      } catch (error) {
        console.error("Error fetching ministries and groups: ", error);
      }
    };

    fetchMinistriesAndGroups();
  }, [user]);

  const handleSave = () => {
    onSave({
      title,
      description,
      date,
      time,
      type,
      groupOrMinistry,
      isRecurring,
      recurrencePattern,
      timestamp: serverTimestamp(),
      userId: user?.uid,
    });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white p-6 rounded-2xl shadow-lg w-full max-w-md">
        <h2 className="text-xl font-bold mb-4">
          {event ? t("editEvent") : t("addEvent")}
        </h2>
        <div className="mb-4">
          <label className="block text-gray-700">{t("title")}</label>
          <input
            maxLength={150}
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">{t("description")}</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">{t("date")}</label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">{t("time")}</label>
          <input
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">{t("typeOfEvent")}</label>
          <select
            value={type}
            onChange={(e) => setType(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          >
            <option value="">{t("selectType")}</option>
            <option value="Service">{t("service")}</option>
            <option value="Worship">{t("worship")}</option>
            <option value="Planning">{t("planning")}</option>
            <option value="Global">{t("global")}</option>
            <option value="Other">{t("other")}</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">{t("groupOrMinistry")}</label>
          <select
            value={groupOrMinistry}
            onChange={(e) => setGroupOrMinistry(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          >
            <option value="">{t("selectGroupOrMinistry")}</option>
            {ministriesAndGroups.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">{t("recurringEvent")}</label>
          <input
            type="checkbox"
            checked={isRecurring}
            onChange={(e) => setIsRecurring(e.target.checked)}
            className="mr-2"
          />
          <label className="text-gray-700">{t("isRecurring")}</label>
        </div>
        {isRecurring && (
          <div className="mb-4">
            <label className="block text-gray-700">
              {t("recurrencePattern")}
            </label>
            <select
              value={recurrencePattern}
              onChange={(e) => setRecurrencePattern(e.target.value)}
              className="w-full px-3 py-2 border rounded"
            >
              <option value="">{t("selectRecurrencePattern")}</option>
              <option value="Daily">{t("daily")}</option>
              <option value="Weekly">{t("weekly")}</option>
              <option value="Monthly">{t("monthly")}</option>
            </select>
          </div>
        )}
        <div className="flex flex-col">
          <button
            onClick={handleSave}
            className="bg-[#5932EA]  bg-opacity-75 text-white px-4 py-2 rounded-2xl hover:bg-[#5932EA] transition-colors  mb-4"
          >
            {t("save")}
          </button>
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded-2xl"
          >
            {t("close")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventModal;
