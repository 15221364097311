import React from "react";

interface DangerButtonWithIconProps {
  onClick: () => void;
  text: string;
  icon: React.ReactNode;
  className?: string;
}

const DangerButton: React.FC<DangerButtonWithIconProps> = ({
  onClick,
  text,
  icon,
  className = "",
}) => {
  return (
    <button
      onClick={onClick}
      className={`bg-[#FFC5C5] text-[#DF0404] border-2 border-[#DF0404] px-4 py-2 rounded-2xl hover:bg-[#DF0404] hover:text-white transition-colors flex items-center ${className}`}
    >
      {icon}
      <span className="ml-2">{text}</span>
    </button>
  );
};

export default DangerButton;
