import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useTranslation } from "react-i18next";
import Divider from "../../../components/Divider";
import AddLeaderModal from "./AddLeaderModal";
import AddMemberModal from "./AddMemberModal";
import InviteToEventModal from "./InviteToEventModal";
import SearchAddSongModal from "../Ministry/SearchAddSongModal";
import SongDetailModal from "../Songs/SongDetailModal";
import EventHeader from "./Components/EventHeader";
import PeopleList from "./Components/PeopleList";
import SongList from "./Components/SongList";
import CommentSection from "./Components/CommentSection";
import DropdownMenu from "./Components/DropdownMenu";
import ServiceEventDetail from "../Components/ServiceEventDetail";
import { doc, updateDoc } from "firebase/firestore";
import { fetchUserOrganization, fetchEvent } from "./utils/fetchFunctions";
import { handleRemoveSong, handleSetPlaylist } from "./utils/handleFunctions";
import { db } from "../../../db/firebase";

const placeholderImage = "path/to/placeholder/image.jpg";

const EventDetail: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [event, setEvent] = useState<any | null>(null);
  const [leaders, setLeaders] = useState<any[]>([]);
  const [members, setMembers] = useState<any[]>([]);
  const [songs, setSongs] = useState<any[]>([]);
  const [leaderColors, setLeaderColors] = useState<Record<string, string>>({});
  const [memberColors, setMemberColors] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(true);
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const [settingPlaylist, setSettingPlaylist] = useState(false);
  const [isCurrentPlaylist, setIsCurrentPlaylist] = useState(false);
  const [showLeaderModal, setShowLeaderModal] = useState(false);
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showAddSongModal, setShowAddSongModal] = useState(false);
  const [selectedSong, setSelectedSong] = useState<any | null>(null);

  useEffect(() => {
    fetchUserOrganization(setOrganizationId);
  }, []);

  useEffect(() => {
    const unsubscribe = fetchEvent(
      id,
      organizationId,
      setEvent,
      setLeaders,
      setLeaderColors,
      setMembers,
      setMemberColors,
      setSongs,
      setIsCurrentPlaylist,
      setLoading
    );
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [id, organizationId]);

  const handleAddLeader = () => setShowLeaderModal(true);
  const handleAddMember = () => setShowMemberModal(true);
  const handleModalClose = () => {
    setShowLeaderModal(false);
    setShowMemberModal(false);
    setShowInviteModal(false);
    setShowAddSongModal(false);
    setSelectedSong(null);
  };

  const handleSearchAddSongs = () => setShowAddSongModal(true);

  const handleMoveSong = (dragIndex: number, hoverIndex: number) => {
    const updatedSongs = [...songs];
    const [draggedSong] = updatedSongs.splice(dragIndex, 1);
    updatedSongs.splice(hoverIndex, 0, draggedSong);
    setSongs(updatedSongs);

    // Optionally, update the songs in the backend (Firebase, etc.)
    const eventDocRef = doc(db, "events", id!);
    updateDoc(eventDocRef, {
      songs: updatedSongs.map((song) => song.id),
    });
  };

  return (
    <div className="-m-4 md:m-2">
      {loading ? (
        <SkeletonWrapper />
      ) : event ? (
        event.type === "Service" ? (
          <ServiceEventDetail
            event={event}
            eventId={id!}
            organizationId={organizationId!}
          />
        ) : (
          <div className="p-1 rounded-xl ">
            <div className="flex justify-between bg-white rounded-2xl p-4">
              <EventHeader
                title={event.title}
                description={event.description}
                date={event.date}
                time={event.time}
              />
              <DropdownMenu
                onSetCurrentPlaylist={() =>
                  handleSetPlaylist(
                    organizationId,
                    songs,
                    id!,
                    setSettingPlaylist,
                    setIsCurrentPlaylist
                  )
                }
                onInviteToEvent={() => setShowInviteModal(true)}
                onSearchAddSongs={handleSearchAddSongs}
                isCurrentPlaylist={isCurrentPlaylist}
              />
            </div>

            <div className=" flex flex-col sm:flex-col md:flex-col lg:flex-row justify-between items-center">
              <PeopleList
                title={t("leaders")}
                people={leaders}
                colors={leaderColors}
                onAdd={handleAddLeader}
              />
              <PeopleList
                title={t("members")}
                people={members}
                colors={memberColors}
                onAdd={handleAddMember}
              />
            </div>

            {event.type === "Worship" && (
              <SongList
                songs={songs}
                placeholderImage={placeholderImage}
                handleMoveSong={handleMoveSong} // Pass the function here
                handleRemoveSong={(songId) =>
                  handleRemoveSong(songId, id!, setSongs)
                }
                settingPlaylist={settingPlaylist}
                isCurrentPlaylist={isCurrentPlaylist}
                handleSetPlaylist={() =>
                  handleSetPlaylist(
                    organizationId,
                    songs,
                    id!,
                    setSettingPlaylist,
                    setIsCurrentPlaylist
                  )
                }
              />
            )}

            <Divider />
            <CommentSection
              eventId={id!}
              initialComments={event?.comments || []}
            />
          </div>
        )
      ) : (
        <p className="text-gray-700">{t("eventNotExistOrNoPermission")}</p>
      )}
      {showLeaderModal && (
        <AddLeaderModal
          eventId={id!}
          organizationId={event.organizationId}
          onClose={handleModalClose}
        />
      )}
      {showMemberModal && (
        <AddMemberModal
          eventId={id!}
          organizationId={event.organizationId}
          onClose={handleModalClose}
        />
      )}
      {showInviteModal && (
        <InviteToEventModal
          eventId={id!}
          organizationId={event.organizationId}
          leaders={leaders.map((leader) => leader.id)}
          members={members.map((member) => member.id)}
          onClose={handleModalClose}
        />
      )}
      {showAddSongModal && (
        <SearchAddSongModal
          eventId={id!}
          currentSongs={songs}
          onClose={() => setShowAddSongModal(false)}
          onAddSong={(song) => {
            const updatedSongs = [...songs, song];
            setSongs(updatedSongs);

            const eventDocRef = doc(db, "events", id!);
            updateDoc(eventDocRef, {
              songs: updatedSongs.map((song) => song.id),
            });
          }}
        />
      )}
      {selectedSong && (
        <SongDetailModal
          song={selectedSong}
          onClose={() => setSelectedSong(null)}
          placeholderImage={placeholderImage}
        />
      )}
    </div>
  );
};

const SkeletonWrapper: React.FC = () => (
  <div>
    <Skeleton height={40} width={300} />
    <Skeleton count={3} />
    <Divider />
    <Skeleton height={30} width={200} />
    <Skeleton count={5} />
  </div>
);

export default EventDetail;
