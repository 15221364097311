import React from "react";

const Modal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
  children,
}) => {
  if (!isOpen) return null;

  const handleCloseClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    // Close the modal when the user clicks outside the content
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-75 flex justify-center items-center z-50"
      onClick={handleCloseClick} // Handle outside click
    >
      <div className="relative bg-white rounded-2xl shadow-lg w-full max-w-3xl h-[90%] overflow-y-auto py-8">
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800  bg-slate-100 rounded-full px-3 py-1.5 hover:bg-slate-400"
          onClick={onClose}
        >
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
