import React from "react";
import { useTranslation } from "react-i18next";

const WaitingApproval: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4 sm:p-6 lg:p-8">
      <div className="bg-white p-8 rounded-2xl shadow-md w-full max-w-md text-center">
        <h2 className="text-2xl font-bold mb-6 text-gray-700">
          {t("waitingForApproval")}
        </h2>
        <p className="text-gray-600">{t("registrationPendingApproval")}</p>
      </div>
    </div>
  );
};

export default WaitingApproval;
