import React from "react";

interface AddButtonWithIconProps {
  onClick: () => void;
  text: string;
  icon: React.ReactNode;
  className?: string;
}

const AddButtonWithIcon: React.FC<AddButtonWithIconProps> = ({
  onClick,
  text,
  icon,
  className = "",
}) => {
  return (
    <button
      onClick={onClick}
      className={`bg-[#5932EA] bg-opacity-75 text-white py-2 px-4 rounded-2xl hover:bg-[#5932EA] transition-colors flex items-center mb-4 ${className}`}
    >
      {icon}
      <span className="ml-2">{text}</span>
    </button>
  );
};

export default AddButtonWithIcon;
