import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { db, storage } from "../../../db/firebase";
import { useTranslation } from "react-i18next"; // Import useTranslation

const EditSong: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation(); // Initialize translation
  const [song, setSong] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [artist, setArtist] = useState("");
  const [album, setAlbum] = useState("");
  const [lyrics, setLyrics] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [artwork, setArtwork] = useState<File | null>(null);
  const [artworkURL, setArtworkURL] = useState("");
  const [charts, setCharts] = useState("");
  const [uploading, setUploading] = useState(false);
  const [importError, setImportError] = useState(""); // Keep this if you plan to use it
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSong = async () => {
      if (id) {
        const songDoc = await getDoc(doc(db, "songs", id));
        if (songDoc.exists()) {
          const songData = songDoc.data();
          setSong(songData);
          setTitle(songData.title || "");
          setArtist(songData.artist || "");
          setAlbum(songData.album || "");
          setLyrics(songData.lyrics || "");
          setYoutubeLink(songData.youtubeLink || "");
          setArtworkURL(songData.artworkURL || "");
          setCharts(songData.charts || "");
        }
      }
      setLoading(false);
    };

    fetchSong();
  }, [id]);

  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    if (title.trim() === "" || artist.trim() === "") {
      setImportError(t("import_error"));
      return;
    }

    if (id && song) {
      setUploading(true);
      let artworkURLToUpdate = artworkURL;

      if (artwork) {
        const storageRef = ref(storage, `artworks/${artwork.name}`);
        const uploadTask = uploadBytesResumable(storageRef, artwork);

        await new Promise<void>((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
            },
            (error) => {
              console.error("Error uploading artwork: ", error);
              setUploading(false);
              reject(error);
            },
            async () => {
              artworkURLToUpdate = await getDownloadURL(
                uploadTask.snapshot.ref
              );
              resolve();
            }
          );
        });
      }

      const songDocRef = doc(db, "songs", id);
      await updateDoc(songDocRef, {
        title,
        artist,
        album,
        lyrics,
        youtubeLink,
        artworkURL: artworkURLToUpdate,
        charts,
      });

      setUploading(false);
      navigate("/organization/songs");
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4 sm:p-6 lg:p-8">
        <div className="bg-white p-8 rounded-2xl shadow-md w-full max-w-md text-center">
          <p className="text-gray-700">{t("loading")}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6 text-gray-700">
        {t("edit_song")}
      </h1>
      <form onSubmit={handleUpdate} className="space-y-4">
        <div>
          <label htmlFor="title" className="block text-gray-600 mb-2">
            {t("title")}
          </label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="w-full px-4 py-2 border rounded-2xl text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            maxLength={100}
          />
        </div>
        <div>
          <label htmlFor="artist" className="block text-gray-600 mb-2">
            {t("artist")}
          </label>
          <input
            type="text"
            id="artist"
            value={artist}
            onChange={(e) => setArtist(e.target.value)}
            required
            className="w-full px-4 py-2 border rounded-2xl text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            maxLength={100}
          />
        </div>
        <div>
          <label htmlFor="album" className="block text-gray-600 mb-2">
            {t("album")}
          </label>
          <input
            type="text"
            id="album"
            value={album}
            onChange={(e) => setAlbum(e.target.value)}
            className="w-full px-4 py-2 border rounded-2xl text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            maxLength={100}
          />
        </div>
        <div>
          <label htmlFor="lyrics" className="block text-gray-600 mb-2">
            {t("lyrics")}
          </label>
          <textarea
            id="lyrics"
            value={lyrics}
            onChange={(e) => setLyrics(e.target.value)}
            className="w-full px-4 py-2 border rounded-2xl text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
          />
        </div>
        <div>
          <label htmlFor="youtubeLink" className="block text-gray-600 mb-2">
            {t("youtube_link")}
          </label>
          <input
            type="url"
            id="youtubeLink"
            value={youtubeLink}
            onChange={(e) => setYoutubeLink(e.target.value)}
            className="w-full px-4 py-2 border rounded-2xl text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            maxLength={1000}
          />
        </div>
        <div>
          <label htmlFor="charts" className="block text-gray-600 mb-2">
            {t("charts_link")}
          </label>
          <input
            type="url"
            id="charts"
            value={charts}
            onChange={(e) => setCharts(e.target.value)}
            className="w-full px-4 py-2 border rounded-2xl text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            maxLength={1000}
          />
        </div>
        <div>
          <label htmlFor="artwork" className="block text-gray-600 mb-2">
            {t("artwork")}
          </label>
          <input
            type="file"
            id="artwork"
            onChange={(e) => setArtwork(e.target.files?.[0] || null)}
            className="w-full px-4 py-2 border rounded-2xl text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
          />
          {artworkURL && (
            <div className="mt-4">
              <img
                src={artworkURL}
                alt={t("artwork")}
                className="h-auto rounded-2xl w-[120px] h-[120px]"
              />
            </div>
          )}
        </div>
        <div className="flex justify-end space-x-2">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-2xl hover:bg-blue-600 transition-colors"
          >
            {uploading ? t("updating") : t("update_song")}
          </button>
        </div>
        {importError && <p className="text-red-500 mt-2">{importError}</p>}
      </form>
    </div>
  );
};

export default EditSong;
