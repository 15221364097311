import React, { useState, useEffect, useRef } from "react";
import { db, auth } from "../../src/db/firebase";
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import Modal from "../components/CardModal";
import AddMemberModal from "../components/AddMemberModal";
import ConfirmationModal from "../components/ConfirmationModal";
import { useAuthState } from "react-firebase-hooks/auth";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoIosAddCircle } from "react-icons/io";
import { FaCheck } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

interface CardProps {
  boardId: string;
  listId: string;
}

interface Comment {
  userName: string;
  comment: string;
  timestamp: string;
}

interface User {
  id: string;
  firstName: string;
  lastName: string;
}

interface Card {
  id: string;
  name: string;
  description: string;
  comments: Comment[];
  assignedMembers: string[];
  order: number;
}

const ItemTypes = {
  CARD: "card",
};

const CardComponent: React.FC<CardProps> = ({ boardId, listId }) => {
  const { t } = useTranslation();
  const [cards, setCards] = useState<Card[]>([]);
  const [newCardName, setNewCardName] = useState<string>("");
  const [selectedCard, setSelectedCard] = useState<Card | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [comments, setComments] = useState<Comment[]>([]);
  const [assignedMembers, setAssignedMembers] = useState<string[]>([]);
  const [user] = useAuthState(auth);
  const [userName, setUserName] = useState("");
  const [userDetails, setUserDetails] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState<string | null>(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [cardToDelete, setCardToDelete] = useState<Card | null>(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [editingCardId, setEditingCardId] = useState<string | null>(null);
  const [editedCardName, setEditedCardName] = useState<string>("");
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const cardsCollection = collection(
          db,
          "boards",
          boardId,
          "lists",
          listId,
          "cards"
        );
        const querySnapshot = await getDocs(cardsCollection);
        const cardsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
          description: doc.data().description || "",
          comments: doc.data().comments || [],
          assignedMembers: doc.data().assignedMembers || [],
          order: doc.data().order || 0,
        }));
        setCards(cardsData.sort((a, b) => a.order - b.order));
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching cards: ", error);
        setIsLoading(false);
      }
    };

    fetchCards();
  }, [boardId, listId]);

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const usersSnapshot = await getDocs(collection(db, "users"));
        const usersData = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
        }));
        setAllUsers(usersData);
      } catch (error) {
        console.error("Error fetching users: ", error);
      }
    };

    fetchAllUsers();
  }, []);

  useEffect(() => {
    if (user) {
      const fetchUserDetails = async () => {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserName(`${userData.firstName} ${userData.lastName}`);
          setUserDetails({
            id: user.uid,
            firstName: userData.firstName,
            lastName: userData.lastName,
          });
        }
      };
      fetchUserDetails();
    }
  }, [user]);

  const handleAddCard = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newCardName.trim() === "") {
      console.error("Card name cannot be empty");
      return;
    }
    try {
      const docRef = await addDoc(
        collection(db, "boards", boardId, "lists", listId, "cards"),
        {
          name: newCardName,
          description: "",
          comments: [],
          assignedMembers: [],
          order: cards.length,
        }
      );
      setCards([
        ...cards,
        {
          id: docRef.id,
          name: newCardName,
          description: "",
          comments: [],
          assignedMembers: [],
          order: cards.length,
        },
      ]);
      setNewCardName("");
      setIsFormVisible(false);
    } catch (error) {
      console.error("Error adding card: ", error);
    }
  };

  const handleDeleteCard = async (id: string) => {
    try {
      setLoadingDelete(true);
      await deleteDoc(doc(db, "boards", boardId, "lists", listId, "cards", id));
      setCards(cards.filter((card) => card.id !== id));
      setDropdownOpen(null);
      setIsConfirmationModalOpen(false);
    } catch (error) {
      console.error("Error deleting card: ", error);
    } finally {
      setLoadingDelete(false);
    }
  };

  const handleCardClick = (card: Card) => {
    setSelectedCard(card);
    setDescription(card.description);
    setComments(card.comments);
    setAssignedMembers(card.assignedMembers);
    setIsModalOpen(true);
    setDropdownOpen(null);
  };

  const handleModalSubmit = async (
    newDescription: string,
    newComments: string[],
    updatedAssignedMembers: string[]
  ) => {
    if (selectedCard) {
      const cardRef = doc(
        db,
        "boards",
        boardId,
        "lists",
        listId,
        "cards",
        selectedCard.id
      );
      try {
        await updateDoc(cardRef, {
          description: newDescription,
          comments: newComments.map((comment) => ({
            userName: userName,
            comment: comment,
            timestamp: new Date().toISOString(),
          })),
          assignedMembers: updatedAssignedMembers,
        });
        setDescription(newDescription);
        setComments(
          newComments.map((comment) => ({
            userName: userName,
            comment: comment,
            timestamp: new Date().toISOString(),
          }))
        );
        setAssignedMembers(updatedAssignedMembers);
        setIsModalOpen(false);
        setCards((prevCards) =>
          prevCards.map((card) =>
            card.id === selectedCard.id
              ? {
                  ...card,
                  description: newDescription,
                  comments: newComments.map((comment) => ({
                    userName: userName,
                    comment: comment,
                    timestamp: new Date().toISOString(),
                  })),
                  assignedMembers: updatedAssignedMembers,
                }
              : card
          )
        );
      } catch (error) {
        console.error("Error updating card: ", error);
      }
    }
  };

  const handleAddMemberClick = () => {
    setIsAddMemberModalOpen(true);
  };

  const handleMemberChange = (updatedAssignedMembers: string[]) => {
    setAssignedMembers(updatedAssignedMembers);
    if (selectedCard) {
      setCards((prevCards) =>
        prevCards.map((card) =>
          card.id === selectedCard.id
            ? { ...card, assignedMembers: updatedAssignedMembers }
            : card
        )
      );
    }
  };

  const handleDrop = async (draggedCardId: string, hoverIndex: number) => {
    const draggedCard = cards.find((card) => card.id === draggedCardId);
    if (!draggedCard) return;

    const updatedCards = [...cards];
    updatedCards.splice(updatedCards.indexOf(draggedCard), 1);
    updatedCards.splice(hoverIndex, 0, draggedCard);

    const reorderedCards = updatedCards.map((card, index) => ({
      ...card,
      order: index,
    }));

    setCards(reorderedCards);

    try {
      const batch = db.batch();
      reorderedCards.forEach((card) => {
        const cardRef = doc(
          db,
          "boards",
          boardId,
          "lists",
          listId,
          "cards",
          card.id
        );
        batch.update(cardRef, { order: card.order });
      });
      await batch.commit();
      console.log("Card order saved successfully.");
    } catch (error) {
      console.error("Error saving card order: ", error);
    }
  };

  const toggleDropdown = (id: string) => {
    setDropdownOpen(dropdownOpen === id ? null : id);
  };

  const confirmDeleteCard = (card: Card) => {
    setCardToDelete(card);
    setIsConfirmationModalOpen(true);
    setDropdownOpen(null);
  };

  const handleConfirmDelete = () => {
    if (cardToDelete) {
      handleDeleteCard(cardToDelete.id);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(null);
    }
  };

  useEffect(() => {
    if (dropdownOpen !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedCardName(e.target.value);
  };

  const handleNameBlur = async (cardId: string) => {
    if (editedCardName.trim() !== "") {
      try {
        await updateDoc(
          doc(db, "boards", boardId, "lists", listId, "cards", cardId),
          {
            name: editedCardName,
          }
        );
        setCards((prevCards) =>
          prevCards.map((card) =>
            card.id === cardId ? { ...card, name: editedCardName } : card
          )
        );
      } catch (error) {
        console.error("Error updating card name: ", error);
      }
    }
    setEditingCardId(null);
  };

  const handleCardNameUpdate = (cardId: string, newCardName: string) => {
    setCards((prevCards) =>
      prevCards.map((card) =>
        card.id === cardId ? { ...card, name: newCardName } : card
      )
    );
  };

  const DraggableCard: React.FC<{ card: Card; index: number }> = ({
    card,
    index,
  }) => {
    const [, ref] = useDrag({
      type: ItemTypes.CARD,
      item: { id: card.id, index },
    });

    const [, drop] = useDrop({
      accept: ItemTypes.CARD,
      hover(draggedItem: { id: string; index: number }) {
        if (draggedItem.index !== index) {
          handleDrop(draggedItem.id, index);
          draggedItem.index = index;
        }
      },
    });

    return (
      <div
        ref={(node) => ref(drop(node))}
        className="flex flex-col h-[80px] bg-[#5932EA] bg-opacity-5 my-6 rounded-2xl px-4 bg shadow-lg"
      >
        <div key={card.id} className="flex justify-between items-start py-3">
          <div>
            {editingCardId === card.id ? (
              <input
                type="text"
                value={editedCardName}
                onChange={handleNameChange}
                onBlur={() => handleNameBlur(card.id)}
                autoFocus
                className="w-full border border-gray-300 rounded-md p-1"
              />
            ) : (
              <div>
                <span
                  className="cursor-pointer"
                  onClick={() => handleCardClick(card)}
                >
                  {card.name}
                </span>
              </div>
            )}
          </div>
          <div className="relative">
            <HiOutlineDotsHorizontal
              onClick={() => toggleDropdown(card.id)}
              className="text-black mx-4 cursor-pointer"
            />
            {dropdownOpen === card.id && (
              <div
                ref={dropdownRef}
                className="absolute right-0 mt-2 w-48 bg-white border rounded-md shadow-lg z-10"
              >
                <button
                  onClick={() => confirmDeleteCard(card)}
                  className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                >
                  {t("delete")}
                </button>
                <button
                  onClick={() => handleCardClick(card)}
                  className="block w-full text-left px-4 py-2 text-sm text-blue-600 hover:bg-gray-100"
                >
                  {t("edit")}
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center w-full justify-end">
          <div className="flex mr-2">
            {card.assignedMembers.map((memberId) => {
              const member = allUsers.find((user) => user.id === memberId);
              return (
                <div
                  key={memberId}
                  className="bg-blue-500 text-white rounded-full w-8 h-8 flex items-center justify-center ml-2 my-2"
                >
                  {member ? (
                    <span>
                      {member.firstName.charAt(0)}
                      {member.lastName.charAt(0)}
                    </span>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <div>{t("loading")}</div>;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="">
        {cards.map((card, index) => (
          <DraggableCard key={card.id} card={card} index={index} />
        ))}
        <div>
          {!isFormVisible ? (
            <button
              onClick={() => setIsFormVisible(true)}
              className=" text-black p-2 mb-4 flex "
            >
              <IoIosAddCircle className="mr-2 h-[20px] w-[20px]" />
              {t("addCard")}
            </button>
          ) : (
            <>
              <form onSubmit={handleAddCard} className="mb-4 flex w-full ">
                <div className="flex w-full">
                  <div className="flex w-full">
                    <input
                      type="text"
                      value={newCardName}
                      onChange={(e) => setNewCardName(e.target.value)}
                      placeholder={t("newCardName")}
                      className=" w-full px-4 py-2 mr-2border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
                    />
                  </div>
                  <div className="flex justify-end w-[20%]  ">
                    <button>
                      <FaCheck
                        type="submit"
                        className="text-black h-5 w-5 mr-2"
                      />
                    </button>
                    <button onClick={() => setIsFormVisible(false)}>
                      <IoCloseSharp className="font-bold h-6 w-6" />
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
        </div>

        {selectedCard && (
          <>
            <Modal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              onSubmit={handleModalSubmit}
              description={description}
              comments={comments}
              userName={userName}
              cardId={selectedCard.id}
              cardName={selectedCard.name}
              boardId={boardId}
              listId={listId}
              userDetails={userDetails}
              assignedMembers={assignedMembers}
              onAddMemberClick={handleAddMemberClick}
              onCardNameUpdate={handleCardNameUpdate} // Pass the function as a prop
            />
            <AddMemberModal
              isOpen={isAddMemberModalOpen}
              onClose={() => setIsAddMemberModalOpen(false)}
              boardId={boardId}
              listId={listId}
              cardId={selectedCard.id}
              onMemberChange={handleMemberChange}
              assignedMembers={assignedMembers}
            />
          </>
        )}
        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          onClose={() => setIsConfirmationModalOpen(false)}
          onConfirm={handleConfirmDelete}
          message={t("confirmDeleteCard")}
          loading={loadingDelete}
        />
      </div>
    </DndProvider>
  );
};

export default CardComponent;
