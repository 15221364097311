import { serverTimestamp } from "firebase/firestore";
import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../db/firebase"; // Adjust the import path if necessary
import { useTranslation } from "react-i18next";

interface MinistryModalProps {
  ministry: any;
  onSave: (ministry: any) => void;
  onClose: () => void;
}

const MinistryModal: React.FC<MinistryModalProps> = ({
  ministry,
  onSave,
  onClose,
}) => {
  const { t } = useTranslation(); // Initialize translation hook
  const [name, setName] = useState(ministry?.name || "");
  const [description, setDescription] = useState(ministry?.description || "");
  const [user] = useAuthState(auth);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave({
      name,
      description,
      timestamp: serverTimestamp(),
      userId: user?.uid,
    });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-8 rounded-2xl shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6">
          {ministry ? t("editMinistry") : t("addMinistry")}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-gray-600 mb-2">
              {t("name")}
            </label>
            <input
              maxLength={150}
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={t("ministryNamePlaceholder")}
              required
              className="w-full px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <div>
            <label htmlFor="description" className="block text-gray-600 mb-2">
              {t("description")}
            </label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder={t("ministryDescriptionPlaceholder")}
              required
              className="w-full px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <div className="flex flex-col ">
            <button
              type="submit"
              className="bg-[#5932EA] bg-opacity-75 text-white px-4 py-2 rounded-2xl hover:bg-[#5932EA] transition-colors mb-4"
            >
              {t("save")}
            </button>
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 text-white px-4 py-2 rounded-2xl hover:bg-gray-600"
            >
              {t("cancel")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MinistryModal;
