import React, { useState, useEffect } from "react";
import { auth, db } from "../../src/db/firebase"; // Adjust the import path if necessary
import { createUserWithEmailAndPassword } from "firebase/auth";
import {
  setDoc,
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { Link, useNavigate, useSearchParams } from "react-router-dom"; // Import useNavigate and useSearchParams
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useTranslation } from "react-i18next"; // Import useTranslation

const Signup: React.FC = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [sex, setSex] = useState(""); // New state variable for sex
  const [address, setAddress] = useState(""); // New state variable for address
  const [hasPrayer, setHasPrayer] = useState(false); // State for the checkbox
  const [prayer, setPrayer] = useState(""); // State for the prayer text field
  const [error, setError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const invitationCode = searchParams.get("invitationCode")?.trim();

  useEffect(() => {
    const fetchInvitation = async () => {
      if (!invitationCode) {
        setError(t("noInvitationCode"));
        setLoading(false);
        return;
      }

      try {
        const invitationDocRef = doc(db, "invitations", invitationCode);
        const invitationDoc = await getDoc(invitationDocRef);

        if (!invitationDoc.exists()) {
          setError(t("invalidOrExpiredInvitation"));
          setLoading(false);
          return;
        }

        const invitationData = invitationDoc.data();
        if (invitationData.status !== "pending") {
          setError(t("invalidOrExpiredInvitation"));
          setLoading(false);
          return;
        }

        setEmail(invitationData.email);
      } catch (error) {
        setError(t("errorFetchingInvitation"));
      } finally {
        setLoading(false);
      }
    };

    fetchInvitation();
  }, [invitationCode, t]);

  const handleSignup = async (e: React.FormEvent) => {
    e.preventDefault();

    const age = calculateAge(dob);
    if (age < 13) {
      setError(t("ageRequirement"));
      return;
    }

    if (password !== confirmPassword) {
      setError(t("passwordsDoNotMatch"));
      return;
    }

    try {
      setLoading(true);
      const invitationDocRef = doc(db, "invitations", invitationCode);
      const invitationDoc = await getDoc(invitationDocRef);

      if (!invitationDoc.exists()) {
        setError(t("invalidOrExpiredInvitation"));
        setLoading(false);
        return;
      }

      const invitationData = invitationDoc.data();
      if (invitationData.status !== "pending") {
        setError(t("invalidOrExpiredInvitation"));
        setLoading(false);
        return;
      }

      const tempUserCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const tempUser = tempUserCredential.user;

      await setDoc(doc(db, "users", tempUser.uid), {
        firstName,
        lastName,
        dob,
        sex,
        address,
        prayer: hasPrayer ? prayer : "",
        email,
        organizationId: invitationData.organizationId,
        role: "member",
        approved: false,
        timestamp: serverTimestamp(),
      });

      await updateDoc(invitationDocRef, {
        status: "accepted",
      });

      await auth.signOut();

      navigate("/waiting-approval");
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const calculateAge = (dob: string): number => {
    const birthDate = new Date(dob);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      return age - 1;
    }
    return age;
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-500 to-purple-600 p-4 sm:p-6 lg:p-8">
        <div className="bg-white p-8 rounded-2xl shadow-md w-full max-w-md text-center">
          <p className="text-gray-700">{t("loading")}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-500 to-purple-600 p-4 sm:p-6 lg:p-8">
      <div className="bg-white p-8 rounded-2xl shadow-md w-full max-w-md">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-700">
          {t("signUp")}
        </h2>
        <form onSubmit={handleSignup} className="space-y-4">
          <div>
            <label htmlFor="firstName" className="block text-gray-600 mb-2">
              {t("firstName")}
            </label>
            <input
              maxLength={100}
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder={t("firstName")}
              required
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <div>
            <label htmlFor="lastName" className="block text-gray-600 mb-2">
              {t("lastName")}
            </label>
            <input
              maxLength={100}
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder={t("lastName")}
              required
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <div>
            <label htmlFor="dob" className="block text-gray-600 mb-2">
              {t("dob")}
            </label>
            <input
              type="date"
              id="dob"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <div>
            <label htmlFor="sex" className="block text-gray-600 mb-2">
              {t("sex")}
            </label>
            <select
              id="sex"
              value={sex}
              onChange={(e) => setSex(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            >
              <option value="">{t("select")}</option>
              <option value="male">{t("male")}</option>
              <option value="female">{t("female")}</option>
            </select>
          </div>
          <div>
            <label htmlFor="address" className="block text-gray-600 mb-2">
              {t("address")}
            </label>
            <input
              maxLength={500}
              type="text"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder={t("address")}
              required
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-gray-600 mb-2">
              {t("email")}
            </label>
            <input
              type="email"
              id="email"
              value={email}
              readOnly
              className="w-full px-4 py-2 border rounded-lg text-gray-700 bg-gray-100 focus:outline-none"
            />
          </div>
          <div className="relative">
            <label htmlFor="password" className="block text-gray-600 mb-2">
              {t("password")}
            </label>
            <input
              maxLength={100}
              type={passwordVisible ? "text" : "password"}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t("password")}
              required
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
            <button
              type="button"
              onClick={() => setPasswordVisible(!passwordVisible)}
              className="absolute inset-y-0 right-0 px-3 py-2 text-gray-700 mt-6"
            >
              {passwordVisible ? <FaRegEyeSlash /> : <FaRegEye />}
            </button>
          </div>
          <div className="relative">
            <label
              htmlFor="confirmPassword"
              className="block text-gray-600 mb-2"
            >
              {t("confirmPassword")}
            </label>
            <input
              maxLength={100}
              type={confirmPasswordVisible ? "text" : "password"}
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder={t("confirmPassword")}
              required
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
            <button
              type="button"
              onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
              className="absolute inset-y-0 right-0 px-3 mt-6 py-2 text-gray-700"
            >
              {confirmPasswordVisible ? <FaRegEyeSlash /> : <FaRegEye />}
            </button>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="hasPrayer"
              checked={hasPrayer}
              onChange={(e) => setHasPrayer(e.target.checked)}
              className="mr-2"
            />
            <label htmlFor="hasPrayer" className="text-gray-600">
              {t("doYouHaveAPrayer")}
            </label>
          </div>
          {hasPrayer && (
            <div>
              <label htmlFor="prayer" className="block text-gray-600 mb-2">
                {t("prayer")}
              </label>
              <textarea
                id="prayer"
                value={prayer}
                onChange={(e) => setPrayer(e.target.value)}
                placeholder={t("yourPrayer")}
                className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
              />
            </div>
          )}
          <button
            type="submit"
            className="w-full bg-[#5932EA] bg-opacity-75 text-white py-2 rounded-lg hover:bg-[#5932EA] transition-colors"
          >
            {t("signUpButton")}
          </button>
        </form>
        {error && <p className="mt-4 text-red-500 text-center">{error}</p>}
        <p className="mt-6 text-gray-600 text-center">
          {t("alreadyHaveAccount")}{" "}
          <Link to="/login" className="text-[#5932EA] hover:underline">
            {t("login")}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Signup;
