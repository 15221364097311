import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import { v4 as uuidv4 } from "uuid";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage, auth, db } from "../../../../db/firebase";
import { doc, getDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

interface CommentInputProps {
  onAddComment: (comment: any) => void;
  maxFileSizeMB?: number;
}

const CommentInput: React.FC<CommentInputProps> = ({
  onAddComment,
  maxFileSizeMB = 25,
}) => {
  const { t } = useTranslation();
  const [newComment, setNewComment] = useState<string>("");
  const [attachment, setAttachment] = useState<File | null>(null);
  const [attachmentError, setAttachmentError] = useState<string>("");
  const [userName, setUserName] = useState<string>(t("anonymousUser"));

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const fetchUserName = async () => {
      const currentUser = auth.currentUser;

      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          const fullName = `${userData.firstName.trim()} ${userData.lastName.trim()}`;
          setUserName(fullName || t("anonymousUser"));
        }
      }
    };

    fetchUserName();
  }, [t]);

  const handleAddComment = async () => {
    if (newComment.trim() === "" && !attachment) return;

    let attachmentUrl = "";
    let attachmentType = "";
    let attachmentName = "";
    let isAudio = false;

    if (attachment) {
      if (attachment.size / (1024 * 1024) > maxFileSizeMB) {
        setAttachmentError(t("fileSizeError", { maxFileSizeMB }));
        return;
      }

      const storageRef = ref(storage, `attachments/${uuidv4()}`);
      await uploadBytes(storageRef, attachment);
      attachmentUrl = await getDownloadURL(storageRef);
      attachmentType = attachment.type.split("/")[0];
      attachmentName = attachment.name;
      isAudio = attachmentType === "audio";
      setAttachment(null);
      setAttachmentError("");
    }

    const newCommentObject = {
      id: uuidv4(),
      userName,
      comment: newComment,
      timestamp: new Date().toISOString(),
      attachmentUrl,
      attachmentType,
      attachmentName,
      isAudio,
    };

    onAddComment(newCommentObject);
    setNewComment("");

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleFileButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      ["link"],
      ["clean"],
    ],
  };

  return (
    <div className="bg-white p-4 rounded-2xl mb-4 h-full flex flex-col">
      <div className="flex-grow">
        <ReactQuill
          value={newComment}
          onChange={setNewComment}
          modules={modules}
          placeholder={t("newNotesPlaceholder")}
          className="text-gray-700 h-full"
          style={{ height: "300px" }} // Adjust the editor height
        />
      </div>
      <div className="mt-4 flex flex-col items-center">
        <button
          onClick={handleAddComment}
          className="w-full mb-2 bg-[#5932EA] bg-opacity-75 text-white hover:bg-[#5932EA] transition-colors p-2 rounded-2xl self-end"
        >
          {t("addNotesButton")}
        </button>

        <button
          onClick={handleFileButtonClick}
          className="mb-2 px-4 py-2 bg-gray-200 text-gray-700 rounded-2xl hover:bg-gray-300 transition-colors"
        >
          {t("chooseFileButton")}
        </button>
        <input
          type="file"
          ref={fileInputRef}
          accept="image/*, audio/*, application/pdf, application/msword, .docx, .ppt, .pptx"
          onChange={(e) => setAttachment(e.target.files?.[0] || null)}
          className="hidden"
        />
        {attachment && (
          <p className="text-gray-600 mt-2">
            {t("selectedFileLabel", { fileName: attachment.name })}
          </p>
        )}
        {attachmentError && (
          <p className="text-red-500 text-sm mt-2">{attachmentError}</p>
        )}
      </div>
    </div>
  );
};

export default CommentInput;
