import React, { useEffect, useState, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../src/db/firebase";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import InviteUserModal from "../pages/InviteUser";
import UserInfoModal from "../components/UserInfoModal";
import CardComponent from "../pages/Card";
import ConfirmationModal from "../components/ConfirmationModal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader"; // Import ClipLoader for the spinner
import { HiSparkles } from "react-icons/hi2";

const Lists: React.FC = () => {
  const { t } = useTranslation();
  const { boardId } = useParams<{ boardId: string }>();
  const [lists, setLists] = useState<List[]>([]);
  const [boardName, setBoardName] = useState<string>("");
  const [newListName, setNewListName] = useState<string>("");
  const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);
  const [isMembersModalOpen, setIsMembersModalOpen] = useState<boolean>(false);
  const [isUserInfoModalOpen, setIsUserInfoModalOpen] =
    useState<boolean>(false);
  const [invitedUsers, setInvitedUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState<string | null>(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const [listToDelete, setListToDelete] = useState<List | null>(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [expandedLists, setExpandedLists] = useState<Record<string, boolean>>(
    {}
  );
  const [isEditingListName, setIsEditingListName] = useState<string | null>(
    null
  );
  const [tempListName, setTempListName] = useState<string>("");
  const [isEditingBoardName, setIsEditingBoardName] = useState<boolean>(false);
  const [tempBoardName, setTempBoardName] = useState<string>("");
  const [isDevotionalModalOpen, setIsDevotionalModalOpen] = useState(false);
  const [generatedDevotional, setGeneratedDevotional] = useState("");
  const [loadingDevotional, setLoadingDevotional] = useState(false);
  const [selectedListId, setSelectedListId] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const fetchInvitedUsers = useCallback(async () => {
    try {
      if (boardId) {
        const membershipsCollection = collection(db, "memberships");
        const membershipsQuery = query(
          membershipsCollection,
          where("boardId", "==", boardId)
        );
        const membershipsSnapshot = await getDocs(membershipsQuery);
        const userPromises = membershipsSnapshot.docs.map(
          async (membership) => {
            const membershipData = membership.data();
            const userId = membershipData.userId;
            const userDoc = await getDoc(doc(db, "users", userId));
            if (userDoc.exists()) {
              return {
                id: userDoc.id,
                firstName: userDoc.data().firstName,
                lastName: userDoc.data().lastName,
                color: membershipData.color,
              } as User;
            }
            return null;
          }
        );
        const usersData = (await Promise.all(userPromises)).filter(
          (user): user is User => user !== null
        );
        setInvitedUsers(usersData);
      }
    } catch (error) {
      console.error(t("errorFetchingInvitedUsers"), error);
    }
  }, [boardId, t]);

  const fetchBoardDetails = useCallback(async () => {
    try {
      if (boardId) {
        const boardDoc = await getDoc(doc(db, "boards", boardId));
        if (boardDoc.exists()) {
          setBoardName(boardDoc.data().name);
        }

        const listsCollection = collection(db, "boards", boardId, "lists");
        const querySnapshot = await getDocs(listsCollection);
        const listsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
          order: doc.data().order || 0,
        }));
        setLists(listsData.sort((a, b) => a.order - b.order));

        const initialExpandedState: Record<string, boolean> = {};
        listsData.forEach((list) => {
          initialExpandedState[list.id] = false;
        });
        setExpandedLists(initialExpandedState);

        await fetchInvitedUsers();
      }
    } catch (error) {
      console.error(t("errorFetchingBoardDetails"), error);
    } finally {
      setLoading(false);
    }
  }, [boardId, fetchInvitedUsers, t]);

  useEffect(() => {
    fetchBoardDetails();
  }, [fetchBoardDetails]);

  const handleOpenInviteModal = () => {
    setIsInviteModalOpen(true);
  };

  const handleAddList = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newListName.trim() === "" || !boardId) return;
    try {
      const listRef = await addDoc(collection(db, "boards", boardId, "lists"), {
        name: newListName,
        order: lists.length,
      });
      setLists([
        ...lists,
        { id: listRef.id, name: newListName, order: lists.length },
      ]);
      setNewListName("");
    } catch (error) {
      console.error(t("errorAddingList"), error);
    }
  };

  const getInitials = (firstName: string, lastName: string) => {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  };

  const handleDeleteList = async (listId: string) => {
    try {
      if (boardId) {
        setLoadingDelete(true);
        await deleteDoc(doc(db, "boards", boardId, "lists", listId));
        setLists(lists.filter((list) => list.id !== listId));
        setDropdownOpen(null);
        setIsConfirmationModalOpen(false);
      }
    } catch (error) {
      console.error(t("errorDeletingList"), error);
    } finally {
      setLoadingDelete(false);
    }
  };

  const handleOpenMembersModal = () => {
    setIsMembersModalOpen(true);
  };

  const handleOpenUserInfoModal = (user: User) => {
    setSelectedUser(user);
    setIsUserInfoModalOpen(true);
  };

  const handleRemoveUser = async (userId: string) => {
    try {
      if (boardId) {
        const membershipsCollection = collection(db, "memberships");
        const membershipsQuery = query(
          membershipsCollection,
          where("boardId", "==", boardId),
          where("userId", "==", userId)
        );
        const membershipsSnapshot = await getDocs(membershipsQuery);
        const deletePromises = membershipsSnapshot.docs.map((doc) =>
          deleteDoc(doc.ref)
        );
        await Promise.all(deletePromises);
        setInvitedUsers(invitedUsers.filter((user) => user.id !== userId));

        const listsCollection = collection(db, "boards", boardId, "lists");
        const listsSnapshot = await getDocs(listsCollection);
        const listPromises = listsSnapshot.docs.map(async (listDoc) => {
          const cardsCollection = collection(
            db,
            "boards",
            boardId,
            "lists",
            listDoc.id,
            "cards"
          );
          const cardsSnapshot = await getDocs(cardsCollection);
          const cardPromises = cardsSnapshot.docs.map(async (cardDoc) => {
            const cardData = cardDoc.data();
            if (cardData.assignedMembers.includes(userId)) {
              const updatedMembers = cardData.assignedMembers.filter(
                (id: string) => id !== userId
              );
              await updateDoc(
                doc(
                  db,
                  "boards",
                  boardId,
                  "lists",
                  listDoc.id,
                  "cards",
                  cardDoc.id
                ),
                {
                  assignedMembers: updatedMembers,
                }
              );
            }
          });
          await Promise.all(cardPromises);
        });
        await Promise.all(listPromises);
      }
    } catch (error) {
      console.error(t("errorRemovingUser"), error);
    }
  };

  const toggleDropdown = (id: string) => {
    setDropdownOpen(dropdownOpen === id ? null : id);
  };

  const confirmDeleteList = (list: List) => {
    setListToDelete(list);
    setIsConfirmationModalOpen(true);
    setDropdownOpen(null);
  };

  const handleConfirmDelete = () => {
    if (listToDelete) {
      handleDeleteList(listToDelete.id);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(null);
    }
  };

  useEffect(() => {
    if (dropdownOpen !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  const toggleExpandList = (listId: string) => {
    setExpandedLists((prevState) => ({
      ...prevState,
      [listId]: !prevState[listId],
    }));
  };

  const handleListNameClick = (listId: string, listName: string) => {
    setIsEditingListName(listId);
    setTempListName(listName);
  };

  const handleListNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempListName(e.target.value);
  };

  const handleListNameBlur = async (listId: string) => {
    if (tempListName.trim() !== "") {
      try {
        await updateDoc(doc(db, "boards", boardId!, "lists", listId), {
          name: tempListName,
        });
        setLists((prevLists) =>
          prevLists.map((list) =>
            list.id === listId ? { ...list, name: tempListName } : list
          )
        );
      } catch (error) {
        console.error(t("errorUpdatingListName"), error);
      }
    }
    setIsEditingListName(null);
  };

  const handleBoardNameClick = () => {
    setIsEditingBoardName(true);
    setTempBoardName(boardName);
  };

  const handleBoardNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempBoardName(e.target.value);
  };

  const handleBoardNameBlur = async () => {
    if (tempBoardName.trim() !== "") {
      try {
        await updateDoc(doc(db, "boards", boardId!), {
          name: tempBoardName,
        });
        setBoardName(tempBoardName);
      } catch (error) {
        console.error(t("errorUpdatingBoardName"), error);
      }
    }
    setIsEditingBoardName(false);
  };

  const handleCreateDevotional = async () => {
    if (!boardId || !selectedListId) return;

    setLoadingDevotional(true);
    setGeneratedDevotional(""); // Clear previous content if any

    try {
      // Fetch the list's name and order
      const listDocRef = doc(db, "boards", boardId, "lists", selectedListId);
      const listDoc = await getDoc(listDocRef);
      const listData = listDoc.exists() ? listDoc.data() : null;

      if (!listData) {
        console.error("List not found.");
        setGeneratedDevotional("List not found.");
        return;
      }

      const listName = listData.name || "Unnamed List";

      // Fetch the cards in the list
      const cardsCollection = collection(
        db,
        "boards",
        boardId,
        "lists",
        selectedListId,
        "cards"
      );
      const cardsSnapshot = await getDocs(cardsCollection);
      const cardsData = cardsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Create the devotionalData array
      const devotionalData = cardsData.map((card) => ({
        cardName: card.name,
        comments: card.comments
          ? card.comments.map((comment) => comment.comment)
          : [],
      }));

      const commentsArr = devotionalData.map(
        (item) =>
          `Tarjeta: ${item.cardName} Comentarios: ${item.comments.join("")}`
      );

      // Construct the prompt including the card names and comments
      const prompt = `Por favor, crea un devocional basado en las siguientes notas de la lista "${listName}": ${commentsArr}. 

**Título:** ${listName}


Proporciona una visión general del tema basado en ${listName}. Explica brevemente la importancia de este tema en la vida espiritual y cómo se relaciona con nuestras experiencias diarias.


Establece la situación antes y después de Cristo en relación con el tema. Describe cómo las enseñanzas de Cristo transforman nuestra comprensión y aplicación de este tema.


Explica el tema en detalle, usando los versículos y comentarios de ${commentsArr} para ilustrar la comprensión y las causas subyacentes. Analiza cómo estos versículos abordan el tema y proporcionan orientación y consuelo.

Ofrece consejos prácticos sobre cómo aplicar el mensaje del devocional en la vida cotidiana. Reflexiona sobre cómo los principios del tema pueden influir en nuestras acciones y pensamientos diaros.

**Pensamiento del Día:**
Incluye una reflexión alentadora que refuerce el mensaje del devocional. Esta reflexión debe animar al lector a incorporar el tema en su vida y a recordar la promesa y el apoyo de Dios.


`;

      console.log(prompt);
      // Call the Netlify function to initiate the process
      const response = await fetch("/.netlify/functions/askChatGPT", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          messages: [{ role: "user", content: prompt }],
          boardId: boardId, // Pass the boardId
          listId: selectedListId, // Pass the listId
        }),
      });

      const data = await response.json();
      const result = data.result;

      if (result) {
        setGeneratedDevotional(result);

        // Automatically save the devotional after it's generated
        await saveDevotional(result);
      } else {
        setGeneratedDevotional(
          "An error occurred while generating the devotional."
        );
      }
    } catch (error) {
      console.error("Error generating devotional:", error);
      setGeneratedDevotional(
        "An error occurred while generating the devotional."
      );
    } finally {
      setLoadingDevotional(false);
    }
  };

  const saveDevotional = async (devotionalContent: string) => {
    if (!devotionalContent || !selectedListId || !boardId) return;

    try {
      const devotionalQuery = collection(
        db,
        "boards",
        boardId,
        "lists",
        selectedListId,
        "devotionals"
      );
      const devotionalSnapshot = await getDocs(devotionalQuery);

      if (!devotionalSnapshot.empty) {
        // If a devotional already exists, update it
        const devotionalDocRef = devotionalSnapshot.docs[0].ref;
        await updateDoc(devotionalDocRef, {
          content: devotionalContent,
          updatedAt: new Date(),
        });
      } else {
        // Otherwise, create a new one
        await addDoc(devotionalQuery, {
          content: devotionalContent,
          createdAt: new Date(),
        });
      }
    } catch (error) {
      console.error("Error saving devotional:", error);
    }
  };

  const handleOpenDevotionalModal = (listId: string) => {
    setSelectedListId(listId);
    setIsDevotionalModalOpen(true);
    setDropdownOpen(null); // Close the dropdown when the Devotional option is clicked
    fetchDevotional(listId);
  };

  const fetchDevotional = async (listId: string) => {
    if (!boardId || !listId) return;

    try {
      const devotionalQuery = collection(
        db,
        "boards",
        boardId,
        "lists",
        listId,
        "devotionals"
      );
      const devotionalSnapshot = await getDocs(devotionalQuery);

      if (!devotionalSnapshot.empty) {
        const devotionalDoc = devotionalSnapshot.docs[0];
        setGeneratedDevotional(devotionalDoc.data().content);
      } else {
        setGeneratedDevotional(""); // No devotional found
      }
    } catch (error) {
      console.error("Error fetching devotional:", error);
    }
  };

  const handleCloseDevotionalModal = () => {
    setIsDevotionalModalOpen(false);
    setGeneratedDevotional("");
    setSelectedListId(null);
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-100 p-4 sm:p-6 lg:p-8">
      <div className="bg-white p-8 rounded-2xl shadow-md w-full max-w-3xl mb-8">
        <div className="flex justify-between items-center">
          {isEditingBoardName ? (
            <input
              maxLength={150}
              type="text"
              value={tempBoardName}
              onChange={handleBoardNameChange}
              onBlur={handleBoardNameBlur}
              autoFocus
              className="text-xl font-bold text-gray-700 truncate max-w-[20ch] cursor-pointer border border-gray-300 rounded-md p-1"
            />
          ) : (
            <h1
              className="text-xl font-bold text-gray-700 truncate max-w-[20ch] cursor-pointer"
              title={boardName.length > 20 ? boardName : ""}
              onClick={handleBoardNameClick}
            >
              {boardName}
            </h1>
          )}
          <div className="flex items-center">
            {invitedUsers.slice(0, 4).map((invitedUser) => (
              <div
                key={invitedUser.id}
                className={`${invitedUser.color} text-white p-2 rounded-full w-8 h-8 flex items-center justify-center mr-2`}
                title={`${invitedUser.firstName} ${invitedUser.lastName}`}
              >
                {getInitials(invitedUser.firstName, invitedUser.lastName)}
              </div>
            ))}
            {invitedUsers.length > 4 && (
              <button
                onClick={handleOpenMembersModal}
                className="bg-blue-500 text-white p-2 rounded-full w-8 h-8 flex items-center justify-center mr-2"
              >
                +{invitedUsers.length - 4}
              </button>
            )}
            <button
              onClick={handleOpenInviteModal}
              className="bg-red-500 text-white p-2 rounded-full w-8 h-8 flex items-center justify-center"
            >
              +
            </button>
          </div>
        </div>
      </div>

      <div className="w-full max-w-3xl mb-8">
        <form onSubmit={handleAddList} className="flex justify-between mb-4">
          <input
            type="text"
            value={newListName}
            onChange={(e) => setNewListName(e.target.value)}
            placeholder={t("createNewNote")}
            className="border p-2 px-4 mr-2 flex-grow rounded-2xl"
            maxLength={150}
          />
          <button
            type="submit"
            className="bg-[#5932EA] bg-opacity-75 text-white p-2 rounded-2xl hover:bg-[#5932EA] transition-colors "
          >
            {t("addNote")}
          </button>
        </form>

        <div>
          {/* List rendering */}
          {loading
            ? [...Array(3)].map((_, i) => (
                <div
                  key={i}
                  className="bg-white rounded-2xl shadow-lg p-4 border-gray-100 border-2"
                >
                  <Skeleton height={25} width={100} />
                  <Skeleton height={50} count={3} />
                </div>
              ))
            : lists.map((list, index) => (
                <div
                  key={list.id}
                  className="bg-white rounded-2xl shadow-lg p-4 border-gray-100 border-2 mb-4"
                >
                  <div className="flex justify-between items-start mb-4">
                    {isEditingListName === list.id ? (
                      <input
                        maxLength={150}
                        type="text"
                        value={tempListName}
                        onChange={handleListNameChange}
                        onBlur={() => handleListNameBlur(list.id)}
                        autoFocus
                        className="text-xl font-bold border border-gray-300 rounded-md p-1"
                      />
                    ) : (
                      <h2
                        className="text-lg font-thin cursor-pointer"
                        onClick={() => handleListNameClick(list.id, list.name)}
                      >
                        {list.name}
                      </h2>
                    )}

                    <div className="flex items-center">
                      <button
                        onClick={() => toggleExpandList(list.id)}
                        className="text-gray-600 hover:text-gray-800 focus:outline-none mr-2"
                      >
                        {expandedLists[list.id] ? (
                          <FaChevronUp />
                        ) : (
                          <FaChevronDown />
                        )}
                      </button>
                      <div className="relative">
                        <HiOutlineDotsHorizontal
                          onClick={() => toggleDropdown(list.id)}
                          className="text-black cursor-pointer"
                        />
                        {dropdownOpen === list.id && (
                          <div
                            ref={dropdownRef}
                            className="absolute right-0 mt-2 w-48 bg-white border rounded-md shadow-lg z-10"
                          >
                            <button
                              onClick={() => handleOpenDevotionalModal(list.id)}
                              className="block w-full text-left px-4 py-2 text-sm text-gray-600 hover:bg-gray-100"
                            >
                              Devotional
                            </button>
                            <button
                              onClick={() => confirmDeleteList(list)}
                              className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                            >
                              {t("delete")}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {expandedLists[list.id] && (
                    <div
                      className="overflow-y-auto"
                      style={{
                        maxHeight: "calc(100vh - 300px)",
                        height: "auto",
                      }}
                    >
                      <CardComponent boardId={boardId!} listId={list.id} />
                    </div>
                  )}
                </div>
              ))}
        </div>
      </div>

      {boardId && (
        <InviteUserModal
          boardId={boardId}
          isOpen={isInviteModalOpen}
          onClose={() => {
            setIsInviteModalOpen(false);
            fetchInvitedUsers();
          }}
        />
      )}

      {isMembersModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-2xl w-full mx-4 max-h-[80%] overflow-y-auto">
            <h2 className="text-xl font-bold mb-4">{t("members")}</h2>
            <div className="grid grid-cols-2 gap-4">
              {invitedUsers.map((user) => (
                <div
                  key={user.id}
                  className={`${
                    user.color || "bg-blue-500"
                  } text-white p-2 rounded-full w-8 h-8 flex items-center justify-center cursor-pointer`}
                  onClick={() => handleOpenUserInfoModal(user)}
                  title={`${user.firstName} ${user.lastName}`}
                >
                  {getInitials(user.firstName, user.lastName)}
                </div>
              ))}
            </div>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setIsMembersModalOpen(false)}
                className="bg-red-500 text-white p-2 rounded-2xl"
              >
                {t("close")}
              </button>
            </div>
          </div>
        </div>
      )}

      {selectedUser && (
        <UserInfoModal
          user={selectedUser}
          isOpen={isUserInfoModalOpen}
          onClose={() => setIsUserInfoModalOpen(false)}
          boardId={boardId!}
          onRemoveUser={handleRemoveUser}
        />
      )}

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirm={handleConfirmDelete}
        message={t("confirmDeleteList")}
        loading={loadingDelete}
      />
      {isDevotionalModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-2xl w-full mx-4 max-h-[80%] flex flex-col">
            {/* Static Header */}
            <div className="flex items-center mb-4">
              <h2 className="text-xl font-bold mr-2">Devotional</h2>
              <HiSparkles
                onClick={() => toggleDropdown(selectedListId!)}
                className="text-[#5932EA] cursor-pointer"
                size={20}
              />
            </div>

            {/* Scrollable Content */}
            <div className="flex-grow overflow-y-auto mb-4">
              {loadingDevotional ? (
                <div className="flex justify-center items-center h-40">
                  <ClipLoader color="#5932EA" size={50} />
                </div>
              ) : !generatedDevotional ? (
                <p>No Devotional 🙏</p>
              ) : (
                <p className="text-gray-700 whitespace-pre-wrap text-center flex w-full">
                  {generatedDevotional}
                </p>
              )}
            </div>

            {/* Fixed Footer */}
            <div className="mt-auto flex justify-end pt-4">
              <button
                onClick={handleCreateDevotional}
                className="bg-[#5932EA] text-white p-2 rounded-2xl mr-2"
                disabled={loadingDevotional}
              >
                {generatedDevotional
                  ? "Re-generate Devotional"
                  : "Generate Devotional"}
              </button>
              <button
                onClick={handleCloseDevotionalModal}
                className="bg-red-500 text-white p-2 rounded-2xl"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Lists;
