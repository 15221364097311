import React, { useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../db/firebase";
import { useTranslation } from "react-i18next"; // Import useTranslation

const OrganizationSettings: React.FC = () => {
  const [orgName, setOrgName] = useState("");
  const [contactInfo, setContactInfo] = useState("");
  const [language, setLanguage] = useState("en"); // State for language
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation(); // Initialize translation

  useEffect(() => {
    const fetchOrganizationDetails = async () => {
      if (auth.currentUser) {
        // Fetch user document
        const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const orgDocRef = doc(db, "organizations", userData.organizationId);

          // Fetch organization document
          const orgDoc = await getDoc(orgDocRef);
          if (orgDoc.exists()) {
            const orgData = orgDoc.data();
            setOrgName(orgData.orgName);
            setContactInfo(orgData.contactInfo);
            setLanguage(orgData.language || "en"); // Set language state
            i18n.changeLanguage(orgData.language || "en"); // Apply language
          } else {
            console.error("No organization document found.");
          }
        }
        setLoading(false);
      }
    };

    fetchOrganizationDetails();
  }, [i18n]);

  const handleSave = async () => {
    if (auth.currentUser) {
      const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const orgDocRef = doc(db, "organizations", userData.organizationId);

        // Update organization document
        await updateDoc(orgDocRef, {
          orgName,
          contactInfo,
          language, // Save language
        });
        i18n.changeLanguage(language); // Apply the language after saving
        alert(t("organizationSettingsUpdated"));
      }
    }
  };

  if (loading) {
    return <div>{t("loading")}</div>;
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4 sm:p-6 lg:p-8">
      <div className="bg-white p-8 rounded-2xl shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-gray-700">
          {t("organizationSettings")}
        </h2>
        <div className="space-y-4">
          <div>
            <label htmlFor="orgName" className="block text-gray-600 mb-2">
              {t("orgName")}
            </label>
            <input
              type="text"
              id="orgName"
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
              placeholder={t("orgName")}
              required
              className="w-full px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <div>
            <label htmlFor="contactInfo" className="block text-gray-600 mb-2">
              {t("contactInfo")}
            </label>
            <input
              type="text"
              id="contactInfo"
              value={contactInfo}
              onChange={(e) => setContactInfo(e.target.value)}
              placeholder={t("contactInfo")}
              required
              className="w-full px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <div>
            <label htmlFor="language" className="block text-gray-600 mb-2">
              {t("language")}
            </label>
            <select
              id="language"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              className="w-full px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            >
              <option value="en">English</option>
              <option value="es">Spanish</option>
              {/* Add more languages as needed */}
            </select>
          </div>
          <button
            onClick={handleSave}
            className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition-colors"
          >
            {t("save")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrganizationSettings;
