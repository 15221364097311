import React, { useState, useEffect } from "react";
import { db } from "../../../db/firebase"; // Adjust the import path if necessary
import {
  updateDoc,
  doc,
  arrayUnion,
  arrayRemove,
  getDoc,
} from "firebase/firestore";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DangerButton from "../Components/DangerButton";
import { FaTrash, FaPlus } from "react-icons/fa";
import SuccessButton from "../Components/SuccessButton";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook

interface AddMemberModalProps {
  eventId: string;
  organizationId: string;
  onClose: () => void;
}

const AddMemberModal: React.FC<AddMemberModalProps> = ({
  eventId,
  organizationId,
  onClose,
}) => {
  const { t } = useTranslation(); // Initialize translation
  const [users, setUsers] = useState<any[]>([]);
  const [members, setMembers] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [noMembers, setNoMembers] = useState(false);

  useEffect(() => {
    const fetchUsersAndEventMembers = async () => {
      // Fetch the event document
      const eventDocRef = doc(db, "events", eventId);
      const eventDoc = await getDoc(eventDocRef);
      if (eventDoc.exists()) {
        const eventData = eventDoc.data();
        const groupOrMinistryId = eventData.groupOrMinistry;

        // Fetch the group or ministry document
        const groupOrMinistryDocRef = doc(
          db,
          groupOrMinistryId.startsWith("group") ? "groups" : "ministries",
          groupOrMinistryId
        );
        const groupOrMinistryDoc = await getDoc(groupOrMinistryDocRef);
        let groupOrMinistryMembers: string[] = [];

        if (groupOrMinistryDoc.exists()) {
          groupOrMinistryMembers = groupOrMinistryDoc.data().members || [];
        }

        if (groupOrMinistryMembers.length === 0) {
          setNoMembers(true);
          setLoading(false);
          return;
        }

        // Fetch users who are members of the group or ministry
        const userPromises = groupOrMinistryMembers.map(async (memberId) => {
          const userDoc = await getDoc(doc(db, "users", memberId));
          if (userDoc.exists()) {
            return { id: userDoc.id, ...userDoc.data() };
          }
          return null;
        });
        const usersData = await Promise.all(userPromises);
        const validUsers = usersData.filter((user) => user !== null);
        setUsers(validUsers);

        // Set the current members of the event
        setMembers(eventData.members || []);
      }
      setLoading(false);
    };

    fetchUsersAndEventMembers();
  }, [organizationId, eventId]);

  const handleAddMember = async (userId: string) => {
    const eventDocRef = doc(db, "events", eventId);

    // Update event document to add the member
    await updateDoc(eventDocRef, {
      members: arrayUnion(userId),
    });

    setMembers((prevMembers) => [...prevMembers, userId]);
  };

  const handleRemoveMember = async (userId: string) => {
    const eventDocRef = doc(db, "events", eventId);

    // Update event document to remove the member
    await updateDoc(eventDocRef, {
      members: arrayRemove(userId),
    });

    setMembers((prevMembers) =>
      prevMembers.filter((member) => member !== userId)
    );
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
      <div className="bg-white p-6 rounded-2xl shadow-lg w-full max-w-md">
        <h2 className="text-xl font-bold mb-4 text-center">
          {t("addRemoveMember")}
        </h2>
        {loading ? (
          <div className="text-center">
            <Skeleton count={5} />
          </div>
        ) : noMembers ? (
          <p className="text-gray-700 text-center">{t("noMembersFound")}</p>
        ) : (
          <ul className="space-y-2">
            {users.map((user) => (
              <li
                key={user.id}
                className="flex justify-between items-center border-b pb-2"
              >
                <span className="text-gray-800">
                  {user.firstName} {user.lastName}
                </span>
                {members.includes(user.id) ? (
                  <DangerButton
                    onClick={() => handleRemoveMember(user.id)}
                    text={t("remove")}
                    icon={<FaTrash />}
                    className="custom-class" // Optional: add additional custom class names if needed
                  />
                ) : (
                  <SuccessButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddMember(user.id);
                    }}
                    text={t("add")}
                    icon={<FaPlus />}
                    className="custom-class " // Optional: add additional custom class names if needed
                  />
                )}
              </li>
            ))}
          </ul>
        )}
        <button
          onClick={onClose}
          className="mt-4 bg-gray-500 text-white px-4 py-2 rounded-2xl w-full hover:bg-gray-600 transition-colors"
        >
          {t("close")}
        </button>
      </div>
    </div>
  );
};

export default AddMemberModal;
