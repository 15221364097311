import React, { useState, useEffect } from "react";
import { db } from "../../db/firebase"; // Adjust the import path if necessary
import { doc, getDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../db/firebase";
import SaveButton from "./Components/SaveButton";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next"; // Import useTranslation

const InviteUser: React.FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  const [email, setEmail] = useState("");
  const [user] = useAuthState(auth);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track form submission
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const { t } = useTranslation(); // Initialize translation

  useEffect(() => {
    const fetchUserRoleAndOrganization = async () => {
      if (user) {
        try {
          // Fetch user document
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            if (userData.role === "admin") {
              setIsAdmin(true);
              setOrganizationId(userData.organizationId);
            }
          }

          // Check if the user is an organization admin
          const orgDoc = await getDoc(doc(db, "organizations", user.uid));
          if (orgDoc.exists()) {
            setOrganizationId(orgDoc.id);
          }
        } catch (err) {
          setError(t("failedToFetchUserRoleOrOrganization"));
        }
      }
    };

    fetchUserRoleAndOrganization();
  }, [user, t]);

  const handleInvite = async (e: React.FormEvent) => {
    e.preventDefault();

    if (isSubmitting) {
      return; // Prevent multiple submissions
    }

    if (!email) {
      setError(t("pleaseEnterEmail"));
      return;
    }

    setIsSubmitting(true); // Disable the form

    try {
      if (organizationId) {
        const invitationLink = `${
          window.location.origin
        }/signup?invitationCode=${uuidv4()}`;

        // Call the backend endpoint to send the email and create the invitation document
        const response = await fetch("/.netlify/functions/sendInvite", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, invitationLink, organizationId }), // Ensure all fields are sent
        });

        if (response.ok) {
          setSuccess(t("invitationSent"));
          setEmail(""); // Clear the email input after success
        } else {
          const errorResponse = await response.json();
          setError(`${t("failedToSendInvitation")}: ${errorResponse.error}`);
        }
      } else {
        setError(t("organizationNotFound"));
      }
    } catch (error) {
      setError(t("failedToSendInvitation"));
      console.error(t("errorSendingInvitation"), error);
    } finally {
      setIsSubmitting(false); // Re-enable the form
    }
  };

  if (!isOpen || (!isAdmin && !organizationId)) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
      <div className="relative mx-auto p-6 border w-full max-w-md shadow-lg rounded-2xl bg-white">
        <div className="text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {t("inviteUser")}
          </h3>
          <div className="mt-4">
            <form onSubmit={handleInvite} className="space-y-4">
              <div>
                <label htmlFor="email" className="block text-gray-400 mb-2">
                  {t("userEmail")}
                </label>
                <input
                  maxLength={100}
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t("enterUserEmail")}
                  required
                  className="w-full px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
                  disabled={isSubmitting} // Disable the input while submitting
                />
              </div>
              <SaveButton
                isSubmitting={isSubmitting}
                text={isSubmitting ? t("sending") : t("sendInvite")}
                className="w-full" // Ensure it spans the full width
              />
              {error && <p className="text-red-500 mt-4">{error}</p>}
              {success && <p className="text-green-500 mt-4">{success}</p>}
            </form>
          </div>
        </div>
        <div className="mt-4">
          <button
            className="w-full px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-2xl shadow-sm hover:bg-gray-600 transition-colors"
            onClick={onClose}
            disabled={isSubmitting} // Disable the button while submitting
          >
            {t("close")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InviteUser;
