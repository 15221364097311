import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom"; // Removed 'Route' as it is not used
import { auth, db } from "../db/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

const PrivateRoute: React.FC<{ element: React.ReactElement }> = ({
  element,
}) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.approved) {
            setIsAuthenticated(true);
          }
        } else {
          const orgDoc = await getDoc(doc(db, "organizations", user.uid));
          if (orgDoc.exists()) {
            const orgData = orgDoc.data();
            if (orgData.role === "admin" || orgData.role === "organization") {
              setIsAuthenticated(true);
              setIsAdmin(true);
            }
          }
        }
      } else {
        setIsAuthenticated(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (isAdmin) {
    return element;
  }

  return isAuthenticated ? element : <Navigate to="/waiting-approval" />;
};

export default PrivateRoute;
