import React from "react";

interface CardProps {
  className?: string;
  onClick?: () => void;
}

const Card: React.FC<CardProps> = ({ className = "", children, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`bg-white p-8 rounded-2xl ${className} ${
        onClick ? "cursor-pointer" : ""
      }`}
    >
      {children}
    </div>
  );
};

export default Card;
