import React, { useEffect, useState } from "react";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "../../db/firebase";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const fetchAllSongs = async (
  setSongs: (value: any[]) => void,
  setLoading: (value: boolean) => void,
  setError: (value: string) => void,
  t: (key: string) => string
) => {
  setLoading(true);
  try {
    const songCollection = collection(db, "songs");
    const songQuery = query(songCollection, orderBy("title")); // Ensure consistent ordering

    const songSnapshot = await getDocs(songQuery);
    const songList = songSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setSongs(songList);
    setLoading(false);
  } catch (err) {
    console.error(t("errorFetchingSongs"), err);
    setError(t("failedToFetchSongs"));
    setLoading(false);
  }
};

const SongDatabase: React.FC = () => {
  const { t } = useTranslation();
  const [songs, setSongs] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchAllSongs(setSongs, setLoading, setError, t);
  }, [t]);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6 text-gray-700 text-center">
        {t("songDatabase")}
      </h1>
      {loading && <p className="text-gray-700">{t("loading")}...</p>}
      {error && <p className="text-red-500">{error}</p>}
      <ul>
        {songs.map((song, index) => (
          <li
            key={`${song.id}-${index}`}
            className="bg-white p-4 mb-4 rounded-2xl shadow-md"
          >
            <Link to={`/songs/${song.id}`} className="flex items-center">
              {song.artworkURL ? (
                <img
                  src={song.artworkURL}
                  alt={song.title}
                  className="w-20 h-20 rounded-md mr-4"
                />
              ) : (
                <div className="w-20 h-20 rounded-md bg-gray-300 mr-4"></div>
              )}
              <div>
                <h3 className="text-xl font-bold text-gray-800">
                  {song.title}
                </h3>
                <p className="text-gray-600">{song.artist}</p>
                <p className="text-gray-600">{song.album}</p>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SongDatabase;
