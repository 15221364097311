import React, { useState, useEffect } from "react";
import { db, auth } from "../../../db/firebase"; // Adjust the import path if necessary
import {
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
  collection,
  getDocs,
} from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";
import AddLeaderModal from "./AddLeader";
import AddMemberModal from "./AddMemberModal";
import { FaPlus, FaUsers, FaCalendarAlt } from "react-icons/fa";
import Card from "../Components/Card";
import AddButtonWithIcon from "../Components/AddButton";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook

const MinistryDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Initialize translation hook

  const [ministry, setMinistry] = useState<any | null>(null);
  const [leaders, setLeaders] = useState<any[]>([]);
  const [members, setMembers] = useState<any[]>([]);
  const [events, setEvents] = useState<any[]>([]);
  const [showLeaderModal, setShowLeaderModal] = useState(false);
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [organizationId, setOrganizationId] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserOrganization = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const orgId = userDoc.data().organizationId;
          const orgDoc = await getDoc(doc(db, "organizations", orgId));
          if (orgDoc.exists()) {
            setOrganizationId(orgDoc.id);
          }
        } else {
          const orgDoc = await getDoc(doc(db, "organizations", user.uid));
          if (orgDoc.exists()) {
            setOrganizationId(orgDoc.id);
          }
        }
      }
    };

    fetchUserOrganization();
  }, []);

  useEffect(() => {
    const fetchMinistry = () => {
      if (organizationId) {
        const ministryDocRef = doc(db, "ministries", id!);
        const unsubscribe = onSnapshot(ministryDocRef, (ministryDoc) => {
          if (
            ministryDoc.exists() &&
            ministryDoc.data().organizationId === organizationId
          ) {
            setMinistry(ministryDoc.data());
            fetchLeaders(ministryDoc.data().leaders || []);
            fetchMembers(ministryDoc.data().members || []);
            fetchEvents(ministryDoc.id);
          } else {
            console.error(
              "Ministry does not exist or user does not have permission."
            );
          }
        });

        return () => unsubscribe();
      }
    };

    const fetchLeaders = async (leaderIds: string[]) => {
      const leaderPromises = leaderIds.map(async (leaderId) => {
        const userDoc = await getDoc(doc(db, "users", leaderId));
        if (userDoc.exists()) {
          return { id: userDoc.id, ...userDoc.data() };
        }
        return null;
      });
      const leaderData = await Promise.all(leaderPromises);
      setLeaders(leaderData.filter((leader) => leader !== null));
    };

    const fetchMembers = async (memberIds: string[]) => {
      const memberPromises = memberIds.map(async (memberId) => {
        const userDoc = await getDoc(doc(db, "users", memberId));
        if (userDoc.exists()) {
          return { id: userDoc.id, ...userDoc.data() };
        }
        return null;
      });
      const memberData = await Promise.all(memberPromises);
      setMembers(memberData.filter((member) => member !== null));
    };

    const fetchEvents = async (ministryId: string) => {
      const eventsQuery = query(
        collection(db, "events"),
        where("groupOrMinistry", "==", ministryId)
      );
      const querySnapshot = await getDocs(eventsQuery);
      const eventsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEvents(eventsData);
    };

    fetchMinistry();
  }, [id, organizationId]);

  const handleAddLeader = () => {
    setShowLeaderModal(true);
  };

  const handleAddMember = () => {
    setShowMemberModal(true);
  };

  const handleLeaderModalClose = () => {
    setShowLeaderModal(false);
  };

  const handleMemberModalClose = () => {
    setShowMemberModal(false);
  };

  const handleEventClick = (eventId: string) => {
    navigate(`/organization/events/${eventId}`);
  };

  return (
    <div className="container mx-auto">
      {ministry ? (
        <div>
          <h1 className="text-3xl font-bold mb-6 text-gray-700">
            {ministry.name}
          </h1>
          <p className="text-gray-700">{ministry.description}</p>
          <div className="flex space-x-2 mt-4">
            <AddButtonWithIcon
              onClick={handleAddLeader}
              text={t("addLeader")}
              icon={<FaPlus />}
              className="custom-class" // Optional: add additional custom class names if needed
            />
            <AddButtonWithIcon
              onClick={handleAddMember}
              text={t("addMember")}
              icon={<FaPlus />}
              className="custom-class" // Optional: add additional custom class names if needed
            />
          </div>
          <div className="mt-4">
            <h2 className="text-xl font-bold mb-2 text-gray-700">
              {t("leaders")}
            </h2>
            {leaders.length > 0 ? (
              <ul className="space-y-2">
                {leaders.map((leader) => (
                  <Card key={leader.id}>
                    <div className="flex items-center">
                      <FaUsers className="mr-2 text-blue-500" />
                      <div>
                        <p className="text-gray-700">
                          {leader.firstName} {leader.lastName}
                        </p>
                        <p className="text-gray-500">{leader.email}</p>
                      </div>
                    </div>
                  </Card>
                ))}
              </ul>
            ) : (
              <p className="text-gray-600">{t("noLeadersAssigned")}</p>
            )}
          </div>
          <div className="mt-4">
            <h2 className="text-xl font-bold mb-2 text-gray-700">
              {t("members")}
            </h2>
            {members.length > 0 ? (
              <ul className="space-y-2">
                {members.map((member) => (
                  <Card key={member.id}>
                    <div className="flex items-center">
                      <FaUsers className="mr-2 text-green-500" />
                      <div>
                        <p className="text-gray-700">
                          {member.firstName} {member.lastName}
                        </p>
                        <p className="text-gray-500">{member.email}</p>
                      </div>
                    </div>
                  </Card>
                ))}
              </ul>
            ) : (
              <p className="text-gray-600">{t("noMembersAssigned")}</p>
            )}
          </div>
          <div className="mt-4">
            <h2 className="text-xl font-bold mb-2 text-gray-700">
              {t("events")}
            </h2>
            {events.length > 0 ? (
              <ul className="space-y-2">
                {events.map((event) => (
                  <Card
                    key={event.id}
                    onClick={() => handleEventClick(event.id)}
                    className="pointer"
                  >
                    <div className="flex items-center">
                      <FaCalendarAlt className="mr-2 text-yellow-500" />
                      <div>
                        <h3 className="text-lg font-bold text-gray-700">
                          {event.title}
                        </h3>
                        <p className="text-gray-500">{event.description}</p>
                        <p className="text-gray-500">
                          {t("date")}: {t("date")}:{" "}
                          {new Date(
                            `${event.date}T00:00:00`
                          ).toLocaleDateString()}
                          {t("time")}: {event.time}
                        </p>
                      </div>
                    </div>
                  </Card>
                ))}
              </ul>
            ) : (
              <p className="text-gray-600">{t("noEventsFound")}</p>
            )}
          </div>
        </div>
      ) : (
        <p className="text-gray-700">{t("loading")}</p>
      )}
      {showLeaderModal && (
        <AddLeaderModal
          ministryId={id!}
          organizationId={ministry.organizationId}
          onClose={handleLeaderModalClose}
        />
      )}
      {showMemberModal && (
        <AddMemberModal
          ministryId={id!}
          organizationId={ministry.organizationId}
          onClose={handleMemberModalClose}
        />
      )}
    </div>
  );
};

export default MinistryDetail;
