import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { IoMdArrowBack } from "react-icons/io";
import AddSongModal from "./Organization/Songs/AddSongModal";

const getRandomGradient = () => {
  const colors = [
    "#0B3D91",
    "#1C1C1C",
    "#343A40",
    "#2C3E50",
    "#4B0082",
    "#2F4F4F",
    "#191970",
    "#000000",
  ];
  const getRandomColor = () =>
    colors[Math.floor(Math.random() * colors.length)];
  return `linear-gradient(45deg, ${getRandomColor()}, ${getRandomColor()})`;
};

const SongLyrics = () => {
  const { artist, title } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [lyrics, setLyrics] = useState("");
  const [songDetails, setSongDetails] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showAddSongModal, setShowAddSongModal] = useState(false);

  useEffect(() => {
    const fetchLyrics = async () => {
      try {
        const response = await axios.get(
          `/.netlify/functions/lyrics?artist=${encodeURIComponent(
            artist
          )}&title=${encodeURIComponent(title)}`
        );
        setLyrics(response.data.lyrics);
        setSongDetails(response.data.songDetails);
        setError("");
      } catch (err) {
        setError("Lyrics not found");
        setLyrics("");
        setSongDetails(null);
      }
      setLoading(false);
    };

    fetchLyrics();
  }, [artist, title]);

  const handleImport = () => {
    setShowAddSongModal(true);
  };

  const handleModalClose = () => {
    setShowAddSongModal(false);
  };

  return (
    <div className="bg-white">
      {loading ? (
        <div className="flex justify-center">
          <ClipLoader size={35} color={"#123abc"} loading={loading} />
        </div>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <>
          <div
            className="fle flex-col h-[220px] mb-4"
            style={{ background: getRandomGradient() }}
          >
            <div className="">
              <button
                onClick={() =>
                  navigate(-1, {
                    state: { songs: location.state?.songs || [] },
                  })
                }
                className="text-white p-2 rounded-md"
              >
                <IoMdArrowBack className="w-[25px] h-[25px]" />
              </button>
            </div>
            <div className="flex">
              <div className="p-2 w-[40%]">
                {songDetails?.headerImageUrl && (
                  <img
                    src={songDetails.headerImageUrl}
                    alt={songDetails.title}
                    className="mx-auto mb-4 w-[170px] h-[150px]"
                  />
                )}
              </div>

              <div className="flex flex-col justify-start p-5 w-[60%]">
                <h1 className="text-2xl font-bold text-left text-white">
                  {songDetails?.title}
                </h1>
                <h2 className="text-base mb-1 text-left text-white underline">
                  {songDetails?.artist}
                </h2>
                {songDetails?.album && (
                  <h3 className="text-base font-bold mb-2 text-left text-white">
                    Album: {songDetails.album}
                  </h3>
                )}
              </div>
            </div>
          </div>
          <div className="px-4">
            {songDetails?.youtubeUrl && (
              <div className="mb-4">
                <iframe
                  width="100%"
                  height="315"
                  src={`https://www.youtube.com/embed/${new URL(
                    songDetails.youtubeUrl
                  ).searchParams.get("v")}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            )}
            <button
              onClick={handleImport}
              className="bg-green-500 my-4 text-white px-4 py-2 rounded-2xl hover:bg-green-600 transition-colors"
            >
              Import Song
            </button>
            <pre className="whitespace-pre-wrap mb-4">{lyrics}</pre>
            {songDetails?.appleMusicUrl && (
              <div className="mb-4">
                <iframe
                  title="Apple Music"
                  allow="autoplay *; encrypted-media *;"
                  frameBorder="0"
                  height="150"
                  style={{
                    width: "100%",
                    maxWidth: "660px",
                    overflow: "hidden",
                    background: "transparent",
                  }}
                  sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
                  src={`https://embed.music.apple.com${
                    new URL(songDetails.appleMusicUrl).pathname
                  }`}
                ></iframe>
              </div>
            )}
          </div>
          {songDetails?.releaseDate && (
            <p className="mb-2">
              <strong>Release Date:</strong> {songDetails.releaseDate}
            </p>
          )}
          {songDetails?.description && (
            <p className="mb-2">
              <strong>Description:</strong> {songDetails.description}
            </p>
          )}
          <p className="mb-2">
            <strong>Pageviews:</strong> {songDetails?.pageviews}
          </p>
        </>
      )}
      {showAddSongModal && (
        <AddSongModal
          onClose={handleModalClose}
          prefillData={{
            title: songDetails?.title,
            artist: songDetails?.artist,
            album: songDetails?.album,
            lyrics,
            youtubeLink: songDetails?.youtubeUrl,
            artworkURL: songDetails?.headerImageUrl,
          }}
        />
      )}
    </div>
  );
};

export default SongLyrics;
