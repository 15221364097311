// utils/handleFunctions.ts
import {
  doc,
  updateDoc,
  collection,
  deleteDoc,
  getDocs,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { DropResult } from "react-beautiful-dnd";
import { db } from "../../../../db/firebase";

export const handleRemoveSong = async (
  songId: string,
  id: string,
  setSongs: React.Dispatch<React.SetStateAction<any[]>>
) => {
  const eventDocRef = doc(db, "events", id);
  const eventDoc = await getDoc(eventDocRef);
  if (eventDoc.exists()) {
    const eventData = eventDoc.data();
    const updatedSongs = eventData.songs.filter(
      (song: string) => song !== songId
    );
    await updateDoc(eventDocRef, {
      songs: updatedSongs,
    });
    setSongs((prevSongs) => prevSongs.filter((song) => song.id !== songId));
  } else {
    console.error("Event document does not exist.");
  }
};

export const handleDragEnd = async (
  result: DropResult,
  songs: any[],
  setSongs: React.Dispatch<React.SetStateAction<any[]>>,
  id: string
) => {
  if (!result.destination) return;

  // Create a deep copy of the songs array if necessary
  const items = Array.from(songs);

  // Reorder the dragged item
  const [reorderedItem] = items.splice(result.source.index, 1);
  items.splice(result.destination.index, 0, reorderedItem);

  // Update the order
  const updatedSongs = items.map((song, index) => ({
    ...song,
    order: index,
  }));

  // Set the new state
  setSongs(updatedSongs);

  try {
    const eventDocRef = doc(db, "events", id);
    await updateDoc(eventDocRef, {
      songs: updatedSongs.map((song) => song.id),
    });
  } catch (error) {
    console.error("Error updating song order: ", error);
  }
};

export const handleSetPlaylist = async (
  organizationId: string | null,
  songs: any[],
  id: string,
  setSettingPlaylist: React.Dispatch<React.SetStateAction<boolean>>,
  setIsCurrentPlaylist: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (!organizationId) return;
  setSettingPlaylist(true); // Start loading spinner

  const playlistCollectionRef = collection(
    db,
    "organizations",
    organizationId,
    "playlists"
  );

  try {
    // Delete all existing documents in the playlist subcollection
    const existingDocsSnapshot = await getDocs(playlistCollectionRef);
    const deletePromises = existingDocsSnapshot.docs.map((doc) =>
      deleteDoc(doc.ref)
    );
    await Promise.all(deletePromises);

    // Add new songs as individual documents in the subcollection
    const addPromises = songs.map((song) => {
      const songData = {
        title: song.title,
        artist: song.artist,
        album: song.album,
        updatedAt: new Date(),
      };

      // Only include youtubeURL if it exists
      if (song.youtubeURL) {
        songData["youtubeURL"] = song.youtubeURL;
      }

      return setDoc(doc(playlistCollectionRef, song.id), songData);
    });
    await Promise.all(addPromises);

    // Update the event to reflect the current playlist
    await updateDoc(doc(db, "events", id), {
      currentPlayList: true,
    });

    setIsCurrentPlaylist(true); // Update local state
    alert("Playlist set successfully!");
  } catch (error) {
    console.error("Error setting playlist: ", error);
  } finally {
    setSettingPlaylist(false); // Stop loading spinner
  }
};
