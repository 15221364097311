import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  deleteDoc,
  doc,
  getDoc,
  arrayRemove,
  writeBatch,
} from "firebase/firestore";
import { db, auth } from "../../db/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { FaUserPlus, FaCheck, FaTrashAlt, FaEdit } from "react-icons/fa";
import InviteUser from "./InviteUser"; // Adjust the import path if necessary
import EditUserModal from "./EditUserModal"; // Import the EditUserModal component

import Card from "./Components/Card";
import DangerButton from "./Components/DangerButton";
import SuccessButton from "./Components/SuccessButton";
import AddButtonWithIcon from "./Components/AddButton";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import { useTranslation } from "react-i18next"; // Import useTranslation

const Users: React.FC = () => {
  const [user] = useAuthState(auth);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [approvedUsers, setApprovedUsers] = useState([]);
  const [error, setError] = useState("");
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any | null>(null);
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<any | null>(null);
  const { t } = useTranslation(); // Initialize translation

  useEffect(() => {
    const fetchUserRoleAndOrganization = async () => {
      if (user) {
        try {
          // Fetch user document
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            if (userData.role === "admin") {
              setOrganizationId(userData.organizationId);
            }
          }

          // Check if the user is an organization admin
          const orgDoc = await getDoc(doc(db, "organizations", user.uid));
          if (orgDoc.exists()) {
            setOrganizationId(orgDoc.id);
          }
        } catch (err) {
          setError(t("errorFetchingUsers"));
        }
      }
    };

    fetchUserRoleAndOrganization();
  }, [user, t]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (user && organizationId) {
        try {
          const usersCollection = collection(db, "users");
          const usersQuery = query(
            usersCollection,
            where("organizationId", "==", organizationId)
          );
          const usersSnapshot = await getDocs(usersQuery);

          const usersList = usersSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setPendingUsers(usersList.filter((user) => user.approved === false));
          setApprovedUsers(usersList.filter((user) => user.approved === true));
        } catch (err) {
          setError(t("errorFetchingUsers"));
        }
      }
    };

    fetchUsers();
  }, [user, organizationId, t]);

  const approveUser = async (userId: string, userEmail: string) => {
    try {
      await updateDoc(doc(db, "users", userId), {
        approved: true,
      });
      setPendingUsers(pendingUsers.filter((user) => user.id !== userId));
      setApprovedUsers((prev) => [
        ...prev,
        pendingUsers.find((user) => user.id === userId),
      ]);

      // Call the backend endpoint to send the email
      const response = await fetch("/.netlify/functions/sendAppovalEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: userEmail }),
      });

      if (!response.ok) {
        throw new Error("Failed to send approval email.");
      }
    } catch (error) {
      setError(t("errorApprovingUser") + error.message);
    }
  };

  const confirmDeleteUser = (user: any) => {
    setUserToDelete(user);
    setIsDeleteModalOpen(true);
  };

  const deleteUser = async () => {
    if (!userToDelete) return;

    const userId = userToDelete.id;
    try {
      const batch = writeBatch(db);

      // Fetch all ministries where the user is a leader or member
      const ministriesRef = collection(db, "ministries");
      const ministriesSnapshot = await getDocs(ministriesRef);

      ministriesSnapshot.docs.forEach((ministryDoc) => {
        const ministryData = ministryDoc.data();
        const ministryDocRef = doc(db, "ministries", ministryDoc.id);

        if (ministryData.leaders && ministryData.leaders.includes(userId)) {
          batch.update(ministryDocRef, {
            leaders: arrayRemove(userId),
          });
        }

        if (ministryData.members && ministryData.members.includes(userId)) {
          batch.update(ministryDocRef, {
            members: arrayRemove(userId),
          });
        }
      });

      // Commit the batch
      await batch.commit();

      // Delete the user document
      await deleteDoc(doc(db, "users", userId));
      setPendingUsers(pendingUsers.filter((user) => user.id !== userId));
      setApprovedUsers(approvedUsers.filter((user) => user.id !== userId));
      setIsDeleteModalOpen(false);
      setUserToDelete(null);
    } catch (error) {
      setError(t("errorDeletingUser") + error.message);
    }
  };

  const handleEditUser = (user: any) => {
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };

  const handleEditUserSubmit = async (updatedUser: any) => {
    try {
      await updateDoc(doc(db, "users", updatedUser.id), updatedUser);
      setApprovedUsers((prev) =>
        prev.map((user) => (user.id === updatedUser.id ? updatedUser : user))
      );
      setIsEditModalOpen(false);
      setSelectedUser(null);
    } catch (error) {
      setError(t("errorApprovingUser") + error.message);
    }
  };

  return (
    <div className="container mx-auto ">
      <div className="">
        <h2 className="text-3xl font-light mb-4 text-gray-700">{t("users")}</h2>

        <div className="mb-6">
          <AddButtonWithIcon
            onClick={() => setIsInviteModalOpen(true)}
            text={t("inviteUser")}
            icon={<FaUserPlus />}
            className="custom-class" // Optional: add additional custom class names if needed
          />

          <InviteUser
            isOpen={isInviteModalOpen}
            onClose={() => setIsInviteModalOpen(false)}
          />
        </div>

        {/* Pending users table */}
        <Card className="mb-6">
          <h3 className="text-xl font-semibold mb-4 text-gray-600">
            {t("pendingApproval")}
          </h3>
          <div className="overflow-x-auto">
            {pendingUsers.length === 0 ? (
              <p className="text-gray-600">{t("noPendingUsers")}</p>
            ) : (
              <table className="min-w-full bg-white ">
                <thead>
                  <tr>
                    <th className="py-2 px-4 border-b text-left font-thin text-gray-400">
                      {t("name")}
                    </th>
                    <th className="py-2 px-4 border-b text-left font-thin text-gray-400">
                      {t("email")}
                    </th>
                    <th className="py-2 px-4 border-b text-left font-thin text-gray-400">
                      {t("actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pendingUsers.map((user) => (
                    <tr key={user.id}>
                      <td className="py-2 px-4 border-b">
                        {user.firstName} {user.lastName}
                      </td>
                      <td className="py-2 px-4 border-b">{user.email}</td>
                      <td className="py-2 px-4 border-b flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
                        <SuccessButton
                          onClick={() => approveUser(user.id, user.email)}
                          text={t("approve")}
                          icon={<FaCheck />}
                          className="custom-class flex justify-center" // Optional: add additional custom class names if needed
                        />
                        <DangerButton
                          onClick={() => confirmDeleteUser(user)}
                          text={t("delete")}
                          icon={<FaTrashAlt />}
                          className="custom-class flex justify-center" // Optional: add additional custom class names if needed
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </Card>

        {/* Approved users table */}
        <Card className="mb-6">
          <h3 className="text-xl font-semibold mb-4 text-gray-600">
            {t("approvedUsers")}
          </h3>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white l shadow-md">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b text-gray-400 text-left font-thin">
                    {t("name")}
                  </th>
                  <th className="py-2 px-4 border-b text-gray-400 text-left font-thin">
                    {t("email")}
                  </th>
                  <th className="py-2 px-4 border-b text-gray-400 text-left font-thin">
                    {t("role")}
                  </th>
                  <th className="py-2 px-4 border-b text-gray-400 text-left font-thin">
                    {t("actions")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {approvedUsers.map((user) => (
                  <tr key={user.id}>
                    <td className="py-2 px-4 border-b">
                      {user.firstName} {user.lastName}
                    </td>
                    <td className="py-2 px-4 border-b">{user.email}</td>
                    <td className="py-2 px-4 border-b">{user.role}</td>
                    <td className="py-2 px-4 border-b flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
                      <SuccessButton
                        onClick={() => handleEditUser(user)}
                        text={t("edit")}
                        icon={<FaEdit />}
                        className="custom-class flex justify-center" // Optional: add additional custom class names if needed
                      />
                      <DangerButton
                        onClick={() => confirmDeleteUser(user)}
                        text={t("delete")}
                        icon={<FaTrashAlt />}
                        className="custom-class flex justify-center" // Optional: add additional custom class names if needed
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
        {error && <p className="text-red-500">{error}</p>}
      </div>

      {isEditModalOpen && (
        <EditUserModal
          user={selectedUser}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={handleEditUserSubmit}
        />
      )}
      {isDeleteModalOpen && (
        <ConfirmDeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={deleteUser}
          userName={
            userToDelete
              ? `${userToDelete.firstName} ${userToDelete.lastName}`
              : ""
          }
        />
      )}
    </div>
  );
};

export default Users;
