// utils/assignColors.ts
export const assignColors = (people: any[]) => {
  const assignedColors: Record<string, string> = {};
  const colors = [
    "bg-red-500",
    "bg-blue-500",
    "bg-green-500",
    "bg-yellow-500",
    "bg-purple-500",
  ];
  people.forEach((person) => {
    if (person && person.id) {
      assignedColors[person.id] =
        colors[Math.floor(Math.random() * colors.length)];
    }
  });
  return assignedColors;
};
