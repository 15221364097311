import { serverTimestamp } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../db/firebase";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

interface GroupModalProps {
  group: any | null;
  onSave: (group: any) => void;
  onClose: () => void;
}

const GroupModal: React.FC<GroupModalProps> = ({ group, onSave, onClose }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [user] = useAuthState(auth);
  const { t } = useTranslation(); // Initialize translation

  useEffect(() => {
    if (group) {
      setName(group.name);
      setDescription(group.description);
    } else {
      setName("");
      setDescription("");
    }
  }, [group]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave({
      name,
      description,
      timestamp: serverTimestamp(),
      userId: user?.uid,
    });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white p-6 rounded-2xl shadow-lg w-full max-w-md">
        <h2 className="text-xl font-bold mb-4">
          {group ? t("editGroup") : t("addGroup")}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-gray-600 mb-2">
              {t("groupName")}
            </label>
            <input
              maxLength={150}
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <div>
            <label htmlFor="description" className="block text-gray-600 mb-2">
              {t("description")}
            </label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            ></textarea>
          </div>
          <div className="flex flex-col justify-end ">
            <button
              type="submit"
              className="bg-[#5932EA] bg-opacity-75 text-white px-4 py-2 rounded-2xl mb-4 hover:bg-[#5932EA] transition-colors "
            >
              {t("save")}
            </button>
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 text-white px-4 py-2 rounded-2xl mb-2"
            >
              {t("cancel")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GroupModal;
