import React, { useEffect, useState, startTransition } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import UserDashboard from "./pages/User/Dashboard";
import AdminDashboard from "./pages/Organization/AdminDashboard";
import OrganizationSettings from "./pages/Organization/Settings";
import AdminLogin from "./pages/Organization/AdminLogin";
import Boards from "./pages/Boards";
import Lists from "./pages/Lists";
import SongSearch from "./pages/SongSearch";
import SongLyrics from "./pages/SongLyrics";
import PrivateRoute from "./utils/PrivateRoute";
import { auth, db } from "./db/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import Users from "./pages/Organization/Users";
import Groups from "./pages/Organization/Groups/Groups";
import Events from "./pages/Organization/Events/Events";
import WaitingApproval from "./pages/Organization/WaitingApproval";
import DashboardLayout from "./pages/Organization/DasboardLayout";
import Ministries from "./pages/Organization/Ministry/Ministries";
import MinistryDetail from "./pages/Organization/Ministry/MinistryDetails";
import GroupDetail from "./pages/Organization/Groups/GroupDetail";
import LandingPage from "./pages/LandingPage";
import UserEventDetail from "./pages/User/Eventdetail";
import EventDetail from "./pages/Organization/Events/EventDetail";
import Songs from "./pages/Organization/Songs/Songs";
import EditSong from "./pages/Organization/Songs/EditSong";
import ViewSong from "./pages/Organization/Songs/ViewSong";
import CommunicationsPage from "./pages/Organization/Communications/ComunicationsPage";
import SongDatabase from "./pages/User/SongDatabase";
import Profile from "./pages/User/Profile";
import { useTranslation } from "react-i18next";
import SignupOrganization from "./pages/Organization/SignUpOrganization";

const App: React.FC = () => {
  const [userRole, setUserRole] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists() && userDoc.data().approved) {
          const orgDoc = await getDoc(
            doc(db, "organizations", userDoc.data().organizationId)
          );
          if (orgDoc.exists()) {
            const orgData = orgDoc.data();
            startTransition(() => {
              i18n.changeLanguage(orgData.language || "en");
            });
            setUserRole(userDoc.data().role);
          }
        } else {
          setUserRole(null);
        }
      } else {
        setUserRole(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [i18n]);

  if (loading) {
    return <div>{t("loading")}</div>;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          userRole ? (
            <PrivateRoute element={<UserDashboard />} />
          ) : (
            <LandingPage />
          )
        }
      />
      <Route
        path="/dashboard"
        element={
          userRole === "organization" ? (
            <Navigate to="/organization/dashboard" />
          ) : (
            <Navigate to="/user/dashboard" />
          )
        }
      />
      <Route path="/signup" element={<Signup />} />
      <Route path="/organization-signup" element={<SignupOrganization />} />
      <Route path="/login" element={<Login />} />
      <Route path="/login-admin" element={<AdminLogin />} />
      <Route
        path="/user/dashboard"
        element={<PrivateRoute element={<UserDashboard />} />}
      />
      <Route path="/waiting-approval" element={<WaitingApproval />} />
      <Route
        path="/organization/*"
        element={
          <DashboardLayout>
            <Routes>
              <Route
                path="dashboard"
                element={<PrivateRoute element={<AdminDashboard />} />}
              />
              <Route
                path="settings"
                element={<PrivateRoute element={<OrganizationSettings />} />}
              />
              <Route
                path="song-search"
                element={<PrivateRoute element={<SongSearch />} />}
              />
              <Route
                path="users"
                element={<PrivateRoute element={<Users />} />}
              />
              <Route
                path="groups"
                element={<PrivateRoute element={<Groups />} />}
              />
              <Route
                path="groups/:id"
                element={<PrivateRoute element={<GroupDetail />} />}
              />
              <Route
                path="ministries"
                element={<PrivateRoute element={<Ministries />} />}
              />
              <Route
                path="ministries/:id"
                element={<PrivateRoute element={<MinistryDetail />} />}
              />
              <Route
                path="events"
                element={<PrivateRoute element={<Events />} />}
              />
              <Route
                path="events/:id"
                element={<PrivateRoute element={<EventDetail />} />}
              />
              <Route
                path="songs"
                element={<PrivateRoute element={<Songs />} />}
              />
              <Route
                path="songs/:id/edit"
                element={<PrivateRoute element={<EditSong />} />}
              />
              <Route
                path="songs/:id/view"
                element={<PrivateRoute element={<ViewSong />} />}
              />
              <Route path="communications" element={<CommunicationsPage />} />
            </Routes>
          </DashboardLayout>
        }
      />
      <Route path="/boards" element={<PrivateRoute element={<Boards />} />} />
      <Route
        path="/boards/:boardId"
        element={<PrivateRoute element={<Lists />} />}
      />
      <Route
        path="/songs"
        element={<PrivateRoute element={<SongSearch />} />}
      />
      <Route
        path="/songs/:artist/:title"
        element={<PrivateRoute element={<SongLyrics />} />}
      />
      <Route
        path="/user/eventdetail/:eventId"
        element={<PrivateRoute element={<UserEventDetail />} />}
      />
      <Route
        path="/songs/:id"
        element={<PrivateRoute element={<ViewSong />} />}
      />
      <Route
        path="/song-database"
        element={<PrivateRoute element={<SongDatabase />} />}
      />
      <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
    </Routes>
  );
};

export default App;
