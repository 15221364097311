import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ClipLoader } from "react-spinners";

const SongSearch = () => {
  const [artist, setArtist] = useState("");
  const [song, setSong] = useState("");
  const [songs, setSongs] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();

  const fetchSongs = async (artist, song, page) => {
    if (page === 1) {
      setLoading(true);
    } else {
      setLoadingMore(true);
    }
    setError("");
    try {
      let params = "";
      if (artist) {
        params += `artist=${encodeURIComponent(artist)}`;
      }
      if (song) {
        if (params) params += "&";
        params += `song=${encodeURIComponent(song)}`;
      }
      console.log("test");
      const response = await axios.get(
        `/.netlify/functions/songs?${params}&page=${page}`
      );
      if (response.data.songs.length === 0) {
        setHasMore(false);
      } else {
        setSongs((prevSongs) => [...prevSongs, ...response.data.songs]);
      }
      setError("");
    } catch (err) {
      setError("Songs not found");
      setHasMore(false);
    }
    setLoading(false);
    setLoadingMore(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (artist.trim() === "" && song.trim() === "") {
      setError("Artist or Song title is required");
      return;
    }
    setSongs([]);
    setPage(1);
    setHasMore(true);
    fetchSongs(artist, song, 1);
  };

  const handleSongClick = (song) => {
    const artistName = artist || song.primary_artist.name;
    navigate(
      `/songs/${encodeURIComponent(artistName)}/${encodeURIComponent(
        song.title
      )}`,
      { state: { songs } }
    );
  };

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    )
      return;
    if (!loadingMore && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [loadingMore, hasMore]);

  useEffect(() => {
    if ((artist || song) && page > 1) {
      fetchSongs(artist, song, page);
    }
  }, [artist, song, page]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const handleReset = () => {
    setArtist("");
    setSong("");
    setSongs([]);
    setError("");
    setPage(1);
    setHasMore(true);
  };

  return (
    <div className="min-h-screen p-6 bg-white rounded-2xl">
      <h1 className="text-2xl font-bold mb-4 text-center">
        Search for Songs by Artist or Song Title
      </h1>
      <h3 className="text-center mb-2 text-gray-500">
        Search for songs online and add them to your organization
      </h3>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* <input
          type="text"
          placeholder="Artist"
          value={artist}
          onChange={(e) => setArtist(e.target.value)}
          className="w-full p-2 px-4 border border-gray-300 rounded-2xl focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
        /> */}
        <input
          maxLength={150}
          type="text"
          placeholder="Search for song or artist"
          value={song}
          onChange={(e) => setSong(e.target.value)}
          className="w-full p-2 px-4  border border-gray-300 rounded-2xl focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
        />
        <div className="flex space-x-4">
          <button
            type="submit"
            className="flex-1 bg-[#5932EA]  bg-opacity-75 text-white p-2 rounded-2xl hover:bg-[#5932EA] transition-colors0"
          >
            Search
          </button>
          <button
            type="button"
            onClick={handleReset}
            className="flex-1 bg-gray-500 text-white p-2 rounded-2xl hover:bg-gray-600"
          >
            Reset
          </button>
        </div>
      </form>
      {loading && (
        <div className="mt-4 flex justify-center">
          <ClipLoader size={35} color={"#123abc"} loading={loading} />
        </div>
      )}
      {error && <p className="mt-4 text-red-500">{error}</p>}
      {songs.length > 0 && (
        <div className="mt-6 bg-gray-100 p-4 rounded-md max-h-120 overflow-y-auto">
          <h2 className="text-xl font-bold mb-4">Songs:</h2>
          <ul>
            {songs.map((song) => (
              <li key={song.id} className="mb-2">
                <span
                  onClick={() => handleSongClick(song)}
                  className="text-[#5932EA] hover:underline cursor-pointer"
                >
                  {song.title}
                  {song.primary_artist && artist === "" && (
                    <span className="text-sm text-gray-600">
                      {" "}
                      by {song.primary_artist.name}
                    </span>
                  )}
                </span>
              </li>
            ))}
          </ul>
          {loadingMore && (
            <div className="flex justify-center mt-4">
              <ClipLoader size={25} color={"#123abc"} loading={loadingMore} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SongSearch;
