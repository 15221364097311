import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../src/db/firebase"; // Adjust the import path if necessary
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  query,
  where,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { FaTrash, FaRegStar } from "react-icons/fa";
import ConfirmationModal from "../components/ConfirmationModal"; // Assuming you have a ConfirmationModal component
import Skeleton from "react-loading-skeleton"; // Import the skeleton package
import "react-loading-skeleton/dist/skeleton.css"; // Import the skeleton CSS
import { useTranslation } from "react-i18next";

interface Board {
  id: string;
  name: string;
  userId: string;
  invitedUsers: Record<string, boolean>;
  favorite: boolean; // Add the favorite attribute
}

interface User {
  id: string;
  firstName: string;
  lastName: string;
}

const Boards: React.FC = () => {
  const { t } = useTranslation();
  const [boards, setBoards] = useState<Board[]>([]);
  const [newBoardName, setNewBoardName] = useState<string>("");
  const [invitedUsers, setInvitedUsers] = useState<Record<string, User[]>>({});
  const [loading, setLoading] = useState(true);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const [boardToDelete, setBoardToDelete] = useState<string | null>(null);
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (!user) return;

    const fetchBoards = async () => {
      try {
        const membershipsCollection = collection(db, "memberships");
        const membershipsQuery = query(
          membershipsCollection,
          where("userId", "==", user.uid)
        );
        const membershipsSnapshot = await getDocs(membershipsQuery);
        const boardIds = membershipsSnapshot.docs.map(
          (doc) => doc.data().boardId
        );
        const boardPromises = boardIds.map((id) =>
          getDoc(doc(db, "boards", id))
        );
        const boardSnapshots = await Promise.all(boardPromises);
        const boardsList = boardSnapshots.map((snapshot) => ({
          id: snapshot.id,
          name: snapshot.data()?.name,
          userId: snapshot.data()?.userId,
          invitedUsers: snapshot.data()?.invitedUsers || {},
          favorite: snapshot.data()?.favorite || false, // Get the favorite status
        }));
        setBoards(boardsList as Board[]);

        const userPromises = boardsList.map(async (board) => {
          const invitedUserIds = Object.keys(board.invitedUsers);
          const userDocs = await Promise.all(
            invitedUserIds.map((userId) => getDoc(doc(db, "users", userId)))
          );
          return {
            boardId: board.id,
            users: userDocs
              .filter((userDoc) => userDoc.exists())
              .map((userDoc) => ({
                id: userDoc.id,
                ...userDoc.data(),
              })) as User[],
          };
        });

        const usersData = await Promise.all(userPromises);
        const usersMap: Record<string, User[]> = {};
        usersData.forEach((data) => {
          usersMap[data.boardId] = data.users;
        });
        setInvitedUsers(usersMap);
      } catch (error) {
        console.error("Error fetching boards: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBoards();
  }, [user]);

  const getRandomColor = () => {
    const colors = [
      "bg-red-500",
      "bg-green-500",
      "bg-blue-500",
      "bg-yellow-500",
      "bg-purple-500",
      "bg-pink-500",
      "bg-indigo-500",
      "bg-teal-500",
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const handleAddBoard = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newBoardName.trim() === "" || !user) {
      console.error("Board name cannot be empty or user is not authenticated");
      return;
    }
    try {
      const boardRef = await addDoc(collection(db, "boards"), {
        name: newBoardName,
        userId: user.uid,
        invitedUsers: {},
        favorite: false, // Add favorite attribute here
      });

      const randomColor = getRandomColor();

      await setDoc(doc(db, "memberships", `${user.uid}_${boardRef.id}`), {
        userId: user.uid,
        boardId: boardRef.id,
        role: "owner",
        color: randomColor,
      });

      setBoards([
        ...boards,
        {
          id: boardRef.id,
          name: newBoardName,
          userId: user.uid,
          invitedUsers: {},
          favorite: false, // Include in local state update
        },
      ]);
      setNewBoardName("");
    } catch (error) {
      console.error("Error adding board: ", error);
    }
  };

  const handleDeleteBoard = async (id: string) => {
    try {
      await deleteDoc(doc(db, "boards", id));
      if (user) {
        await deleteDoc(doc(db, `memberships/${user.uid}_${id}`));
      }
      setBoards(boards.filter((board) => board.id !== id));
    } catch (error) {
      console.error("Error deleting board: ", error);
    } finally {
      setIsConfirmationModalOpen(false);
    }
  };

  const toggleFavorite = async (boardId: string, currentStatus: boolean) => {
    try {
      await updateDoc(doc(db, "boards", boardId), {
        favorite: !currentStatus,
      });

      setBoards((prevBoards) =>
        prevBoards.map((board) =>
          board.id === boardId ? { ...board, favorite: !currentStatus } : board
        )
      );
    } catch (error) {
      console.error("Error updating favorite status: ", error);
    }
  };

  const confirmDeleteBoard = (id: string) => {
    setBoardToDelete(id);
    setIsConfirmationModalOpen(true);
  };

  const getInitials = (firstName: string, lastName: string) => {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-100 p-4 sm:p-6 lg:p-8">
      <div className="p-4 w-full max-w-3xl mb-8">
        <h1 className="text-2xl font-bold mb-6 text-gray-700 text-center">
          {t("boards")}
        </h1>
        <form
          onSubmit={handleAddBoard}
          className="flex flex-col items-center mb-6"
        >
          <input
            type="text"
            value={newBoardName}
            onChange={(e) => setNewBoardName(e.target.value)}
            placeholder={t("createNewBoard")}
            className="border py-2 px-4 rounded-2xl w-full mb-4"
            maxLength={100}
          />
          <button
            type="submit"
            className="bg-[#5932EA] bg-opacity-75 text-white py-2 px-4 rounded-2xl w-full hover:bg-[#5932EA] transition-colors"
          >
            {t("addBoard")}
          </button>
        </form>

        {boards.some((board) => board.favorite) && (
          <>
            <h2 className="text-xl font-bold mb-4">{t("favorites")}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
              {boards
                .filter((board) => board.favorite)
                .map((board) => (
                  <div
                    key={board.id}
                    className="bg-white p-4 rounded-2xl cursor-pointer shadow-lg flex flex-col justify-between"
                    onClick={() => navigate(`/boards/${board.id}`)}
                  >
                    <div>
                      <h2 className="text-base font-thin text-black mb-2">
                        {board.name}
                      </h2>
                      <div className="flex flex-wrap">
                        {invitedUsers[board.id]?.map((user) => (
                          <div
                            key={user.id}
                            className={`${
                              user.color || "bg-blue-500"
                            } text-white p-2 rounded-full w-10 h-10 flex items-center justify-center mr-2`}
                          >
                            {getInitials(user.firstName, user.lastName)}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex justify-between mt-4">
                      <FaTrash
                        onClick={(e) => {
                          e.stopPropagation();
                          confirmDeleteBoard(board.id);
                        }}
                        className="text-black cursor-pointer"
                      />
                      <FaRegStar
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleFavorite(board.id, board.favorite);
                        }}
                        className={`cursor-pointer ${
                          board.favorite ? "text-yellow-500" : "text-black"
                        }`}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}

        <h2 className="text-xl font-bold mb-4">{t("allNotes")}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {loading ? (
            <>
              <Skeleton count={6} height={120} width={"100%"} />
            </>
          ) : (
            boards
              .filter((board) => !board.favorite)
              .map((board) => (
                <div
                  key={board.id}
                  className="bg-white p-4 rounded-2xl cursor-pointer shadow-lg flex flex-col justify-between"
                  onClick={() => navigate(`/boards/${board.id}`)}
                >
                  <div>
                    <h2 className="text-base font-thin text-black mb-2">
                      {board.name}
                    </h2>
                    <div className="flex flex-wrap">
                      {invitedUsers[board.id]?.map((user) => (
                        <div
                          key={user.id}
                          className={`${
                            user.color || "bg-blue-500"
                          } text-white p-2 rounded-full w-10 h-10 flex items-center justify-center mr-2`}
                        >
                          {getInitials(user.firstName, user.lastName)}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex justify-between mt-4">
                    <FaTrash
                      onClick={(e) => {
                        e.stopPropagation();
                        confirmDeleteBoard(board.id);
                      }}
                      className="text-black cursor-pointer"
                    />
                    <FaRegStar
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleFavorite(board.id, board.favorite);
                      }}
                      className={`cursor-pointer ${
                        board.favorite ? "text-yellow-500" : "text-black"
                      }`}
                    />
                  </div>
                </div>
              ))
          )}
        </div>
      </div>

      {boardToDelete && (
        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          onClose={() => setIsConfirmationModalOpen(false)}
          onConfirm={() => handleDeleteBoard(boardToDelete)}
          message={t("confirmDeleteBoard")}
        />
      )}
    </div>
  );
};

export default Boards;
