import React, { useState, useEffect } from "react";
import { db, auth } from "../../../db/firebase";
import {
  collection,
  getDocs,
  addDoc,
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import GroupModal from "./GroupsModal";
import { useNavigate } from "react-router-dom";
import Card from "../Components/Card";
import AddButtonWithIcon from "../Components/AddButton";
import SuccessButton from "../Components/SuccessButton";
import DangerButton from "../Components/DangerButton";
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

const Groups: React.FC = () => {
  const [groups, setGroups] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [currentGroup, setCurrentGroup] = useState<any | null>(null);
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState<any | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation(); // Initialize translation

  useEffect(() => {
    const fetchUserOrganization = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const orgId = userDoc.data().organizationId;
          const orgDoc = await getDoc(doc(db, "organizations", orgId));
          if (orgDoc.exists()) {
            setOrganizationId(orgDoc.id);
          }
        } else {
          const orgDoc = await getDoc(doc(db, "organizations", user.uid));
          if (orgDoc.exists()) {
            setOrganizationId(orgDoc.id);
          }
        }
      }
    };

    fetchUserOrganization();
  }, []);

  useEffect(() => {
    const fetchGroups = async () => {
      if (organizationId) {
        const groupsSnapshot = await getDocs(collection(db, "groups"));
        const groupsData = groupsSnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter((group) => group.organizationId === organizationId);
        setGroups(groupsData);
        setLoading(false);
      }
    };

    if (organizationId) {
      fetchGroups();
    }
  }, [organizationId]);

  const handleCreate = () => {
    setCurrentGroup(null);
    setShowModal(true);
  };

  const handleEdit = (group: any) => {
    setCurrentGroup(group);
    setShowModal(true);
  };

  const handleDelete = async () => {
    if (groupToDelete) {
      await deleteDoc(doc(db, "groups", groupToDelete.id));
      setGroups(groups.filter((group) => group.id !== groupToDelete.id));
      setShowConfirmDelete(false);
      setGroupToDelete(null);
    }
  };

  const confirmDeleteGroup = (group: any) => {
    setGroupToDelete(group);
    setShowConfirmDelete(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setCurrentGroup(null);
  };

  const handleSave = async (group: any) => {
    if (currentGroup) {
      // Edit existing group
      const groupDocRef = doc(db, "groups", currentGroup.id);
      await updateDoc(groupDocRef, group);
      setGroups(
        groups.map((g) => (g.id === currentGroup.id ? { ...g, ...group } : g))
      );
    } else {
      // Create new group
      const newGroupDocRef = await addDoc(collection(db, "groups"), {
        ...group,
        organizationId,
      });
      setGroups([
        ...groups,
        { id: newGroupDocRef.id, ...group, organizationId },
      ]);
    }
    handleModalClose();
  };

  const handleCardClick = (id: string) => {
    navigate(`/organization/groups/${id}`);
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4 sm:p-6 lg:p-8">
        <div className="bg-white p-8 rounded-2xl shadow-md w-full max-w-md text-center">
          <p className="text-gray-700">{t("loading")}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto">
      <h1 className="text-3xl font-light mb-4 text-gray-700">{t("groups")}</h1>
      <div className="flex justify-between items-center mb-4">
        <AddButtonWithIcon
          onClick={handleCreate}
          text={t("addGroup")}
          icon={<FaPlus />}
          className="custom-class"
        />
      </div>
      {groups.length === 0 ? (
        <p className="text-gray-600">{t("noGroupsAvailable")}</p>
      ) : (
        <ul className="space-y-4">
          {groups.map((group) => (
            <Card
              key={group.id}
              className="bg-gray-100 p-4 rounded-2xl flex flex-col md:flex-row justify-between items-start cursor-pointer"
              onClick={() => handleCardClick(group.id)}
            >
              <div className="flex-1">
                <h2 className="text-xl font-bold text-gray-700">
                  {group.name}
                </h2>
                <p className="text-gray-600">{group.description}</p>
              </div>
              <div className="flex space-x-2 mt-4 md:mt-0">
                <SuccessButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEdit(group);
                  }}
                  text={t("editGroup")}
                  icon={<FaEdit />}
                  className="custom-class"
                />
                <DangerButton
                  onClick={(e) => {
                    e.stopPropagation();
                    confirmDeleteGroup(group);
                  }}
                  text={t("deleteGroup")}
                  icon={<FaTrash />}
                  className="custom-class"
                />
              </div>
            </Card>
          ))}
        </ul>
      )}
      {showModal && (
        <GroupModal
          group={currentGroup}
          onSave={handleSave}
          onClose={handleModalClose}
        />
      )}
      {showConfirmDelete && (
        <ConfirmDeleteModal
          isOpen={showConfirmDelete}
          onClose={() => setShowConfirmDelete(false)}
          onConfirm={handleDelete}
          userName={groupToDelete ? groupToDelete.name : ""}
        />
      )}
    </div>
  );
};

export default Groups;
