import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db, auth } from "../../../db/firebase";
import Divider from "../../../components/Divider";
import AddSongModal from "../Songs/AddSongModal";

const placeholderImage = "path/to/placeholder/image.jpg";

interface SearchAddSongModalProps {
  eventId: string;
  currentSongs: any[];
  onClose: () => void;
}

const SearchAddSongModal: React.FC<SearchAddSongModalProps> = ({
  eventId,
  currentSongs,
  onClose,
}) => {
  const [songSearch, setSongSearch] = useState("");
  const [searchedSongs, setSearchedSongs] = useState<any[]>([]);
  const [favoriteSongs, setFavoriteSongs] = useState<any[]>([]);
  const [noSongsMessage, setNoSongsMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showAddSongModal, setShowAddSongModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchFavoriteSongs = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const orgId = userDoc.data().organizationId;
            if (orgId) {
              const favSongsRef = collection(
                db,
                "organizations",
                orgId,
                "favorite_songs"
              );
              const favSongsSnapshot = await getDocs(favSongsRef);
              const favSongIds = favSongsSnapshot.docs.map((doc) => doc.id);

              if (favSongIds.length === 0) {
                setFavoriteSongs([]);
                return;
              }

              const favSongsQuery = query(
                collection(db, "songs"),
                where("__name__", "in", favSongIds)
              );
              const favSongsSnapshotAll = await getDocs(favSongsQuery);
              const favSongs = favSongsSnapshotAll.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));

              setFavoriteSongs(favSongs);
            }
          }
        } catch (error) {
          console.error("Error fetching favorite songs:", error);
        }
      }
    };

    fetchFavoriteSongs();
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil(searchedSongs.length / itemsPerPage));
  }, [searchedSongs]);

  const handleSearch = async () => {
    setLoading(true);
    const queryText = songSearch.trim().toLowerCase();

    if (queryText === "") {
      setSearchedSongs([]);
      setNoSongsMessage("");
      setLoading(false);
      return;
    }

    try {
      const allSongsSnapshot = await getDocs(collection(db, "songs"));
      const allSongs = allSongsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const filteredSongs = allSongs.filter((song) => {
        const songTitle = song.title.toLowerCase();
        const songArtist = song.artist.toLowerCase();
        return songTitle.includes(queryText) || songArtist.includes(queryText);
      });

      if (filteredSongs.length === 0) {
        setNoSongsMessage("No songs matching that title or artist.");
      } else {
        setSearchedSongs(filteredSongs);
        setNoSongsMessage("");
      }
    } catch (error) {
      console.error("Error searching songs:", error);
      setNoSongsMessage("Error searching songs.");
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    setSongSearch("");
    setSearchedSongs([]);
    setNoSongsMessage("");
  };

  const handleSelectSong = async (song: any) => {
    try {
      const eventDocRef = doc(db, "events", eventId);
      const eventDoc = await getDoc(eventDocRef);

      if (eventDoc.exists()) {
        const eventData = eventDoc.data();
        const updatedSongs = [...(eventData.songs || []), song.id];

        await updateDoc(eventDocRef, { songs: updatedSongs });

        alert(`"${song.title}" has been added to the event.`);
      }
    } catch (error) {
      console.error("Error adding song to event:", error);
      alert("An error occurred while adding the song to the event.");
    }
  };

  const handleRemoveSong = async (songId: string) => {
    try {
      const eventDocRef = doc(db, "events", eventId);
      const eventDoc = await getDoc(eventDocRef);
      if (eventDoc.exists()) {
        const eventData = eventDoc.data();
        const updatedSongs = (eventData.songs || []).filter(
          (id: string) => id !== songId
        );
        await updateDoc(eventDocRef, { songs: updatedSongs });
        setSearchedSongs(searchedSongs.filter((song) => song.id !== songId));
      }
    } catch (error) {
      console.error("Error removing song from event:", error);
    }
  };

  const renderSongs = (songs: any[]) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return songs.slice(startIndex, endIndex).map((song) => {
      const isInEvent = currentSongs.some((s) => s.id === song.id);
      return (
        <li key={song.id} className="mb-4 ">
          <Divider />
          <div className="bg-white  rounded-2xl flex justify-between items-center p-4 ">
            <div className="flex items-center">
              {song.artworkURL ? (
                <img
                  src={song.artworkURL}
                  alt={song.title}
                  className="w-16 h-16 rounded-md mr-4"
                />
              ) : (
                <img
                  src={placeholderImage}
                  alt="Placeholder"
                  className="w-16 h-16 rounded-md mr-4"
                />
              )}
              <div>
                <h3 className="text-lg font-bold text-gray-700">
                  {song.title}
                </h3>
                <p className="text-gray-500">
                  <strong>Artist:</strong> {song.artist}
                </p>
                <p className="text-gray-500">
                  <strong>Album:</strong> {song.album}
                </p>
              </div>
            </div>
            <div className="flex space-x-2">
              {isInEvent ? (
                <button
                  onClick={() => handleRemoveSong(song.id)}
                  className="bg-red-500 text-white py-2 px-4 rounded-2xl hover:bg-red-600 transition-colors"
                >
                  Remove
                </button>
              ) : (
                <button
                  onClick={() => handleSelectSong(song)}
                  className="bg-[#5932EA] bg-opacity-75 text-white text-base py-2 px-4 rounded-2xl hover:bg-[#5932EA] transition-colors flex items-center"
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </li>
      );
    });
  };

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-2xl p-6 w-full max-w-3xl max-h-[80vh] overflow-y-auto">
        <h2 className="text-2xl font-semibold mb-4">Search/Add Songs</h2>
        <div className="flex items-center mb-4">
          <input
            type="text"
            value={songSearch}
            onChange={(e) => setSongSearch(e.target.value)}
            placeholder="Song or artist"
            className="w-full px-4 py-2 border rounded-2xl"
            maxLength={150}
          />
          <button
            onClick={handleSearch}
            className="ml-2 bg-[#5932EA] bg-opacity-75 text-white py-2 px-4 rounded-2xl hover:bg-[#5932EA] transition-colors"
          >
            {loading ? (
              <svg
                className="animate-spin h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                ></path>
              </svg>
            ) : (
              "Search"
            )}
          </button>
          <button
            onClick={handleClear}
            className="ml-2 bg-gray-400 text-white py-2 px-4 rounded-2xl hover:bg-gray-500 transition-colors"
          >
            Clear
          </button>
        </div>
        {noSongsMessage && (
          <div>
            <p className="text-red-500 mb-2">{noSongsMessage}</p>
            <button
              onClick={() => setShowAddSongModal(true)}
              className="bg-[#5932EA] bg-opacity-75 text-white py-2 px-4 rounded-2xl"
            >
              Add New Song
            </button>
          </div>
        )}
        {searchedSongs.length > 0 && (
          <div className="mt-2 max-h-[40vh] overflow-y-auto  -mx-4 p-2 ">
            <ul>{renderSongs(searchedSongs)}</ul>
          </div>
        )}
        <Divider />
        {favoriteSongs.length > 0 && (
          <div className="mt-8">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">
              Favorite Songs
            </h3>
            <div className="max-h-[60vh] overflow-y-auto">
              <ul>{renderSongs(favoriteSongs)}</ul>
            </div>
          </div>
        )}
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`text-gray-500 py-2 px-4 rounded-2xl hover:text-gray-600 transition-colors ${
              currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            Previous
          </button>
          <span className="text-gray-700">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`text-gray-500 py-2 px-4 rounded-2xl hover:text-gray-600 transition-colors ${
              currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            Next
          </button>
        </div>
        <button
          onClick={onClose}
          className="mt-4 bg-red-500 text-white py-2 px-4 rounded-2xl hover:bg-red-600 transition-colors"
        >
          Close
        </button>
      </div>
      {showAddSongModal && (
        <AddSongModal onClose={() => setShowAddSongModal(false)} />
      )}
    </div>
  );
};

export default SearchAddSongModal;
