import React, { useState, useEffect } from "react";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage, auth } from "../../../db/firebase"; // Import auth here
import { useAuthState } from "react-firebase-hooks/auth";
import { ClipLoader } from "react-spinners"; // Import spinner library
import { useTranslation } from "react-i18next"; // Import useTranslation

interface AddSongModalProps {
  onClose: () => void;
  prefillData?: {
    title?: string;
    artist?: string;
    album?: string;
    lyrics?: string;
    youtubeLink?: string;
    charts?: string;
    artworkURL?: string;
  };
}

const AddSongModal: React.FC<AddSongModalProps> = ({
  onClose,
  prefillData,
}) => {
  const { t } = useTranslation(); // Initialize translation
  const [title, setTitle] = useState(prefillData?.title || "");
  const [artist, setArtist] = useState(prefillData?.artist || "");
  const [album, setAlbum] = useState(prefillData?.album || "");
  const [lyrics, setLyrics] = useState(prefillData?.lyrics || "");
  const [youtubeLink, setYoutubeLink] = useState(
    prefillData?.youtubeLink || ""
  );
  const [charts, setCharts] = useState(prefillData?.charts || "");
  const [artwork, setArtwork] = useState<File | null>(null);
  const [artworkURL, setArtworkURL] = useState(prefillData?.artworkURL || "");
  const [uploadError, setUploadError] = useState("");
  const [user] = useAuthState(auth);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (prefillData?.artworkURL) {
      setArtworkURL(prefillData.artworkURL);
    }
  }, [prefillData]);

  const handleAddSong = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true); // Start loading
    let finalArtworkURL = artworkURL;

    try {
      if (artwork) {
        const storageRef = ref(storage, `artworks/${artwork.name}`);
        const uploadTask = uploadBytesResumable(storageRef, artwork);

        await new Promise<void>((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
            },
            (error) => {
              console.error("Error uploading artwork: ", error);
              setUploadError(t("upload_error"));
              reject(error);
            },
            async () => {
              finalArtworkURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve();
            }
          );
        });
      }

      await addDoc(collection(db, "songs"), {
        title,
        artist,
        album,
        lyrics,
        youtubeLink,
        charts,
        artworkURL: finalArtworkURL,
        timestamp: serverTimestamp(),
        userId: user?.uid,
      });
      setSuccessMessage(t("song_saved_success"));
      setTimeout(() => {
        setSuccessMessage("");
        onClose();
      }, 2000);
    } catch (error) {
      console.error("Error adding song: ", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleArtworkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setArtwork(e.target.files[0]);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white p-6 rounded-2xl shadow-lg w-[800px] max-w-md max-h-[90vh] overflow-y-auto">
        <h2 className="text-xl font-bold mb-4">{t("add_new_song")}</h2>
        <form onSubmit={handleAddSong} className="space-y-4">
          <div>
            <label htmlFor="title" className="block text-gray-600 mb-2">
              {t("title")}
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
              maxLength={100}
            />
          </div>
          <div>
            <label htmlFor="artist" className="block text-gray-600 mb-2">
              {t("artist")}
            </label>
            <input
              type="text"
              id="artist"
              value={artist}
              onChange={(e) => setArtist(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
              maxLength={100}
            />
          </div>
          <div>
            <label htmlFor="album" className="block text-gray-600 mb-2">
              {t("album")}
            </label>
            <input
              type="text"
              id="album"
              value={album}
              onChange={(e) => setAlbum(e.target.value)}
              className="w-full px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
              maxLength={100}
            />
          </div>
          <div>
            <label htmlFor="lyrics" className="block text-gray-600 mb-2">
              {t("lyrics")}
            </label>
            <textarea
              id="lyrics"
              value={lyrics}
              onChange={(e) => setLyrics(e.target.value)}
              className="w-full px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <div>
            <label htmlFor="youtubeLink" className="block text-gray-600 mb-2">
              {t("youtube_link")}
            </label>
            <input
              type="url"
              id="youtubeLink"
              value={youtubeLink}
              onChange={(e) => setYoutubeLink(e.target.value)}
              className="w-full px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
              maxLength={1000}
            />
          </div>
          <div>
            <label htmlFor="charts" className="block text-gray-600 mb-2">
              {t("charts_link")}
            </label>
            <input
              type="url"
              id="charts"
              value={charts}
              onChange={(e) => setCharts(e.target.value)}
              className="w-full px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
              maxLength={1000}
            />
          </div>
          <div>
            <label htmlFor="artwork" className="block text-gray-600 mb-2">
              {t("artwork")}
            </label>
            <input
              type="file"
              id="artwork"
              onChange={handleArtworkChange}
              className="w-full px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
            {uploadError && <p className="text-red-500 mt-2">{uploadError}</p>}
            {artworkURL && !artwork && (
              <img
                src={artworkURL}
                alt={t("artwork")}
                className="w-20 h-20 object-cover mt-2"
              />
            )}
          </div>
          {loading && (
            <div className="flex justify-center items-center">
              <ClipLoader size={35} color="#5932EA" />
            </div>
          )}
          {successMessage && (
            <p className="text-green-500 mt-2 text-center">{successMessage}</p>
          )}
          <div className="flex flex-col ">
            <button
              type="submit"
              className="bg-[#5932EA] bg-opacity-75 text-white px-4 py-2 rounded-2xl hover:bg-[#5932EA] transition-colors mb-4"
              disabled={loading}
            >
              {t("save")}
            </button>
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 text-white px-4 py-2 rounded-2xl hover:bg-gray-600 transition-colors "
              disabled={loading}
            >
              {t("cancel")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddSongModal;
