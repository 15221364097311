import React, { useState } from "react";
import { auth, db } from "../../src/db/firebase"; // Adjust the import path if necessary
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom"; // Import useNavigate and Link
import { doc, getDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next"; // Import useTranslation

const Login: React.FC = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      const userDoc = await getDoc(doc(db, "users", user.uid));

      if (userDoc.exists() && userDoc.data().approved) {
        navigate("/user/dashboard"); // Redirect to UserDashboard after successful login
      } else {
        await signOut(auth); // Sign out the user if not approved
        setError(t("accountNotApproved"));
      }
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-500 to-purple-600 p-4 sm:p-6 lg:p-8">
      <div className="bg-white p-8 rounded-2xl shadow-md w-full max-w-md -mt-[100px]">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-700">
          {t("login")}
        </h2>
        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-gray-600 mb-2">
              {t("email")}
            </label>
            <input
              maxLength={100}
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t("email")}
              required
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-gray-600 mb-2">
              {t("password")}
            </label>
            <input
              maxLength={100}
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t("password")}
              required
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-[#5932EA] bg-opacity-75 text-white py-2 rounded-lg hover:bg-[#5932EA] transition-colors"
          >
            {t("loginButton")}
          </button>
        </form>
        {error && <p className="mt-4 text-red-500 text-center">{error}</p>}
        <p className="mt-6 text-gray-600 text-center">
          <Link to="/login-admin" className="text-[#5932EA] hover:underline">
            {t("loginAsAdmin")}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
