// utils/fetchFunctions.ts
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { assignColors } from "./assignColors";
import { db, auth } from "../../../../db/firebase";

export const fetchUserOrganization = async (
  setOrganizationId: React.Dispatch<React.SetStateAction<string | null>>
) => {
  const user = auth.currentUser;
  if (user) {
    const userDoc = await getDoc(doc(db, "users", user.uid));
    if (userDoc.exists()) {
      const orgId = userDoc.data().organizationId;
      const orgDoc = await getDoc(doc(db, "organizations", orgId));
      if (orgDoc.exists()) {
        setOrganizationId(orgDoc.id);
      }
    } else {
      const orgDoc = await getDoc(doc(db, "organizations", user.uid));
      if (orgDoc.exists()) {
        setOrganizationId(orgDoc.id);
      }
    }
  }
};

export const fetchLeaders = async (
  leaderIds: string[],
  setLeaders: React.Dispatch<React.SetStateAction<any[]>>,
  setLeaderColors: React.Dispatch<React.SetStateAction<Record<string, string>>>
) => {
  const leaderPromises = leaderIds.map(async (leaderId) => {
    const userDoc = await getDoc(doc(db, "users", leaderId));
    if (userDoc.exists()) {
      return { id: userDoc.id, ...userDoc.data() };
    }
    return null;
  });
  const leaderData = await Promise.all(leaderPromises);
  const validLeaders = leaderData.filter((leader) => leader !== null);
  setLeaders(validLeaders);
  setLeaderColors(assignColors(validLeaders));
};

export const fetchMembers = async (
  memberIds: string[],
  setMembers: React.Dispatch<React.SetStateAction<any[]>>,
  setMemberColors: React.Dispatch<React.SetStateAction<Record<string, string>>>
) => {
  const memberPromises = memberIds.map(async (memberId) => {
    const userDoc = await getDoc(doc(db, "users", memberId));
    if (userDoc.exists()) {
      return { id: userDoc.id, ...userDoc.data() };
    }
    return null;
  });
  const memberData = await Promise.all(memberPromises);
  const validMembers = memberData.filter((member) => member !== null);
  setMembers(validMembers);
  setMemberColors(assignColors(validMembers));
};

export const fetchSongs = async (
  songIds: string[],
  setSongs: React.Dispatch<React.SetStateAction<any[]>>
) => {
  const songPromises = songIds.map(async (songId) => {
    const songDoc = await getDoc(doc(db, "songs", songId));
    if (songDoc.exists()) {
      const songData = songDoc.data();
      return {
        id: songDoc.id,
        title: songData.title,
        artist: songData.artist,
        album: songData.album,
        artworkURL: songData.artworkURL,
        youtubeURL: songData.youtubeURL,
        favorite: songData.favorite,
      };
    }
    return null;
  });
  const songData = await Promise.all(songPromises);
  setSongs(songData.filter((song) => song !== null));
};

export const fetchEvent = (
  id: string | undefined,
  organizationId: string | null,
  setEvent: React.Dispatch<React.SetStateAction<any | null>>,
  setLeaders: React.Dispatch<React.SetStateAction<any[]>>,
  setLeaderColors: React.Dispatch<React.SetStateAction<Record<string, string>>>,
  setMembers: React.Dispatch<React.SetStateAction<any[]>>,
  setMemberColors: React.Dispatch<React.SetStateAction<Record<string, string>>>,
  setSongs: React.Dispatch<React.SetStateAction<any[]>>,
  setIsCurrentPlaylist: React.Dispatch<React.SetStateAction<boolean>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (organizationId) {
    const eventDocRef = doc(db, "events", id!);

    const unsubscribe = onSnapshot(eventDocRef, async (eventDoc) => {
      if (
        eventDoc.exists() &&
        eventDoc.data().organizationId === organizationId
      ) {
        const eventData = eventDoc.data();
        setEvent(eventData);
        await fetchLeaders(
          eventData.leaders || [],
          setLeaders,
          setLeaderColors
        );
        await fetchMembers(
          eventData.members || [],
          setMembers,
          setMemberColors
        );
        await fetchSongs(eventData.songs || [], setSongs);
        setIsCurrentPlaylist(eventData.currentPlayList || false); // Set current playlist status
        setLoading(false);
      } else {
        console.error("Event does not exist or user does not have permission.");
        setLoading(false);
      }
    });

    return unsubscribe;
  }
};
