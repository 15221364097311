import React from "react";
import { FaRegEye, FaTrashAlt } from "react-icons/fa";
import { MdDragIndicator } from "react-icons/md"; // Import the drag indicator icon
import { useNavigate } from "react-router-dom";
import Divider from "../../../../components/Divider";
import { useTranslation } from "react-i18next";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const ItemTypes = {
  SONG: "song",
};

interface Song {
  id: string;
  title: string;
  artist: string;
  album: string;
  artworkURL?: string;
  youtubeURL?: string;
  favorite?: boolean;
}

interface SongListProps {
  songs: Song[];
  placeholderImage: string;
  handleMoveSong: (dragIndex: number, hoverIndex: number) => void;
  handleRemoveSong: (songId: string) => void;
  handleAddSong: () => void;
  settingPlaylist: boolean;
  isCurrentPlaylist: boolean;
  handleSetPlaylist: () => void;
}

const SongList: React.FC<SongListProps> = ({
  songs,
  placeholderImage,
  handleMoveSong,
  handleRemoveSong,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const SongItem: React.FC<{
    song: Song;
    index: number;
    moveItem: (dragIndex: number, hoverIndex: number) => void;
  }> = ({ song, index, moveItem }) => {
    const ref = React.useRef<HTMLLIElement>(null);
    const [, drop] = useDrop({
      accept: ItemTypes.SONG,
      hover(draggedItem: { index: number }) {
        if (!ref.current) {
          return;
        }
        const dragIndex = draggedItem.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        moveItem(dragIndex, hoverIndex);
        draggedItem.index = hoverIndex;
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.SONG,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    drag(drop(ref));

    return (
      <li
        ref={ref}
        className={`mb-4 ${isDragging ? "opacity-50" : "opacity-100"} flex`}
      >
        <div className="bg-white p-4 rounded-2xl flex flex-col md:flex-row justify-between items-start w-full">
          <div className="flex items-center mb-4 md:mb-0 ">
            {song.artworkURL ? (
              <img
                src={song.artworkURL}
                alt={song.title}
                className="w-20 h-20 rounded-md mr-4"
              />
            ) : (
              <img
                src={placeholderImage}
                alt="Placeholder"
                className="w-20 h-20 rounded-md mr-4"
              />
            )}
            <div>
              <h3 className="text-lg font-bold text-gray-700">{song.title}</h3>
              <p className="text-gray-500">
                <strong>{t("artist")}:</strong> {song.artist}
              </p>
              <p className="text-gray-500">
                <strong>{t("album")}:</strong> {song.album}
              </p>
            </div>
          </div>

          <div className="flex  item-center justify-between h-full w-[100px]">
            <button
              onClick={() => navigate(`/organization/songs/${song.id}/view`)}
              className="text-gray-400 hover:text-[#5932EA] transition-colors"
            >
              <FaRegEye size={20} />
            </button>
            <button
              onClick={() => handleRemoveSong(song.id)}
              className="text-gray-400 hover:text-red-600 transition-colors"
            >
              <FaTrashAlt size={20} />
            </button>
            <div className="mr-4 flex items-center">
              <MdDragIndicator
                size={20}
                className="text-gray-400 cursor-move"
              />
            </div>
          </div>
        </div>
        <Divider />
      </li>
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="bg-white p-2 px-3 rounded-2xl">
        <h2 className="text-2xl font-semibold text-gray-700 mt-2">
          {t("songs")}
        </h2>
        <ul className="mt-4">
          {songs.map((song, index) => (
            <SongItem
              key={song.id}
              index={index}
              song={song}
              moveItem={handleMoveSong}
            />
          ))}
        </ul>
      </div>
    </DndProvider>
  );
};

export default SongList;
