import React, { useEffect, useState } from "react";
import { auth, db, storage } from "../../db/firebase";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { FaUserCircle } from "react-icons/fa";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { MdEdit } from "react-icons/md";
import { useTranslation } from "react-i18next";

const Profile: React.FC = () => {
  const { t } = useTranslation();
  const [user] = useAuthState(auth);
  const [userData, setUserData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            setUserData(userDoc.data());
          } else {
            setError(t("userDataNotFound"));
          }
        } catch (err) {
          setError(t("failedToFetchUserData"));
          console.error(err);
        }
        setLoading(false);
      }
    };

    fetchUserData();
  }, [user, t]);

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const storageRef = ref(storage, `profilePictures/${user?.uid}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    setUploading(true);

    uploadTask.on(
      "state_changed",
      () => {},
      (error) => {
        console.error(t("uploadFailed"), error);
        setUploading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        await updateDoc(doc(db, "users", user!.uid), {
          profilePicture: downloadURL,
        });
        setUserData((prevData: any) => ({
          ...prevData,
          profilePicture: downloadURL,
        }));
        setUploading(false);
      }
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-2xl font-semibold text-gray-700">
          {t("loading")}...
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-2xl font-semibold text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6 flex justify-center">
      <div className="bg-white p-8 rounded-2xl shadow-lg w-full max-w-lg">
        <div className="relative flex justify-center mb-6">
          <div className="relative inline-block">
            {userData?.profilePicture ? (
              <img
                src={userData.profilePicture}
                alt={t("profilePictureAlt")}
                className="w-24 h-24 rounded-full object-cover"
              />
            ) : (
              <FaUserCircle className="text-6xl text-gray-400" />
            )}
            <label
              htmlFor="profilePicture"
              className="absolute bottom-0 right-0 transform translate-x-1/2 translate-y-1/2 h-12 w-12"
            >
              <MdEdit className="text-gray-600 bg-white rounded-full p-1 w-8 h-8 hover:text-[#5932EA] hover:bg-gray-100 cursor-pointer" />
              <input
                type="file"
                id="profilePicture"
                accept="image/*"
                onChange={handleImageUpload}
                className="hidden"
              />
            </label>
          </div>
        </div>
        <h1 className="text-4xl font-bold mb-8 text-center text-gray-700">
          {t("profile")}
        </h1>
        {userData && (
          <div className="space-y-6">
            <p className="text-xl">
              <strong className="font-semibold text-gray-600">
                {t("name")}:
              </strong>{" "}
              {userData.firstName} {userData.lastName}
            </p>
            <p className="text-xl">
              <strong className="font-semibold text-gray-600">
                {t("email")}:
              </strong>{" "}
              {userData.email}
            </p>
            <p className="text-xl">
              <strong className="font-semibold text-gray-600">
                {t("role")}:
              </strong>{" "}
              {userData.role}
            </p>
            <p className="text-xl">
              <strong className="font-semibold text-gray-600">
                {t("organization")}:
              </strong>{" "}
              {userData.organizationName}
            </p>
            {uploading && (
              <p className="text-sm text-gray-600 mt-2">{t("uploading")}...</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
