import React, { useState, useEffect } from "react";
import { db } from "../../src/db/firebase";
import {
  collection,
  getDocs,
  addDoc,
  query,
  where,
  deleteDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import UserInfoModal from "../components/UserInfoModal";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next"; // Import useTranslation

interface InviteUserProps {
  boardId: string;
  isOpen: boolean;
  onClose: () => void;
}

interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  color?: string;
}

const InviteUserModal: React.FC<InviteUserProps> = ({
  boardId,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation(); // Initialize translation
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [existingUserIds, setExistingUserIds] = useState<string[]>([]);
  const [members, setMembers] = useState<User[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isUserInfoModalOpen, setIsUserInfoModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, "users");
        const q = query(usersCollection, where("email", "!=", null));
        const querySnapshot = await getDocs(q);
        const usersData = querySnapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as User)
        );
        setUsers(usersData);
      } catch (error) {
        console.error(t("errorFetchingUsers"), error);
      }
    };

    const fetchExistingMemberships = async () => {
      try {
        const membershipsCollection = collection(db, "memberships");
        const membershipsQuery = query(
          membershipsCollection,
          where("boardId", "==", boardId)
        );
        const membershipsSnapshot = await getDocs(membershipsQuery);
        const existingUserIdsData = membershipsSnapshot.docs.map(
          (doc) => doc.data().userId
        );

        const userPromises = membershipsSnapshot.docs.map(async (doc) => {
          const membershipData = doc.data();
          const userDoc = await getDocs(
            query(
              collection(db, "users"),
              where("__name__", "==", membershipData.userId)
            )
          );
          if (userDoc.docs.length > 0) {
            const userData = userDoc.docs[0].data() as User;
            return {
              id: membershipData.userId,
              firstName: userData.firstName,
              lastName: userData.lastName,
              email: userData.email,
              color: membershipData.color, // Add color from the membership data
            };
          }
          return null;
        });

        const usersData = (await Promise.all(userPromises)).filter(
          (user) => user !== null
        ) as User[];
        setMembers(usersData);
        setExistingUserIds(existingUserIdsData);
      } catch (error) {
        console.error(t("errorFetchingMemberships"), error);
      }
    };

    if (isOpen) {
      fetchUsers();
      fetchExistingMemberships();
    }
  }, [isOpen, boardId, t]);

  const handleInvite = async (user: User) => {
    if (existingUserIds.includes(user.id)) {
      setErrorMessage(t("userAlreadyMember"));
      return;
    }

    const color = getRandomColor();

    try {
      await addDoc(collection(db, "memberships"), {
        boardId,
        userId: user.id,
        role: "member",
        color, // Add the color to the membership document
      });
      setMembers([...members, { ...user, color }]);
      setExistingUserIds([...existingUserIds, user.id]);
    } catch (error) {
      console.error(t("errorInvitingUser"), error);
    }
  };

  const getInitials = (firstName: string, lastName: string) => {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  };

  const getRandomColor = () => {
    const colors = [
      "bg-red-500",
      "bg-green-500",
      "bg-blue-500",
      "bg-yellow-500",
      "bg-purple-500",
      "bg-pink-500",
      "bg-indigo-500",
      "bg-teal-500",
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const handleRemoveUser = async (userId: string) => {
    try {
      // Remove user from memberships collection
      const membershipsCollection = collection(db, "memberships");
      const membershipsQuery = query(
        membershipsCollection,
        where("boardId", "==", boardId),
        where("userId", "==", userId)
      );
      const membershipsSnapshot = await getDocs(membershipsQuery);
      membershipsSnapshot.docs.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });

      // Remove user from assignedMembers in all cards
      const listsCollection = collection(db, "boards", boardId, "lists");
      const listsSnapshot = await getDocs(listsCollection);
      const listPromises = listsSnapshot.docs.map(async (listDoc) => {
        const cardsCollection = collection(
          db,
          "boards",
          boardId,
          "lists",
          listDoc.id,
          "cards"
        );
        const cardsSnapshot = await getDocs(cardsCollection);
        const cardPromises = cardsSnapshot.docs.map(async (cardDoc) => {
          const cardData = cardDoc.data();
          if (
            cardData.assignedMembers &&
            cardData.assignedMembers.includes(userId)
          ) {
            const updatedMembers = cardData.assignedMembers.filter(
              (id: string) => id !== userId
            );
            await updateDoc(
              doc(
                db,
                "boards",
                boardId,
                "lists",
                listDoc.id,
                "cards",
                cardDoc.id
              ),
              {
                assignedMembers: updatedMembers,
              }
            );
          }
        });
        await Promise.all(cardPromises);
      });
      await Promise.all(listPromises);

      setMembers(members.filter((member) => member.id !== userId));
      setExistingUserIds(existingUserIds.filter((id) => id !== userId));
    } catch (error) {
      console.error(t("errorRemovingUser"), error);
    }
  };

  return isOpen ? (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="relative bg-white p-4 rounded-2xl w-full mx-4">
        <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
          <button
            onClick={onClose}
            className="bg-gray-200 text-gray-700 rounded-full p-1 flex items-center justify-center"
          >
            <IoClose className="h-6 w-6" />
          </button>
        </div>
        <h2 className="text-xl font-bold mb-4">{t("addNewMember")}</h2>
        <div className="mb-4 flex flex-wrap gap-2">
          {members.map((member) => (
            <div
              key={member.id}
              className={`text-white p-2 rounded-full w-8 h-8 flex items-center justify-center ${
                member.color || getRandomColor()
              } relative group`}
              onClick={() => {
                setSelectedUser(member);
                setIsUserInfoModalOpen(true);
              }}
            >
              {getInitials(member.firstName, member.lastName)}
              <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-700 text-white text-xs rounded-md opacity-0 group-hover:opacity-100 transition-opacity">
                {member.firstName} {member.lastName}
              </div>
            </div>
          ))}
        </div>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder={t("searchByEmail")}
          className="border p-2 w-full mb-4"
        />
        <div className="mb-4 overflow-y-auto">
          {users
            .filter((user) => user.email === searchQuery)
            .map((user) => (
              <li
                key={user.id}
                className={`cursor-pointer flex items-center justify-between ${
                  existingUserIds.includes(user.id) ? "text-gray-500" : ""
                }`}
              >
                <div className="flex items-center">
                  <div className="bg-blue-500 text-white p-2 rounded-full w-8 h-8 flex items-center justify-center mr-2">
                    {getInitials(user.firstName, user.lastName)}
                  </div>
                  {user.firstName} {user.lastName}{" "}
                  {existingUserIds.includes(user.id) && `(${t("member")})`}
                </div>
                {!existingUserIds.includes(user.id) && (
                  <div className="flex flex-col">
                    <button
                      onClick={() => handleInvite(user)}
                      className="bg-blue-500 w-[70px] text-white p-1 rounded-md items-center"
                    >
                      {t("invite")}
                    </button>
                  </div>
                )}
              </li>
            ))}
        </div>
        {errorMessage && (
          <div className="text-red-500 mb-4">{errorMessage}</div>
        )}
      </div>
      {selectedUser && (
        <UserInfoModal
          user={selectedUser}
          isOpen={isUserInfoModalOpen}
          onClose={() => setIsUserInfoModalOpen(false)}
          onRemoveUser={handleRemoveUser}
        />
      )}
    </div>
  ) : null;
};

export default InviteUserModal;
