import React, { useEffect, useState, useRef } from "react";
import { auth, db } from "../db/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getDoc, doc } from "firebase/firestore";
import { signOut } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import { IoMenuSharp } from "react-icons/io5";
import {
  FaHome,
  FaClipboardList,
  FaMusic,
  FaSignOutAlt,
  FaUser,
  FaDatabase,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Nav: React.FC = () => {
  const [user] = useAuthState(auth);
  const [userName, setUserName] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserName(`${userData.firstName} ${userData.lastName}`);
        } else {
          const orgDoc = await getDoc(doc(db, "organizations", user.uid));
          if (orgDoc.exists()) {
            const orgData = orgDoc.data();
            setUserName(orgData.orgName);
          }
        }
      }
    };
    fetchUserData();
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setSidebarOpen(false);
      }
    };

    if (sidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarOpen]);

  const handleLogout = async () => {
    await signOut(auth);
    navigate("/login");
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <nav className="flex justify-between items-center p-4 bg-gradient-to-r from-blue-500 to-purple-600 text-white shadow-md">
      <div className="cursor-pointer" onClick={() => navigate("/")}>
        <h2 className="text-2xl font-bold">praisify.io</h2>
      </div>
      <div className="flex items-center">
        {user ? (
          <>
            <span className="mr-2">{userName}</span>
            <IoMenuSharp
              onClick={toggleSidebar}
              className="text-white h-6 w-6 cursor-pointer"
            />
          </>
        ) : (
          <>
            <button
              onClick={() => navigate("/login")}
              className="bg-[#5932EA] p-2 rounded-lg text-white hover:bg-[#5932EA] transition-colors"
            >
              {t("login")}
            </button>
          </>
        )}
      </div>

      <div
        ref={sidebarRef}
        className={`fixed inset-y-0 left-0 transform ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out w-64 h-full text-gray-600 bg-white z-50`}
      >
        <button
          onClick={toggleSidebar}
          className="absolute top-4 right-4 text-2xl"
        >
          &times;
        </button>
        <div className="p-6">
          <ul className="mt-6">
            <Link
              to="/user/dashboard"
              className="text-lg hover:transition-opacity hover:text-[#5932EA]"
              onClick={toggleSidebar}
            >
              <li className="my-4 flex items-center hover:text-[#5932EA] hover:bg-[#5932EA] hover:bg-opacity-10 hover:rounded-xl py-2">
                <FaHome className="mx-3 hover:text-[#5932EA]" />
                {t("home")}
              </li>
            </Link>
            <Link
              to="/boards"
              className="text-lg hover:transition-opacity hover:text-[#5932EA]"
              onClick={toggleSidebar}
            >
              <li className="my-4 flex items-center hover:text-[#5932EA] hover:bg-[#5932EA] hover:bg-opacity-10 hover:rounded-xl py-2">
                <FaClipboardList className="mx-3 hover:text-[#5932EA]" />
                {t("boards")}
              </li>
            </Link>
            <Link
              to="/songs"
              className="text-lg hover:transition-opacity hover:text-[#5932EA]"
              onClick={toggleSidebar}
            >
              <li className="my-4 flex items-center hover:text-[#5932EA] hover:bg-[#5932EA] hover:bg-opacity-10 hover:rounded-xl py-2">
                <FaMusic className="mx-3 hover:text-[#5932EA]" />
                {t("songImporter")}
              </li>
            </Link>
            <Link
              to="/song-database"
              className="text-lg hover:transition-opacity hover:text-[#5932EA]"
              onClick={toggleSidebar}
            >
              <li className="my-4 flex items-center hover:text-[#5932EA] hover:bg-[#5932EA] hover:bg-opacity-10 hover:rounded-xl py-2">
                <FaDatabase className="mx-3 hover:text-[#5932EA]" />
                {t("songDatabase")}
              </li>
            </Link>
            <Link
              to="/profile"
              className="text-lg hover:transition-opacity hover:text-[#5932EA]"
              onClick={toggleSidebar}
            >
              <li className="my-4 flex items-center hover:text-[#5932EA] hover:bg-[#5932EA] hover:bg-opacity-10 hover:rounded-xl py-2">
                <FaUser className="mx-3 hover:text-[#5932EA]" />
                {t("profile")}
              </li>
            </Link>
            <li
              className="my-4 flex items-center hover:text-[#5932EA] hover:bg-[#5932EA] hover:bg-opacity-10 hover:rounded-xl py-2"
              onClick={handleLogout}
            >
              <FaSignOutAlt className="mx-3 hover:text-[#5932EA]" />
              <button className="text-lg hover:transition-opacity hover:text-[#5932EA]">
                {t("logout")}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
