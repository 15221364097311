import React from "react";
import { FaPlus } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook

interface PeopleListProps {
  title: string;
  people: any[];
  colors: Record<string, string>;
  onAdd: () => void;
}

const PeopleList: React.FC<PeopleListProps> = ({
  title,
  people,
  colors,
  onAdd,
}) => {
  const { t } = useTranslation(); // Initialize translation

  const getInitials = (firstName: string, lastName: string) => {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  };

  return (
    <div className="w-full md:mx-2 bg-white p-4 rounded-2xl  my-4">
      <div className="flex justify-between items-center mb-4 ">
        <h3 className="text-lg font-semibold text-gray-600">{title}</h3>
      </div>
      {people.length > 0 ? (
        <ul className="flex flex-wrap">
          {people.map((person) => (
            <li
              key={person.id}
              className="flex items-center mb-4 mr-4"
              data-tip={`${person.firstName} ${person.lastName}`}
            >
              <div
                className={`flex items-center justify-center w-10 h-10 ${
                  colors[person.id]
                } rounded-full mr-3`}
              >
                {person.imageURL ? (
                  <img
                    src={person.imageURL}
                    alt={`${person.firstName} ${person.lastName}`}
                    className="rounded-full w-10 h-10 object-cover"
                  />
                ) : (
                  <span className="text-lg font-semibold text-white">
                    {getInitials(person.firstName, person.lastName)}
                  </span>
                )}
              </div>
              <Tooltip />
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-600">
          {t("noPeopleAssigned", { title: title.toLowerCase() })}
        </p>
      )}

      <button
        onClick={onAdd}
        className="text-[#5932EA] py-2 px-4 rounded-full hover:text-[#5932EA] transition-colors flex items-center"
      >
        <FaPlus className="mr-2" /> {t("add", { title })}
      </button>
    </div>
  );
};

export default PeopleList;
