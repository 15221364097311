import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../db/firebase";
import { useTranslation } from "react-i18next"; // Import useTranslation

interface AddSongToEventModalProps {
  song: any;
  onClose: () => void;
}

const AddSongToEventModal: React.FC<AddSongToEventModalProps> = ({
  song,
  onClose,
}) => {
  const { t } = useTranslation(); // Initialize translation
  const [events, setEvents] = useState<any[]>([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchWorshipEvents = async () => {
      const eventsQuery = query(
        collection(db, "events"),
        where("type", "==", "Worship")
      );
      const eventsSnapshot = await getDocs(eventsQuery);
      const eventsData = eventsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEvents(eventsData);
    };

    fetchWorshipEvents();
  }, []);

  const handleSave = async () => {
    if (!selectedEvent) {
      alert(t("select_event_alert"));
      return;
    }

    setLoading(true);
    setErrorMessage("");

    try {
      const event = events.find((event) => event.id === selectedEvent);
      const eventDocRef = doc(db, "events", selectedEvent);

      if (event.songs && event.songs.includes(song.id)) {
        setErrorMessage(t("song_already_in_event", { title: song.title }));
        setLoading(false);
        return;
      }

      await updateDoc(eventDocRef, {
        songs: [...(event.songs || []), song.id],
      });

      alert(t("song_added_to_event", { title: song.title }));
      onClose();
    } catch (error) {
      console.error("Error adding song to event: ", error);
      alert(t("error_adding_song"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white p-6 rounded-2xl shadow-lg w-full max-w-md">
        <h2 className="text-xl font-bold mb-4">
          {t("add_song_to_event", { title: song.title })}
        </h2>
        <select
          value={selectedEvent}
          onChange={(e) => setSelectedEvent(e.target.value)}
          className="w-full px-3 py-2 border rounded mb-4"
        >
          <option value="">{t("select_event")}</option>
          {events.map((event) => (
            <option key={event.id} value={event.id}>
              {t("date")}:{" "}
              {new Date(`${event.date}T00:00:00`).toLocaleDateString()}
            </option>
          ))}
        </select>
        <div className="flex flex-col">
          <button
            type="button"
            onClick={handleSave}
            className="bg-[#5932EA] bg-opacity-75 text-white px-4 py-2 rounded-2xl hover:bg-[#5932EA] transition-colors mb-4"
            disabled={loading}
          >
            {loading ? t("saving") : t("save")}
          </button>
          <button
            type="button"
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded-2xl hover:bg-gray-600 transition-colors"
            disabled={loading}
          >
            {t("cancel")}
          </button>
          {errorMessage && (
            <p className="mt-4 text-red-500 text-center">{errorMessage}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddSongToEventModal;
