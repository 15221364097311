import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { db, storage } from "../../src/db/firebase";
import {
  doc,
  getDocs,
  collection,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { IoMdArrowBack } from "react-icons/io";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { FaCheck } from "react-icons/fa";

interface Comment {
  id: string;
  userName: string;
  comment: string;
  timestamp: string;
  attachmentUrl?: string;
  attachmentType?: string;
  attachmentName?: string;
}

interface User {
  id: string;
  firstName: string;
  lastName: string;
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    newDescription: string,
    newComments: Comment[],
    assignedMembers: string[]
  ) => void;
  description: string;
  comments: Comment[];
  userName: string;
  cardId: string;
  boardId: string;
  listId: string;
  userDetails: User | null;
  assignedMembers: string[];
  onAddMemberClick: () => void;
  cardName: string;
  triggerUpdate: boolean;
  onCardNameUpdate: (cardId: string, newCardName: string) => void;
}

const MAX_FILE_SIZE_MB = 5;

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  description: initialDescription,
  comments: initialComments,
  userName,
  cardId,
  boardId,
  listId,
  userDetails,
  assignedMembers: initialAssignedMembers,
  onAddMemberClick,
  cardName: initialCardName,
  triggerUpdate,
  onCardNameUpdate,
}) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState<string>(initialDescription);
  const [newComment, setNewComment] = useState<string>("");
  const [comments, setComments] = useState<Comment[]>(
    initialComments.reverse()
  );
  const [assignedMembers, setAssignedMembers] = useState<string[]>(
    initialAssignedMembers
  );
  const [allMembers, setAllMembers] = useState<User[]>([]);
  const [editingComment, setEditingComment] = useState<string | null>(null);
  const [editCommentText, setEditCommentText] = useState<string>("");
  const [attachment, setAttachment] = useState<File | null>(null);
  const [attachmentError, setAttachmentError] = useState<string>("");

  const [isEditingCardName, setIsEditingCardName] = useState<boolean>(false);
  const [tempCardName, setTempCardName] = useState<string>(initialCardName);

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      ["link"],
      ["clean"],
    ],
  };

  useEffect(() => {
    const fetchAllMembers = async () => {
      try {
        const usersSnapshot = await getDocs(collection(db, "users"));
        const usersData = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
        }));
        setAllMembers(usersData);
      } catch (error) {
        console.error(t("fetchMembersError"), error);
      }
    };

    fetchAllMembers();
  }, [t]);

  useEffect(() => {
    const fetchCardDetails = async () => {
      try {
        const cardRef = doc(
          db,
          "boards",
          boardId,
          "lists",
          listId,
          "cards",
          cardId
        );
        const cardDoc = await getDoc(cardRef);
        if (cardDoc.exists()) {
          const cardData = cardDoc.data();
          setDescription(cardData.description);
          setComments(cardData.comments.reverse());
          setAssignedMembers(cardData.assignedMembers);
          setTempCardName(cardData.name); // Initialize with fetched card name
        }
      } catch (error) {
        console.error(t("fetchCardDetailsError"), error);
      }
    };

    if (isOpen) {
      fetchCardDetails();
    }
  }, [isOpen, boardId, listId, cardId, t]);

  useEffect(() => {
    if (triggerUpdate) {
      const fetchCardDetails = async () => {
        try {
          const cardRef = doc(
            db,
            "boards",
            boardId,
            "lists",
            listId,
            "cards",
            cardId
          );
          const cardDoc = await getDoc(cardRef);
          if (cardDoc.exists()) {
            const cardData = cardDoc.data();
            setAssignedMembers(cardData.assignedMembers);
          }
        } catch (error) {
          console.error(t("fetchCardDetailsError"), error);
        }
      };

      fetchCardDetails();
    }
  }, [triggerUpdate, boardId, listId, cardId, t]);

  const saveToDatabase = async (
    updatedDescription: string,
    updatedComments: Comment[],
    updatedAssignedMembers: string[]
  ) => {
    const cardRef = doc(
      db,
      "boards",
      boardId,
      "lists",
      listId,
      "cards",
      cardId
    );
    try {
      await updateDoc(cardRef, {
        description: updatedDescription,
        comments: updatedComments,
        assignedMembers: updatedAssignedMembers,
      });
    } catch (error) {
      console.error(t("saveError"), error);
    }
  };

  const saveCardNameToDatabase = async (newCardName: string) => {
    const cardRef = doc(
      db,
      "boards",
      boardId,
      "lists",
      listId,
      "cards",
      cardId
    );
    try {
      await updateDoc(cardRef, {
        name: newCardName,
      });
      setIsEditingCardName(false); // Exit edit mode after saving
      onCardNameUpdate(cardId, newCardName); // Update the name in the parent component
    } catch (error) {
      console.error(t("saveError"), error);
    }
  };

  const handleAddComment = async () => {
    saveToDatabase(description, comments, assignedMembers);

    if (newComment.trim() !== "" || attachment) {
      let attachmentUrl = "";
      let attachmentType = "";
      let attachmentName = "";

      if (attachment) {
        if (attachment.size / (1024 * 1024) > MAX_FILE_SIZE_MB) {
          setAttachmentError(t("fileSizeError", { size: MAX_FILE_SIZE_MB }));
          return;
        }

        const storageRef = ref(storage, `attachments/${uuidv4()}`);
        await uploadBytes(storageRef, attachment);
        attachmentUrl = await getDownloadURL(storageRef);
        attachmentType = attachment.type.split("/")[0];
        attachmentName = attachment.name;
        setAttachment(null);
        setAttachmentError("");
      }

      const newCommentObject: Comment = {
        id: uuidv4(),
        userName: `${userDetails?.firstName} ${userDetails?.lastName}`,
        comment: newComment,
        timestamp: new Date().toISOString(),
        attachmentUrl,
        attachmentType,
        attachmentName,
      };
      const newCommentsList = [newCommentObject, ...comments];
      setComments(newCommentsList);
      setNewComment("");
      saveToDatabase(description, newCommentsList, assignedMembers);

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleDeleteComment = async (id: string) => {
    const commentToDelete = comments.find((comment) => comment.id === id);
    if (commentToDelete && commentToDelete.attachmentUrl) {
      const attachmentRef = ref(storage, commentToDelete.attachmentUrl);
      try {
        await deleteObject(attachmentRef);
      } catch (error) {
        console.error(t("deleteAttachmentError"), error);
      }
    }

    const updatedComments = comments.filter((comment) => comment.id !== id);
    setComments(updatedComments);
    saveToDatabase(description, updatedComments, assignedMembers);
  };

  const handleEditComment = (id: string) => {
    const commentToEdit = comments.find((comment) => comment.id === id);
    if (commentToEdit) {
      setEditingComment(id);
      setEditCommentText(commentToEdit.comment);
    }
  };

  const handleSaveEditComment = (id: string) => {
    const updatedComments = comments.map((comment) =>
      comment.id === id ? { ...comment, comment: editCommentText } : comment
    );
    setComments(updatedComments);
    setEditingComment(null);
    setEditCommentText("");
    saveToDatabase(description, updatedComments, assignedMembers);
  };

  const isYouTubeUrl = (url: string) => {
    const ytRegex = /^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
    return ytRegex.test(url);
  };

  const getYouTubeEmbedUrl = (url: string) => {
    const urlObj = new URL(url);
    if (urlObj.hostname === "www.youtube.com" && urlObj.pathname === "/watch") {
      return `https://www.youtube.com/embed/${urlObj.searchParams.get("v")}`;
    } else if (urlObj.hostname === "youtu.be") {
      return `https://www.youtube.com/embed/${urlObj.pathname.slice(1)}`;
    }
    return "";
  };

  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const getInitials = (firstName: string, lastName: string) => {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="relative bg-white w-full h-full max-h-full overflow-y-auto rounded-2xl shadow-lg">
        <button
          onClick={onClose}
          className="absolute top-4 left-1 text-gray-700 rounded-full p-2  flex items-center justify-center z-10"
        >
          <IoMdArrowBack className="h-6 w-6 " />
        </button>

        <div className="flex flex-col mt-14 px-4 pb-16">
          <div className="flex justify-between items-center mb-4">
            {isEditingCardName ? (
              <div className="flex items-center">
                <input
                  type="text"
                  value={tempCardName}
                  onChange={(e) => setTempCardName(e.target.value)}
                  autoFocus
                  className="text-2xl font-bold border-b-2 border-gray-300 focus:outline-none"
                />
                <button
                  onClick={() => saveCardNameToDatabase(tempCardName)}
                  className="ml-2 p-1  text-gray-300 hover:text-gray-500 rounded-full"
                >
                  <FaCheck className="h-5 w-5" />
                </button>
              </div>
            ) : (
              <h2
                className="text-2xl font-bold cursor-pointer"
                onClick={() => setIsEditingCardName(true)}
              >
                {tempCardName}
              </h2>
            )}
            <div className="flex items-center">
              {assignedMembers.map((memberId) => {
                const member = allMembers.find((m) => m.id === memberId);
                return (
                  <div key={memberId}>
                    {member ? (
                      <div className="mr-1">
                        <span className="bg-blue-500 text-white p-2 rounded-full w-8 h-8 flex items-center justify-center">
                          {getInitials(member.firstName, member.lastName)}
                        </span>{" "}
                      </div>
                    ) : null}
                  </div>
                );
              })}
              <button
                onClick={onAddMemberClick}
                className="bg-green-500 text-white p-2 rounded-full w-8 h-8 flex items-center justify-center ml-2"
              >
                +
              </button>
            </div>
          </div>
          <div className=" flex-col justify-between">
            <div>
              <ReactQuill
                value={description}
                onChange={(newDescription) => {
                  setDescription(newDescription);
                  saveToDatabase(newDescription, comments, assignedMembers);
                }}
                modules={modules}
                placeholder={t("newNotesPlaceholder")}
                className="text-gray-700 mb-2"
                style={{ height: "200px" }}
              />
            </div>
            <div className="mt-[120px]">
              <h3 className="text-lg font-bold mb-2">{t("commentsTitle")}</h3>
              <div className="mb-4 flex-grow">
                <div className="max-h-[450px] h-[400px] overflow-y-auto">
                  {comments.map((comment) => (
                    <div
                      key={comment.id}
                      className=" py-4 px-2 rounded-2xl mb-2 flex justify-between items-start"
                    >
                      {editingComment === comment.id ? (
                        <div className="w-full">
                          <textarea
                            value={editCommentText}
                            onChange={(e) => setEditCommentText(e.target.value)}
                            className="w-full px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
                          />
                          <button
                            onClick={() => handleSaveEditComment(comment.id)}
                            className="bg-blue-500 text-white p-2 my-2 rounded-2xl px-2"
                          >
                            {t("saveButton")}
                          </button>
                        </div>
                      ) : (
                        <div className="w-full">
                          {isYouTubeUrl(comment.comment) ? (
                            <iframe
                              width="100%"
                              height="315"
                              src={getYouTubeEmbedUrl(comment.comment)}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              title="YouTube video"
                              onClick={() => handleEditComment(comment.id)}
                            ></iframe>
                          ) : isValidUrl(comment.comment) ? (
                            <a
                              href={comment.comment}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 underline"
                            >
                              {comment.comment}
                            </a>
                          ) : (
                            <p>{comment.comment}</p>
                          )}
                          {comment.attachmentUrl && (
                            <div className="my-2">
                              {comment.attachmentType === "image" ? (
                                <div>
                                  <img
                                    src={comment.attachmentUrl}
                                    alt="Attachment"
                                    className="max-w-full h-auto"
                                  />
                                  <a
                                    href={comment.attachmentUrl}
                                    download={comment.attachmentName}
                                    className="text-black text-sm font-bold underline"
                                  >
                                    {t("downloadButton")}
                                  </a>
                                </div>
                              ) : (
                                <a
                                  href={comment.attachmentUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-blue-500 underline"
                                >
                                  {comment.attachmentName ||
                                    comment.attachmentUrl}
                                </a>
                              )}
                            </div>
                          )}
                          <div>
                            <strong>{comment.userName}:</strong>
                            <em>
                              {new Date(comment.timestamp).toLocaleString()}
                            </em>
                          </div>
                          <div className="flex w-full">
                            <button
                              onClick={() => handleEditComment(comment.id)}
                              className=" text-black font-bold rounded underline text-sm mr-2"
                            >
                              {t("editButton")}
                            </button>
                            <button
                              onClick={() => handleDeleteComment(comment.id)}
                              className=" text-black font-bold p-1 rounded underline text-sm"
                            >
                              {t("deleteButton")}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="fixed bottom-0 left-0 w-full bg-white p-4 shadow-inner">
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder={t("newCommentPlaceholder")}
            className="w-full px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#5932EA]"
          />
          <input
            type="file"
            ref={fileInputRef}
            accept="image/*, application/pdf, application/msword, .docx, .ppt, .pptx"
            onChange={(e) => setAttachment(e.target.files?.[0] || null)}
            className="mt-2"
          />
          {attachmentError && (
            <p className="text-red-500 text-sm mt-2">{attachmentError}</p>
          )}
          <button
            onClick={handleAddComment}
            className="bg-[#5932EA] bg-opacity-75 text-white  p-2 my-2 rounded-2xl px-2 w-full hover:bg-[#5932EA] transition-colors"
          >
            {t("addCommentButton")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
